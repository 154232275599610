unit libcalendar;

interface

{$mode objfpc}
{$modeswitch externalclass}

uses
  Web, JS, Classes;

type
  TJSCalendarSelectHandler = reference to procedure(Date: TJSDate);

  TJSCalendarOptions = class(TJSObject)
  private
    FSelector: string external name 'selector';
    FDatesFilter: Boolean external name 'datesFilter';
    FReadOnly: Boolean external name 'readOnly';
    FPastDates: Boolean external name 'pastDates';
    FFirstDay: NativeInt external name 'firstDay';
    FAvailableWeekDays: TJSArray external name 'availableWeekDays';
    FAvailableDates: TJSArray external name 'availableDates';
    FSelectedDate: TJSDate external name 'selectedDate';
    FDate: TJSDate external name 'date';
    FTodaysDate: TJSDate external name 'todaysDate';
    FMultiselect: Boolean external name 'multiselect';
    FMonths: TJSArray external name 'months';
    FShortWeekday: TJSArray external name 'shortWeekday';
    FShowToday: Boolean external name 'showToday';
    FButtonHintNext: string external name 'buttonHintNext';
    FButtonHintPrev: string external name 'buttonHintPrev';
    FMaxDate: TJSDate external name 'maxDate';
    FMinDate: TJSDate external name 'minDate';
    FBackgroundClass: string external name 'backgroundClass';
    FSelectedClass: string external name 'selectedClass';
    FCurrentClass: string external name 'currentClass';
    FWeekClass: string external name 'weekClass';
    FHeaderClass: string external name 'headerClass';
  public
    onSelect: TJSCalendarSelectHandler;
    onUnselect: TJSCalendarSelectHandler;
    property selector: string read FSelector write FSelector;
    property datesFilter: Boolean read FDatesFilter write FDatesFilter;
    property readOnly: Boolean read FReadOnly write FReadOnly;
    property pastDates: Boolean read FPastDates write FPastDates;
    property firstDay: NativeInt read FFirstDay write FFirstDay;
    property availableWeekDays: TJSArray read FAvailableWeekDays write FAvailableWeekDays;
    property availableDates: TJSArray read FAvailableDates write FAvailableDates;
    property selectedDate: TJSDate read FSelectedDate write FSelectedDate;
    property date: TJSDate read FDate write FDate;
    property todaysDate: TJSDate read FTodaysDate write FTodaysDate;
    property multiselect: Boolean read FMultiselect write FMultiselect;
    property months: TJSArray read FMonths write FMonths;
    property shortWeekday: TJSArray read FShortWeekday write FShortWeekday;
    property showToday: Boolean read FShowToday write FShowToday;
    property buttonHintNext: string read FButtonHintNext write FButtonHintNext;
    property buttonHintPrev: string read FButtonHintPrev write FButtonHintPrev;
    property maxDate: TJSDate read FMaxDate write FMaxDate;
    property minDate: TJSDate read FMinDate write FMinDate;
    property backgroundClass: string read FBackgroundClass write FBackgroundClass;
    property selectedClass: string read FSelectedClass write FSelectedClass;
    property currentClass: string read FCurrentClass write FCurrentClass;
    property weekClass: string read FWeekClass write FWeekClass;
    property headerClass: string read FHeaderClass write FHeaderClass;
  end;

  TJSCalendar = class external name 'VanillaCalendar' (TJSObject)
  public
    constructor new(AOptions: TJSCalendarOptions);
    procedure init;
    procedure reset;
    procedure &set(AOptions: TJSCalendarOptions);
    procedure setSelectedYear(AYear: NativeInt);
    procedure setSelectedMonth(AMonth: NativeInt);
    procedure setSelectedDay(ADay: NativeInt);
    procedure setSelectedDate(ADate: TJSDate);
    procedure setFirstDay(ADay: NativeInt);
    procedure setMultiSelect(AMultiSelect: Boolean);
    procedure setMonths(AMonths: TJSArray);
    procedure setShortWeekdays(AWeekdays: TJSArray);
    procedure setPastDates(APastDates: Boolean);
    procedure setEnabledWeekdays(AWeekdays: TJSArray);
    procedure setDatesFilter(AFilter: Boolean);
    procedure setShowToday(AShowToday: Boolean);
    procedure setReadOnly(AReadOnly: Boolean);
    procedure setSelectedDates(ADates: TJSArray);
    procedure setButtonHintNext(AHint: string);
    procedure setButtonHintPrev(AHint: string);
    procedure setMaxDate(ADate: TJSDate);
    procedure setMinDate(ADate: TJSDate);
    procedure setBackgroundClass(AClass: string);
    procedure setSelectedClass(AClass: string);
    procedure setCurrentClass(AClass: string);
    procedure setWeekClass(AClass: string);
    procedure setHeaderClass(AClass: string);
    function getSelectedDays: TJSArray;
    function getSelectedYear: NativeInt;
    function getSelectedMonth: NativeInt;
    function getSelectedDay: NativeInt;
    function getSelectedDate: TJSDate;
    function getSelectedDateCount: NativeInt;
  end;

implementation

end.
