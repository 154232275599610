unit UVW_Einrichtungen;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  UDatamodule,
  UGlobvar, WEBLib.Grids, WEBLib.DBCtrls,
  UEditEinrichtung;

type
  TVW_Einrichtungen = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebButton5: TButton;
    WebPanel2: TPanel;
    WebButton3: TButton;
    WebButton2: TButton;
    WebButton4: TButton;
    WebPanel3: TPanel;
    WebButton7: TButton;
    WebButton6: TButton;
    WebButton1: TButton;
    WebDBGrid1: TDBGrid;

    procedure WebFormShow(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  VW_Einrichtungen: TVW_Einrichtungen;

implementation


procedure TVW_Einrichtungen.WebButton2Click(Sender: TObject);
var
  newform: TEditEinrichtung;

begin
  Einrichtung_Insert_Mode := false;
  Datamodule1.EinrichtungDataSet.Edit;
  newform := TEditEinrichtung.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditEinrichtung).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;

procedure TVW_Einrichtungen.WebButton3Click(Sender: TObject);
var i : integer;
    Newform : TEditEinrichtung;
begin
  Einrichtung_Insert_Mode := true;
  Datamodule1.EinrichtungDataSet.Last;
  i := Datamodule1.EinrichtungDataSet.Fieldbyname('Nummer').AsInteger + 1;
  Datamodule1.EinrichtungDataSet.Insert;
  Datamodule1.EinrichtungDataSet.Fieldbyname('Nummer').AsInteger := i;
  Datamodule1.EinrichtungDataSet.Fieldbyname('Bezeichnung').AsString := '';
  Newform := TEditEinrichtung.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditEinrichtung).Caption := 'Einrichtungen';
    end
  );
  Newform.Border := fbSingle;

  Newform.ShowModal(procedure(AValue: TModalResult)
  begin
//      Close;
  end
  );

end;





procedure TVW_Einrichtungen.WebFormShow(Sender: TObject);

begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
  if not Datamodule1.EinrichtungDataSet.Active then begin
    if Lade_Daten_2(Datamodule1.EinrichtungDataSet,'EINRICHTUNGEN','$filter=(NL_Key eq '+IntToStr(AktFirmaNo) + ')','') then begin
    end;
  end;

end;

procedure TVW_Einrichtungen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  try
    Name := 'VW_Einrichtungen';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 65;
    Weblabel301.Height := 18;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton5.SetParentComponent(WebPanel1);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 496;
    WebButton5.Top := 48;
    WebButton5.Width := 96;
    WebButton5.Height := 25;
    WebButton5.Caption := 'WebButton5';
    WebButton5.ChildOrder := 4;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 455;
    WebPanel2.Top := 89;
    WebPanel2.Width := 185;
    WebPanel2.Height := 331;
    WebPanel2.Align := alRight;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebButton3.SetParentComponent(WebPanel2);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 6;
    WebButton3.Top := 16;
    WebButton3.Width := 160;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Hinzuf'#252'gen';
    WebButton3.ChildOrder := 3;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 6;
    WebButton2.Top := 47;
    WebButton2.Width := 160;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Bearbeiten';
    WebButton2.ChildOrder := 2;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton4.SetParentComponent(WebPanel2);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 16;
    WebButton4.Top := 83;
    WebButton4.Width := 160;
    WebButton4.Height := 25;
    WebButton4.Caption := 'L'#246'schen';
    WebButton4.ChildOrder := 4;
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 420;
    WebPanel3.Width := 640;
    WebPanel3.Height := 60;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebButton7.SetParentComponent(WebPanel3);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 105;
    WebButton7.Top := 6;
    WebButton7.Width := 113;
    WebButton7.Height := 39;
    WebButton7.Caption := 'Wochenplan';
    WebButton7.ChildOrder := 4;
    WebButton6.SetParentComponent(WebPanel3);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 3;
    WebButton6.Top := 6;
    WebButton6.Width := 96;
    WebButton6.Height := 39;
    WebButton6.Caption := 'Allergien';
    WebButton6.ChildOrder := 4;
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 280;
    WebButton1.Top := 6;
    WebButton1.Width := 153;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 89;
    WebDBGrid1.Width := 455;
    WebDBGrid1.Height := 331;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'NUMMER';
      Title := 'Nummer';
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BEZEICHNUNG';
      Title := 'Bezeichnung';
      Width := 362;
    end;
    WebDBGrid1.DataSource := DataModule1.EinrichtungSource;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.TabOrder := 3;
    WebDBGrid1.ShowIndicator := False;
    WebDBGrid1.ColWidths[0] := 64;
    WebDBGrid1.ColWidths[1] := 362;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
  end;
end;

end.
