unit UWochenplan;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  UDatamodule,
  UGlobvar;

type
  TWochenplan = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebLabel3: TLabel;
    WebLabel2: TLabel;
    WebButton2: TButton;
    WebButton1: TButton;
    WebLabel4: TLabel;
    WebComboBox1: TComboBox;
    WebLabel5: TLabel;
    WebComboBox2: TComboBox;
    WebLabel6: TLabel;
    WebComboBox3: TComboBox;
    WebLabel7: TLabel;
    WebComboBox4: TComboBox;
    WebLabel8: TLabel;
    WebComboBox5: TComboBox;
    WebLabel9: TLabel;
    WebComboBox6: TComboBox;
    WebLabel10: TLabel;
    WebComboBox7: TComboBox;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    A_ID : array[1 .. 100] of integer;
  end;

var
  Wochenplan: TWochenplan;

implementation


procedure TWochenplan.WebButton1Click(Sender: TObject);
begin
  Close;
end;



procedure TWochenplan.WebButton2Click(Sender: TObject);
begin
  try
    Datamodule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan1').AsInteger := WebComboBox1.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan2').AsInteger := WebComboBox2.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan3').AsInteger := WebComboBox3.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan4').AsInteger := WebComboBox4.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan5').AsInteger := WebComboBox5.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan6').AsInteger := WebComboBox6.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan7').AsInteger := WebComboBox7.ItemIndex;
    DataModule1.TeilnehmerDataSet.Post;
    DataModule1.TeilnehmerDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;

end;

procedure TWochenplan.WebFormShow(Sender: TObject);
var i,
    j1,j2,j3,j4,j5,j6,j7 : integer;

begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
  WebLabel2.Caption := AktTeilnehmerName;

  WebComboBox1.Items.Clear;
  WebComboBox1.Items.Add('----------------');
  WebComboBox2.Items.Clear;
  WebComboBox2.Items.Add('----------------');
  WebComboBox3.Items.Clear;
  WebComboBox3.Items.Add('----------------');
  WebComboBox4.Items.Clear;
  WebComboBox4.Items.Add('----------------');
  WebComboBox5.Items.Clear;
  WebComboBox5.Items.Add('----------------');
  WebComboBox6.Items.Clear;
  WebComboBox6.Items.Add('----------------');
  WebComboBox7.Items.Clear;
  WebComboBox7.Items.Add('----------------');


  for i := 1 to 100 do begin
    A_ID[i] := 0;
  end;
  i := 0;
  j1 := -1;
  j2 := -1;
  j3 := -1;
  j4 := -1;
  j5 := -1;
  j6 := -1;
  j7 := -1;

  while not Datamodule1.VerpflegungsArtDataSet.Eof do begin
    i := i + 1;
    A_ID[i] := Datamodule1.VerpflegungsArtDataSet.FieldByName('ID').AsInteger;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan1').AsInteger then j1 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan2').AsInteger then j2 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan3').AsInteger then j3 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan4').AsInteger then j4 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan5').AsInteger then j5 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan6').AsInteger then j6 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan7').AsInteger then j7 := i;
    WebComboBox1.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox2.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox3.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox4.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox5.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox6.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox7.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    Datamodule1.VerpflegungsArtDataSet.Next;
  end;
  if j1 <> -1 then begin
    WebComboBox1.ItemIndex := j1;
  end;
  if j2 <> -1 then begin
    WebComboBox2.ItemIndex := j2;
  end;
  if j3 <> -1 then begin
    WebComboBox3.ItemIndex := j3;
  end;
  if j4 <> -1 then begin
    WebComboBox4.ItemIndex := j4;
  end;
  if j5 <> -1 then begin
    WebComboBox5.ItemIndex := j5;
  end;
  if j6 <> -1 then begin
    WebComboBox6.ItemIndex := j6;
  end;
  if j7 <> -1 then begin
    WebComboBox7.ItemIndex := j7;
  end;
end;

procedure TWochenplan.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel3 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);
  WebComboBox3 := TComboBox.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebComboBox5 := TComboBox.Create(Self);
  WebComboBox6 := TComboBox.Create(Self);
  WebComboBox7 := TComboBox.Create(Self);

  WebLabel3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  WebComboBox3.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebComboBox5.BeforeLoadDFMValues;
  WebComboBox6.BeforeLoadDFMValues;
  WebComboBox7.BeforeLoadDFMValues;
  try
    Name := 'Wochenplan';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 16;
    WebLabel3.Top := 83;
    WebLabel3.Width := 71;
    WebLabel3.Height := 14;
    WebLabel3.Caption := 'Wochplan f'#252'r :';
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 105;
    WebLabel2.Top := 81;
    WebLabel2.Width := 85;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'WebLabel2';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [fsBold];
    WebLabel2.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 24;
    WebLabel4.Top := 128;
    WebLabel4.Width := 35;
    WebLabel4.Height := 14;
    WebLabel4.Caption := 'Montag';
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 24;
    WebLabel5.Top := 160;
    WebLabel5.Width := 42;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'Dienstag';
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 24;
    WebLabel6.Top := 192;
    WebLabel6.Width := 44;
    WebLabel6.Height := 14;
    WebLabel6.Caption := 'Mittwoch';
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 24;
    WebLabel7.Top := 224;
    WebLabel7.Width := 56;
    WebLabel7.Height := 14;
    WebLabel7.Caption := 'Donnerstag';
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 24;
    WebLabel8.Top := 256;
    WebLabel8.Width := 33;
    WebLabel8.Height := 14;
    WebLabel8.Caption := 'Freitag';
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 24;
    WebLabel9.Top := 288;
    WebLabel9.Width := 42;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'Samstag';
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 24;
    WebLabel10.Top := 320;
    WebLabel10.Width := 40;
    WebLabel10.Height := 14;
    WebLabel10.Caption := 'Sonntag';
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 73;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 47;
    Weblabel301.Height := 14;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 367;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Daten speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 265;
    WebButton1.Top := 367;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn-secondary';
    WebButton1.TabOrder := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 97;
    WebComboBox1.Top := 125;
    WebComboBox1.Width := 247;
    WebComboBox1.Height := 22;
    WebComboBox1.Text := '--';
    WebComboBox1.ItemIndex := 0;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('--');
      WebComboBox1.Items.Add('Januar');
      WebComboBox1.Items.Add('Februar');
      WebComboBox1.Items.Add('M'#228'rz');
      WebComboBox1.Items.Add('April');
      WebComboBox1.Items.Add('Mai');
      WebComboBox1.Items.Add('Juni');
      WebComboBox1.Items.Add('Juli');
      WebComboBox1.Items.Add('August');
      WebComboBox1.Items.Add('September');
      WebComboBox1.Items.Add('Oktober');
      WebComboBox1.Items.Add('November');
      WebComboBox1.Items.Add('Dezember');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebComboBox2.SetParentComponent(Self);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 97;
    WebComboBox2.Top := 157;
    WebComboBox2.Width := 247;
    WebComboBox2.Height := 22;
    WebComboBox2.Text := '--';
    WebComboBox2.ItemIndex := 0;
    WebComboBox2.Items.BeginUpdate;
    try
      WebComboBox2.Items.Clear;
      WebComboBox2.Items.Add('--');
      WebComboBox2.Items.Add('Januar');
      WebComboBox2.Items.Add('Februar');
      WebComboBox2.Items.Add('M'#228'rz');
      WebComboBox2.Items.Add('April');
      WebComboBox2.Items.Add('Mai');
      WebComboBox2.Items.Add('Juni');
      WebComboBox2.Items.Add('Juli');
      WebComboBox2.Items.Add('August');
      WebComboBox2.Items.Add('September');
      WebComboBox2.Items.Add('Oktober');
      WebComboBox2.Items.Add('November');
      WebComboBox2.Items.Add('Dezember');
    finally
      WebComboBox2.Items.EndUpdate;
    end;
    WebComboBox3.SetParentComponent(Self);
    WebComboBox3.Name := 'WebComboBox3';
    WebComboBox3.Left := 97;
    WebComboBox3.Top := 189;
    WebComboBox3.Width := 247;
    WebComboBox3.Height := 22;
    WebComboBox3.Text := '--';
    WebComboBox3.ItemIndex := 0;
    WebComboBox3.Items.BeginUpdate;
    try
      WebComboBox3.Items.Clear;
      WebComboBox3.Items.Add('--');
      WebComboBox3.Items.Add('Januar');
      WebComboBox3.Items.Add('Februar');
      WebComboBox3.Items.Add('M'#228'rz');
      WebComboBox3.Items.Add('April');
      WebComboBox3.Items.Add('Mai');
      WebComboBox3.Items.Add('Juni');
      WebComboBox3.Items.Add('Juli');
      WebComboBox3.Items.Add('August');
      WebComboBox3.Items.Add('September');
      WebComboBox3.Items.Add('Oktober');
      WebComboBox3.Items.Add('November');
      WebComboBox3.Items.Add('Dezember');
    finally
      WebComboBox3.Items.EndUpdate;
    end;
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 97;
    WebComboBox4.Top := 221;
    WebComboBox4.Width := 247;
    WebComboBox4.Height := 22;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebComboBox5.SetParentComponent(Self);
    WebComboBox5.Name := 'WebComboBox5';
    WebComboBox5.Left := 97;
    WebComboBox5.Top := 253;
    WebComboBox5.Width := 247;
    WebComboBox5.Height := 22;
    WebComboBox5.Text := '--';
    WebComboBox5.ItemIndex := 0;
    WebComboBox5.Items.BeginUpdate;
    try
      WebComboBox5.Items.Clear;
      WebComboBox5.Items.Add('--');
      WebComboBox5.Items.Add('Januar');
      WebComboBox5.Items.Add('Februar');
      WebComboBox5.Items.Add('M'#228'rz');
      WebComboBox5.Items.Add('April');
      WebComboBox5.Items.Add('Mai');
      WebComboBox5.Items.Add('Juni');
      WebComboBox5.Items.Add('Juli');
      WebComboBox5.Items.Add('August');
      WebComboBox5.Items.Add('September');
      WebComboBox5.Items.Add('Oktober');
      WebComboBox5.Items.Add('November');
      WebComboBox5.Items.Add('Dezember');
    finally
      WebComboBox5.Items.EndUpdate;
    end;
    WebComboBox6.SetParentComponent(Self);
    WebComboBox6.Name := 'WebComboBox6';
    WebComboBox6.Left := 97;
    WebComboBox6.Top := 285;
    WebComboBox6.Width := 247;
    WebComboBox6.Height := 22;
    WebComboBox6.Text := '--';
    WebComboBox6.ItemIndex := 0;
    WebComboBox6.Items.BeginUpdate;
    try
      WebComboBox6.Items.Clear;
      WebComboBox6.Items.Add('--');
      WebComboBox6.Items.Add('Januar');
      WebComboBox6.Items.Add('Februar');
      WebComboBox6.Items.Add('M'#228'rz');
      WebComboBox6.Items.Add('April');
      WebComboBox6.Items.Add('Mai');
      WebComboBox6.Items.Add('Juni');
      WebComboBox6.Items.Add('Juli');
      WebComboBox6.Items.Add('August');
      WebComboBox6.Items.Add('September');
      WebComboBox6.Items.Add('Oktober');
      WebComboBox6.Items.Add('November');
      WebComboBox6.Items.Add('Dezember');
    finally
      WebComboBox6.Items.EndUpdate;
    end;
    WebComboBox7.SetParentComponent(Self);
    WebComboBox7.Name := 'WebComboBox7';
    WebComboBox7.Left := 97;
    WebComboBox7.Top := 317;
    WebComboBox7.Width := 247;
    WebComboBox7.Height := 22;
    WebComboBox7.Text := '--';
    WebComboBox7.ItemIndex := 0;
    WebComboBox7.Items.BeginUpdate;
    try
      WebComboBox7.Items.Clear;
      WebComboBox7.Items.Add('--');
      WebComboBox7.Items.Add('Januar');
      WebComboBox7.Items.Add('Februar');
      WebComboBox7.Items.Add('M'#228'rz');
      WebComboBox7.Items.Add('April');
      WebComboBox7.Items.Add('Mai');
      WebComboBox7.Items.Add('Juni');
      WebComboBox7.Items.Add('Juli');
      WebComboBox7.Items.Add('August');
      WebComboBox7.Items.Add('September');
      WebComboBox7.Items.Add('Oktober');
      WebComboBox7.Items.Add('November');
      WebComboBox7.Items.Add('Dezember');
    finally
      WebComboBox7.Items.EndUpdate;
    end;
  finally
    WebLabel3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
    WebComboBox3.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebComboBox5.AfterLoadDFMValues;
    WebComboBox6.AfterLoadDFMValues;
    WebComboBox7.AfterLoadDFMValues;
  end;
end;

end.