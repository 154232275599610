unit UAnzSpeiseplan;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Calendar, WEBLib.StdCtrls,
  DB,
  UGlobvar,
  UDatamodule,
  UAnzSpeiseplanTag2;

type
  TAnzSpeiseplan = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebButton1: TButton;
    WebDateTimePicker1: TDateTimePicker;
    WebButton2: TButton;
    WebLabel2: TLabel;
    WebLabel5: TLabel;
    WebComboBox4: TComboBox;
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure Zeige_Speiseplan_Tag(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    TA,
    EA : array[0 .. 100] of Integer;
  end;

var
  AnzSpeiseplan: TAnzSpeiseplan;
  Abbestell_Modus : integer;

implementation


procedure TAnzSpeiseplan.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TAnzSpeiseplan.WebButton2Click(Sender: TObject);
var D : TDateTime;
    Newform: TAnzSpeiseplanTag2;
    i : integer;

begin
  AktTeilnehmerID := TA[WebComboBox4.ItemIndex];
  AktTeilnehmerName := WebComboBox4.Text;
  AktEInrichtungID := EA[WebComboBox4.ItemIndex];
  if WebDateTimePicker1.Date < Date then begin
    Meldungs_Fenster('Dieses Datum liegt in der Vergangenheit',mtError);
    exit;
  end;
//  Datamodule1.SpeiseplanDataSet.AfterOpen := Zeige_Speiseplan_Tag;
//  if Lade_Daten(Datamodule1.SpeiseplanDataset,'SPEISEPLAN_TAG','$filter=ENO eq '+IntToStr(AktEinrichtungID)) then begin
//  if Lade_Daten(Datamodule1.SpeiseplanDataset,'SPEISEPLAN_TAG','$filter=ENO eq 2') then begin

//  end;

    Newform := TAnzSpeiseplanTag2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TAnzSpeiseplanTag2).Caption := 'Speiseplan';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );




end;

procedure TAnzSpeiseplan.WebFormCreate(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
end;

procedure TAnzSpeiseplan.WebFormShow(Sender: TObject);
var i : integer;
begin
  if Abbestell_Modus > 0 then begin
    WebButton2.Caption := 'Essen abbestellen';
    WebDateTimePicker1.Enabled := false;
    case Abbestell_Modus of
      1 : begin
            WebDateTimePicker1.Date := Date;
          end;
      2 : begin
            WebDateTimePicker1.Date := Date + 1;
          end;
      3 : begin
            WebDateTimePicker1.Date := Date + 2;
          end;

    end
  end
  else begin
    WebDateTimePicker1.Date := Date;
  end;


  WebComboBox4.Items.Clear;
  for i := 0 to 100 do begin
    EA[i] := 0;
    TA[i] := 0;
  end;
  Datamodule1.TeilnehmerDataSet.First;
  i := 0;
  while not Datamodule1.TeilnehmerDataSet.EOF do begin
    WebComboBox4.Items.Add(Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString);
    EA[i] := Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger;
    TA[i] := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
    i := i + 1;
    Datamodule1.TeilnehmerDataSet.Next;
  end;
  WebComboBox4.ItemIndex := 0;

end;


procedure TAnzSpeiseplan.Zeige_Speiseplan_Tag(DataSet: TDataSet);
var newform : TAnzSpeiseplanTag2;
begin
  newform := TAnzSpeiseplanTag2.CreateNew(procedure(AForm: TObject)
    begin
//      Datamodule1.SpeiseplanDataset.Load;
      (AForm as TAnzSpeiseplanTag2).Caption := 'Speiseplan';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;




procedure TAnzSpeiseplan.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebDateTimePicker1 := TDateTimePicker.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  try
    Name := 'AnzSpeiseplan';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 8;
    WebLabel1.Width := 44;
    WebLabel1.Height := 14;
    WebLabel1.Caption := 'Benutzer';
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 116;
    WebLabel2.Width := 104;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Ausgew'#228'hltes Datum';
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 24;
    WebLabel5.Top := 50;
    WebLabel5.Width := 85;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'Essensteilnehmer';
    WebLabel5.Color := clBtnFace;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 96;
    WebEdit1.Top := 8;
    WebEdit1.Width := 225;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 2;
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 225;
    WebButton1.Top := 209;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.Left := 24;
    WebDateTimePicker1.Top := 136;
    WebDateTimePicker1.Width := 297;
    WebDateTimePicker1.Height := 41;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 4;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 44097.583971446760000000;
    WebDateTimePicker1.Font.Charset := DEFAULT_CHARSET;
    WebDateTimePicker1.Font.Color := clWindowText;
    WebDateTimePicker1.Font.Height := -19;
    WebDateTimePicker1.Font.Name := 'Arial';
    WebDateTimePicker1.Font.Style := [];
    WebDateTimePicker1.ParentFont := False;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 209;
    WebButton2.Width := 177;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Speiseplan anzeigen';
    WebButton2.ChildOrder := 4;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 24;
    WebComboBox4.Top := 70;
    WebComboBox4.Width := 297;
    WebComboBox4.Height := 22;
    WebComboBox4.Font.Charset := DEFAULT_CHARSET;
    WebComboBox4.Font.Color := clWindowText;
    WebComboBox4.Font.Height := -19;
    WebComboBox4.Font.Name := 'Arial';
    WebComboBox4.Font.Style := [];
    WebComboBox4.ParentFont := False;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
  end;
end;

end.