unit UTeilnehmer;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  UDatamodule,
  UGlobvar, WEBLib.DBCtrls, WEBLib.StdCtrls, DB,
  WEBLib.ExtCtrls, WEBLib.Grids,
  WEBLib.CDS,
  UEditTeilnehmer,
  UVWAllergien,
  UWochenplan;

type
  TTeilnehmer = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebDBGrid1: TDBGrid;
    WebPanel2: TPanel;
    WebButton3: TButton;
    WebButton2: TButton;
    WebButton4: TButton;
    WebPanel3: TPanel;
    WebButton7: TButton;
    WebButton6: TButton;
    WebButton1: TButton;
    WebButton5: TButton;
    WebButton8: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure After_Insert_Teilnehmer(DataSet: TDataSet);
    procedure WebButton6Click(Sender: TObject);
    procedure Zeige_Allergie_Fenster(DataSet: TDataSet);
    procedure WebButton7Click(Sender: TObject);
    procedure Zeige_Wochenplan(DataSet: TDataSet);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  Teilnehmer: TTeilnehmer;

implementation





procedure TTeilnehmer.WebButton1Click(Sender: TObject);
begin
  Datamodule1.TeilnehmerDataSet.Close;
  Close;
end;

procedure TTeilnehmer.WebButton2Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 0 then begin
    MessageDlg('Teilnehmer ist inaktiv gesetzt, ggf. erst aktivieren', mtError, [], nil);
    exit;
  end;

  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := false;

  Datamodule1.TeilnehmerDataSet.Edit;
  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TTeilnehmer.WebButton3Click(Sender: TObject);
var i : integer;
  newform: TEditTeilnehmer;
begin
  Teilnehmer_Insert_Mode := true;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := false;

  Datamodule1.TeilnehmerDataSet.Last;
  i := Datamodule1.TeilnehmerDataSet.FieldByName('lfdno').AsInteger;
  Datamodule1.TeilnehmerDataSet.Insert;
  Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger := 0;
  Datamodule1.TeilnehmerDataSet.FieldByName('NL_Key').AsInteger := AktFirmaNo;
  Datamodule1.TeilnehmerDataSet.FieldByName('KDID').AsInteger := Datamodule1.KundenDataSet.Fieldbyname('ID').AsInteger;
  Datamodule1.TeilnehmerDataSet.FieldByName('lfdno').AsInteger := i + 1;

  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//    Datamodule1.TeilnehmerDataSet.AfterOpen := After_Insert_Teilnehmer;
//    if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((NL_Key eq '+IntToStr(AktFirmaNo)+' AND (KDID eq '
//      + Datamodule1.KundenDataSet.Fieldbyname('ID').AsString+')))') then begin
//    end;
//
  end
  );


end;

procedure TTeilnehmer.WebButton4Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;

  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := true;

  Datamodule1.TeilnehmerDataSet.Edit;
  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer löschen';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );
{
Teilnehmer_Delete_Mode
  if Meldungs_Fenster('Aktuellen Datensatz löschen?',mtConfirmation) = MrYes  then ;
    Datamodule1.TeilnehmerDataSet.Delete;
    DataModule1.TeilnehmerDataSet.ApplyUpdates;
}
end;

procedure TTeilnehmer.WebButton5Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 0 then begin
    MessageDlg('Teilnehmer ist bereits inaktiv', mtError, [], nil);
    exit;
  end;
  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := true;

  Datamodule1.TeilnehmerDataSet.Edit;
  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );
end;

procedure TTeilnehmer.WebButton6Click(Sender: TObject);

begin
  AktTeilnehmer := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
  AktTeilnehmerName := Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString;
  Datamodule1.AllergieDataSet.AfterOpen := Zeige_Allergie_Fenster;
  if Lade_Daten(Datamodule1.AllergieDataSet,'TEILNEHMER_ALLERGIEN','$filter=TEILNEHMERID eq '+IntToStr(AktTeilnehmer)) then begin
  end;

end;

procedure TTeilnehmer.WebButton7Click(Sender: TObject);

begin
  AktTeilnehmer := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
  AktTeilnehmerName := Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString;
  Datamodule1.VerpflegungsArtDataSet.AfterOpen := Zeige_Wochenplan;
  if Lade_Daten(Datamodule1.VerpflegungsArtDataSet,'EINRICHTUNGENVERPFLEGUNG','$filter=NL_Key eq '+IntToStr(AktFirmaNo)) then begin
  end;



end;

procedure TTeilnehmer.WebButton8Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 1 then begin
    MessageDlg('Teilnehmer ist bereits aktiv', mtError, [], nil);
    exit;
  end;
  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := true;
  Teilnehmer_Deactivate_Mode := false;

  Datamodule1.TeilnehmerDataSet.Edit;
  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TTeilnehmer.WebFormCreate(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
//  WebDBGrid1.RowHeights[0] := 24;
//  Datamodule1.WebClientDataSet1.Active := true;


end;

procedure TTeilnehmer.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
//  WebDBGrid1.RowHeights[0] := 24;
  if not Datamodule1.TeilnehmerDataSet.Active then begin
    if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((NL_Key eq '+IntToStr(AktFirmaNo)+' AND (KDID eq '
      + Datamodule1.KundenDataSet.Fieldbyname('ID').AsString+')))') then begin
    end;
  end;

  if not Datamodule1.EinrichtungDataSet.Active then begin
    if Lade_Daten(Datamodule1.EinrichtungDataSet,'Einrichtungen','$filter=NL_Key eq '+IntToStr(AktFirmaNo)) then begin
    end;
  end;
  if not Datamodule1.VariantenDataSet.Active then begin
    if Lade_Daten(Datamodule1.VariantenDataSet,'ESSENSVARIANTEN','$filter=NL_Key eq '+IntToStr(AktFirmaNo)) then begin
    end;
  end;



//  Datamodule1.TeilnehmerDataSet.Active := false;
//  Datamodule1.TeilnehmerDataSet.Active := true;

end;



procedure TTeilnehmer.After_Insert_Teilnehmer(DataSet: TDataSet);
begin
  Datamodule1.TeilnehmerDataSet.Last;
end;


procedure TTeilnehmer.Zeige_Allergie_Fenster(DataSet: TDataSet);
var newform : TVWAllergien;
begin
  newform := TVWAllergien.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TVWAllergien).Caption := 'Teilnehmer bearbeiten';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;

procedure TTeilnehmer.Zeige_Wochenplan(DataSet: TDataSet);
var newform : TWochenplan;
begin
  newform := TWochenplan.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TWochenplan).Caption := 'Teilnehmer bearbeiten';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;



procedure TTeilnehmer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'Teilnehmer';
    Left := 0;
    Top := 0;
    Width := 789;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 789;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 10;
    Weblabel301.Top := 33;
    Weblabel301.Width := 80;
    Weblabel301.Height := 16;
    Weblabel301.Caption := 'Akt. Benutzer';
    Weblabel301.Color := clBtnFace;
    Weblabel301.Font.Charset := DEFAULT_CHARSET;
    Weblabel301.Font.Color := clWindowText;
    Weblabel301.Font.Height := -13;
    Weblabel301.Font.Name := 'Arial';
    Weblabel301.Font.Style := [];
    Weblabel301.ParentFont := False;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 10;
    WebEdit1.Top := 55;
    WebEdit1.Width := 271;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 89;
    WebDBGrid1.Width := 672;
    WebDBGrid1.Height := 331;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'NAME';
      Title := 'Name';
      Width := 239;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'AKTIV';
      DataType := cdCheck;
      Title := 'Aktiv';
      Width := 40;
    end;
    WebDBGrid1.DataSource := DataModule1.TeilnehmerDataSource;
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goTabs,goRowSelect];
    WebDBGrid1.TabOrder := 1;
    WebDBGrid1.ShowIndicator := False;
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 239;
    WebDBGrid1.ColWidths[2] := 40;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 672;
    WebPanel2.Top := 89;
    WebPanel2.Width := 117;
    WebPanel2.Height := 331;
    WebPanel2.Align := alRight;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebButton3.SetParentComponent(WebPanel2);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 6;
    WebButton3.Top := 16;
    WebButton3.Width := 97;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Hinzuf'#252'gen';
    WebButton3.ChildOrder := 3;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 6;
    WebButton2.Top := 58;
    WebButton2.Width := 97;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Bearbeiten';
    WebButton2.ChildOrder := 2;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton4.SetParentComponent(WebPanel2);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 6;
    WebButton4.Top := 187;
    WebButton4.Width := 97;
    WebButton4.Height := 25;
    WebButton4.Caption := 'L'#246'schen';
    WebButton4.ChildOrder := 4;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(WebPanel2);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 6;
    WebButton5.Top := 101;
    WebButton5.Width := 97;
    WebButton5.Height := 25;
    WebButton5.Caption := 'Deaktivieren';
    WebButton5.ChildOrder := 3;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton8.SetParentComponent(WebPanel2);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 6;
    WebButton8.Top := 144;
    WebButton8.Width := 97;
    WebButton8.Height := 25;
    WebButton8.Caption := 'Aktivieren';
    WebButton8.ChildOrder := 4;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 420;
    WebPanel3.Width := 789;
    WebPanel3.Height := 60;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebButton7.SetParentComponent(WebPanel3);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 79;
    WebButton7.Top := 6;
    WebButton7.Width := 104;
    WebButton7.Height := 39;
    WebButton7.Caption := 'Wochenplan';
    WebButton7.ChildOrder := 4;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton6.SetParentComponent(WebPanel3);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 3;
    WebButton6.Top := 6;
    WebButton6.Width := 70;
    WebButton6.Height := 39;
    WebButton6.Caption := 'Allergien';
    WebButton6.ChildOrder := 4;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 189;
    WebButton1.Top := 6;
    WebButton1.Width := 81;
    WebButton1.Height := 39;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.