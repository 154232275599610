unit UAnzSpeiseplanTag2;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Grids, WEBLib.DBCtrls,
  UGlobvar,
  UDatamodule, WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.ComCtrls;

type
  TAnzSpeiseplanTag2 = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebPanel3: TPanel;
    WebButton1: TButton;
    WebDBGrid1: TDBGrid;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebButton2: TButton;
    WebRichEdit1: TRichEdit;
    WebLabel3: TLabel;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebDBGrid1Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  AnzSpeiseplanTag2: TAnzSpeiseplanTag2;

implementation


procedure TAnzSpeiseplanTag2.WebButton1Click(Sender: TObject);
begin
  Datamodule1.SpeiseplanDataSet.Close;
  Close;

end;

procedure TAnzSpeiseplanTag2.WebDBGrid1Click(Sender: TObject);
var ArtNr : integer;
begin
  ArtNr := WebDBGrid1.DataSource.DataSet.FieldByName('Artikel').AsInteger;
  WebLabel3.Caption := IntToStr(ArtNr);
end;

procedure TAnzSpeiseplanTag2.WebFormCreate(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebEdit2.Text := AktTeilnehmerName;
  WebLabel1.Caption := AktFirmaName;

end;

procedure TAnzSpeiseplanTag2.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebEdit2.Text := AktTeilnehmerName;
  WebLabel1.Caption := AktFirmaName;
  AktEinrichtungID := 2;
  if Lade_Daten(Datamodule1.SpeiseplanDataset,'SPEISEPLAN_TAG','$filter=ENO eq '+IntToStr(AktEinrichtungID)) then begin

  end;

end;

procedure TAnzSpeiseplanTag2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebRichEdit1 := TRichEdit.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebRichEdit1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  try
    Name := 'AnzSpeiseplanTag2';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 113;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 47;
    Weblabel301.Height := 14;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 76;
    WebLabel2.Width := 88;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Essensteilnehmer:';
    WebLabel2.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebEdit2.SetParentComponent(WebPanel1);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 162;
    WebEdit2.Top := 73;
    WebEdit2.Width := 248;
    WebEdit2.Height := 22;
    WebEdit2.TabStop := False;
    WebEdit2.ChildOrder := 7;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.ReadOnly := True;
    WebEdit2.Text := 'WebEdit1';
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 336;
    WebPanel3.Width := 640;
    WebPanel3.Height := 144;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebLabel3.SetParentComponent(WebPanel3);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 32;
    WebLabel3.Top := 72;
    WebLabel3.Width := 54;
    WebLabel3.Height := 14;
    WebLabel3.Caption := 'WebLabel3';
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 216;
    WebButton1.Top := 78;
    WebButton1.Width := 153;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel3);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 488;
    WebButton2.Top := 24;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'WebButton2';
    WebButton2.ChildOrder := 1;
    WebRichEdit1.SetParentComponent(WebPanel3);
    WebRichEdit1.Name := 'WebRichEdit1';
    WebRichEdit1.Left := 10;
    WebRichEdit1.Top := 6;
    WebRichEdit1.Width := 343;
    WebRichEdit1.Height := 51;
    WebRichEdit1.BorderStyle := bsSingle;
    WebRichEdit1.ChildOrder := 2;
    WebRichEdit1.Color := clWhite;
    WebRichEdit1.ReadOnly := True;
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 113;
    WebDBGrid1.Width := 640;
    WebDBGrid1.Height := 223;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'DATUM';
      Title := 'DATUM';
      Width := 137;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BEZEICHNUNG';
      Title := 'BEZEICHNUNG';
      Width := 247;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BEZEICHNUNG2';
      Title := 'BEZEICHNUNG2';
      Width := 215;
    end;
    WebDBGrid1.DataSource := DataModule1.SpeiseplanSource;
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -19;
    WebDBGrid1.Font.Name := 'Arial';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 2;
    SetEvent(WebDBGrid1, Self, 'OnClick', 'WebDBGrid1Click');
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 137;
    WebDBGrid1.ColWidths[2] := 247;
    WebDBGrid1.ColWidths[3] := 215;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebRichEdit1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
  end;
end;

end.