unit UEditBank;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls;

type
  TEditBank = class(TForm)
    WebLabel2: TLabel;
    WebDBEdit2: TDBEdit;
    WebLabel4: TLabel;
    WebDBEdit3: TDBEdit;
    WebLabel5: TLabel;
    WebDBEdit4: TDBEdit;
    WebLabel15: TLabel;
    WebLabel14: TLabel;
    WebLabel13: TLabel;
    WebLabel20: TLabel;
    WebButton2: TButton;
    WebButton1: TButton;
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebLabel3: TLabel;
    WebDBEdit1: TDBEdit;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  EditBank: TEditBank;

implementation


procedure TEditBank.WebButton1Click(Sender: TObject);
begin
  DataModule1.KundenDataSet.Cancel;
  Close;

end;

procedure TEditBank.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(WebDBEdit2.Text);
  WebDBEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Bank muß erfasst werden', mtError, [], nil);
    WebDBEdit2.SetFocus;
    exit;
  end;
  hst := Trim(WebDBEdit3.Text);
  WebDBEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('IBAN muß erfasst werden', mtError, [], nil);
    WebDBEdit3.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit4.Text);
  WebDBEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('BIC muß erfasst werden', mtError, [], nil);
    WebDBEdit4.SetFocus;
    exit;
  end;

  try
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;


end;

procedure TEditBank.WebFormShow(Sender: TObject);
begin
  DataModule1.KundenDataSet.Edit;
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;

end;

procedure TEditBank.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebDBEdit3 := TDBEdit.Create(Self);
  WebDBEdit4 := TDBEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebDBEdit1 := TDBEdit.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebDBEdit3.BeforeLoadDFMValues;
  WebDBEdit4.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebDBEdit1.BeforeLoadDFMValues;
  try
    Name := 'EditBank';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 98;
    WebLabel2.Width := 90;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Name der Bank';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 151;
    WebLabel4.Width := 30;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'IBAN';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 8;
    WebLabel5.Top := 202;
    WebLabel5.Width := 21;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'BIC';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebLabel15.SetParentComponent(Self);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 270;
    WebLabel15.Top := 227;
    WebLabel15.Width := 11;
    WebLabel15.Height := 18;
    WebLabel15.Caption := '*)';
    WebLabel15.Color := clBtnFace;
    WebLabel14.SetParentComponent(Self);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 270;
    WebLabel14.Top := 173;
    WebLabel14.Width := 11;
    WebLabel14.Height := 18;
    WebLabel14.Caption := '*)';
    WebLabel14.Color := clBtnFace;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 270;
    WebLabel13.Top := 117;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel13.Color := clBtnFace;
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 8;
    WebLabel20.Top := 315;
    WebLabel20.Width := 95;
    WebLabel20.Height := 18;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel20.Color := clBtnFace;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 10;
    WebLabel3.Top := 255;
    WebLabel3.Width := 244;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Kontoinhaber (falls abweichend von Name)';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 8;
    WebDBEdit2.Top := 115;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'BANK';
    WebDBEdit2.DataSource := DataModule1.KundenDataSource;
    WebDBEdit3.SetParentComponent(Self);
    WebDBEdit3.Name := 'WebDBEdit3';
    WebDBEdit3.Left := 8;
    WebDBEdit3.Top := 170;
    WebDBEdit3.Width := 248;
    WebDBEdit3.Height := 22;
    WebDBEdit3.ChildOrder := 28;
    WebDBEdit3.ElementClassName := 'form-control';
    WebDBEdit3.Text := 'WebDBEdit1';
    WebDBEdit3.DataField := 'IBAN';
    WebDBEdit3.DataSource := DataModule1.KundenDataSource;
    WebDBEdit4.SetParentComponent(Self);
    WebDBEdit4.Name := 'WebDBEdit4';
    WebDBEdit4.Left := 8;
    WebDBEdit4.Top := 224;
    WebDBEdit4.Width := 248;
    WebDBEdit4.Height := 22;
    WebDBEdit4.ChildOrder := 28;
    WebDBEdit4.ElementClassName := 'form-control';
    WebDBEdit4.Text := 'WebDBEdit1';
    WebDBEdit4.DataField := 'BIC';
    WebDBEdit4.DataSource := DataModule1.KundenDataSource;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 339;
    WebButton2.Width := 177;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 191;
    WebButton1.Top := 339;
    WebButton1.Width := 90;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 10;
    Weblabel301.Top := 33;
    Weblabel301.Width := 80;
    Weblabel301.Height := 16;
    Weblabel301.Caption := 'Akt. Benutzer';
    Weblabel301.Color := clBtnFace;
    Weblabel301.Font.Charset := DEFAULT_CHARSET;
    Weblabel301.Font.Color := clWindowText;
    Weblabel301.Font.Height := -13;
    Weblabel301.Font.Name := 'Arial';
    Weblabel301.Font.Style := [];
    Weblabel301.ParentFont := False;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 10;
    WebEdit1.Top := 55;
    WebEdit1.Width := 271;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebDBEdit1.SetParentComponent(Self);
    WebDBEdit1.Name := 'WebDBEdit1';
    WebDBEdit1.Left := 10;
    WebDBEdit1.Top := 277;
    WebDBEdit1.Width := 248;
    WebDBEdit1.Height := 22;
    WebDBEdit1.ChildOrder := 28;
    WebDBEdit1.ElementClassName := 'form-control';
    WebDBEdit1.Text := 'WebDBEdit1';
    WebDBEdit1.DataField := 'KONTOINHABER';
    WebDBEdit1.DataSource := DataModule1.KundenDataSource;
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebDBEdit3.AfterLoadDFMValues;
    WebDBEdit4.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebDBEdit1.AfterLoadDFMValues;
  end;
end;

end.