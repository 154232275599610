{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.WebTools;

interface

uses
  Classes, Web, JS;

function DoubleToHTML(d: double): string;

procedure MessageBeep(AType: integer);
procedure OutputDebugString(const s: string);
function GetTickCount: longint;
function GetQueryParam(AName: string): string;
function HasQueryParam(AName: string; var AValue: string): boolean;
function EscapeHTML(AValue: string): string;

function GetLocaleShortDateFormat(ALocale: string = ''): string;
function GetLocaleLongDayName(DayOfWeek: integer; ALocale: string = ''): string;
function GetLocaleShortDayName(DayOfWeek: integer; ALocale: string = ''): string;
function GetLocaleLongMonthName(Month: integer; ALocale: string = ''): string;
function GetLocaleShortMonthName(Month: integer; ALocale: string = ''): string;
function GetLocaleDecimalSeparator(ALocale: string = ''): string;
function GetLocaleThousandSeparator(ALocale: string = ''): string;
function LocaleFormatCurrency(Value: double; ACurrency: string; ALocale: string = ''): string;
function GetLocaleCurrency(ALocale: string = ''): string;
function GetBrowserLocale: string;
function ProcessAccelerator(AValue: string; var Accelerator: string): string;
function GetBase64Image(AImage: TJSHTMLElement; AWidth: integer = 0; AHeight: integer = 0): string;
procedure DebugBreak;
function HexImageDecode(s: string): TJSUint8Array;
function HexImageEncodeFromBase64(s: string): string;
function HexImageEncode(a: TJSUint8Array): string;
function HexImageDecodeAsBase64(s: string): string;
function EmptyImage: string;

implementation

uses
  SysUtils, WEBLib.Utils;

function EmptyImage: string;
begin
  Result := 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
end;


function HexImageDecode(s: string): TJSUint8Array;
var
  i,j,l: integer;
  res: TJSUint8Array;
  c1,c2: byte;
  hdr: byte;
begin
  l := Length(s) div 2;
  j := 0;

  c1 := ord(s[1]) - 48;
  if c1 >= 10 then
    c1 := c1 - 7;

  c2 := ord(s[2]) -48;
  if c2 >= 10 then
    c2 := c2 - 7;

  hdr := 1 + (c2 +  (c1 shl 4));

  i := pos('4A5045474',s);
  // possible extra 4 bytes when TJPEGImage?
  if (i > 1) and (i < 10) then
    inc(hdr,4);

  i := pos('4269746D6',s);
  // possible extra 4 bytes when TBitmap?
  if (i > 1) and (i < 10) then
    inc(hdr,4);

  res := TJSUint8Array.new(l - hdr);

  for i := hdr + 1 to l do
  begin
    c1 := ord(s[i*2 - 1]) - 48;
    if c1 >= 10 then
      c1 := c1 - 7;

    c2 := ord(s[i*2]) -48;
    if c2 >= 10 then
      c2 := c2 - 7;

    c2 := c2 +  (c1 shl 4);

    res[j] := c2;
    inc(j);
  end;

  Result := res;
end;


function HexImageEncode(a: TJSUint8Array): string;
var
  i,l: integer;
  b: byte;
  s,ls: string;
begin
  Result := '';

  l := a.length;

  if l = 0 then
    Exit;

  l := (l and $FF) shl 24 + (l and $FF00) shl 8 +  (l and $FF0000) shr 8 + (l and $FF000000) shr 24;

  ls := '';

  if (a[0] = 137) and (a[1] = 80) then
  begin
    s := 'TPngImage';
    b := Length(s);
  end;

  if (a[0] = $FF) and (a[1] = $D8) then
  begin
    s := 'TJPEGImage';
    b := Length(s);
    ls := inttohex(l,8);
  end;

  if (a[0] = 66) and (a[1] = 77) then
  begin
    s := 'TBitmap';
    b := Length(s);
    ls := inttohex(l,8);
  end;

  if (a[0] = 71) and (a[1] = 73) then
  begin
    s := 'TGIFImage';
    b := Length(s);
  end;

  Result := inttohex(b,2);

  for i := 1 to length(s) do
  begin
    b := ord(s[i]);
    Result := Result + inttohex(b,2);
  end;

  Result := Result + ls;

  for i := 0 to a.length - 1 do
  begin
    b := a[i];
    Result := Result + inttohex(b,2);
  end;
end;

{$HINTS OFF}

function HexImageEncodeFromBase64(s: string): string;
var
  ja: TJSUint8Array;
begin
  asm
    s = s.replace(/^data:image\/\S+;base64,/, "");
    //ja = Uint8Array.from(atob(s), c => c.charCodeAt(0))
    ja = Uint8Array.from(atob(s), function(c) { return c.charCodeAt(0);} )
  end;

  Result := HexImageEncode(ja);
end;

function HexImageDecodeAsBase64(s: string): string;
var
  ja: TJSUint8Array;
begin
  ja := HexImageDecode(s);
  asm
    s = btoa(new Uint8Array(ja).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
      }, ''));
  end;
  Result := s;
end;

function GetBase64Image(AImage: TJSHTMLElement; AWidth: integer = 0; AHeight: integer = 0): string;
var
  s: string;
  m: TJSHTMLElement;
begin
  s := '';
  m := AImage;
  asm
    function getBase64Image(img) {
      var canvas = document.createElement("canvas");
      if (AWidth == 0) {
        canvas.width = img.width; }
        else {
        canvas.width = AWidth; }
      if (AHeight == 0) {
      canvas.height = img.height; }
      else {
        canvas.height = AHeight; }
      var ctx = canvas.getContext("2d");
      if ((AWidth == 0) || (AHeight == 0)) {
         ctx.drawImage(img, 0, 0); }
      else {
         ctx.drawImage(img, 0, 0, AWidth, AHeight); }
      var dataURL = canvas.toDataURL("image/png");
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
    s = getBase64Image(m);
  end;
  Result := s;
end;
{$HINTS ON}

procedure DebugBreak;
  asm
    debugger;
  end;

function DoubleToHTML(d: double): string;
begin
  Result := Format('%g',[d]);
  Result := StringReplace(Result, ',','.',[rfReplaceAll]);
end;

procedure OutputDebugString(const s: string);
begin
  asm
    console.log(s);
  end;
end;

function GetTickCount: longint;
var
  n: longint;
begin
  asm
    var d = new Date();
    n = d.getTime();
  end;

  Result := n;
end;

function HasQueryParam(AName: string; var AValue: string): boolean;
var
  found: boolean;
  s: string;
begin
  s := '';
  asm
    var query = window.location.search.substring(1);
    var res = "";
    found = false;
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
       var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == AName) {
          res = decodeURIComponent(pair[1]);
          found = true;
       }
    }
    s = res;
  end;

  AValue := s;
  Result := found;
end;

function GetQueryParam(AName: string): string;
var
  res: string;
begin
  HasQueryParam(AName,res);
  Result := res;
end;


function GetLocaleShortDateFormat(ALocale: string = ''): string;
begin
  Result := TFormatSettings.Create(ALocale).ShortDateFormat;
end;

function GetLocaleLongDayName(DayOfWeek: integer; ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var baseDate = new Date(2017, 0, 1); // Sunday
    baseDate.setDate(baseDate.getDate() + DayOfWeek - 1);

    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }

    res = baseDate.toLocaleDateString(loc, { weekday: 'long' });
  end;
  Result := res;
end;

function GetLocaleShortDayName(DayOfWeek: integer; ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var baseDate = new Date(2017, 0, 1); // Sunday
    baseDate.setDate(baseDate.getDate() + DayOfWeek - 1);

    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }

    res = baseDate.toLocaleDateString(loc, { weekday: 'short' });
  end;
  Result := res;
end;

function GetLocaleLongMonthName(Month: integer; ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var baseDate = new Date(2017, Month - 1, 1);

    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }

    res = baseDate.toLocaleDateString(loc, { month: 'long' });
  end;

  Result := res;
end;

function GetLocaleShortMonthName(Month: integer; ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var baseDate = new Date(2017, Month - 1, 1);

    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }

    res = baseDate.toLocaleDateString(loc, { month: 'short' });
  end;

  Result := res;
end;

function GetLocaleDecimalSeparator(ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var n = 1.1;
    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }

    n = n.toLocaleString(loc).substring(1, 2);
    res = n;
  end;
  Result := res;
end;

function GetLocaleThousandSeparator(ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var n = 1000.1;
    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }
    n = n.toLocaleString(loc).substring(1, 2);
    res = n;
  end;
  Result := res;
end;

function LocaleFormatCurrency(Value: double; ACurrency: string; ALocale: string = ''): string;
var
  res: string;
begin

  asm
    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }
    var number = Value;
    res = number.toLocaleString(loc, { style: 'currency', currency: ACurrency });
  end;
  Result := res;
end;

function GetLocaleCurrency(ALocale: string = ''): string;
var
  res: string;
begin
  asm
    var curr =
      {"BD": "BDT", "BE": "EUR", "BF": "XOF", "BG": "BGN", "BA": "BAM", "BB": "BBD", "WF": "XPF",
       "BL": "EUR", "BM": "BMD", "BN": "BND", "BO": "BOB", "BH": "BHD", "BI": "BIF", "BJ": "XOF",
       "BT": "BTN", "JM": "JMD", "BV": "NOK", "BW": "BWP", "WS": "WST", "BQ": "USD", "BR": "BRL",
       "BS": "BSD", "JE": "GBP", "BY": "BYR", "BZ": "BZD", "RU": "RUB", "RW": "RWF", "RS": "RSD",
       "TL": "USD", "RE": "EUR", "TM": "TMT", "TJ": "TJS", "RO": "RON", "TK": "NZD", "GW": "XOF",
       "GU": "USD", "GT": "GTQ", "GS": "GBP", "GR": "EUR", "GQ": "XAF", "GP": "EUR", "JP": "JPY",
       "GY": "GYD", "GG": "GBP", "GF": "EUR", "GE": "GEL", "GD": "XCD", "GB": "GBP", "GA": "XAF",
       "SV": "USD", "GN": "GNF", "GM": "GMD", "GL": "DKK", "GI": "GIP", "GH": "GHS", "OM": "OMR",
       "TN": "TND", "JO": "JOD", "HR": "HRK", "HT": "HTG", "HU": "HUF", "HK": "HKD", "HN": "HNL",
       "HM": "AUD", "VE": "VEF", "PR": "USD", "PS": "ILS", "PW": "USD", "PT": "EUR", "SJ": "NOK",
       "PY": "PYG", "IQ": "IQD", "PA": "PAB", "PF": "XPF", "PG": "PGK", "PE": "PEN", "PK": "PKR",
       "PH": "PHP", "PN": "NZD", "PL": "PLN", "PM": "EUR", "ZM": "ZMK", "EH": "MAD", "EE": "EUR",
       "EG": "EGP", "ZA": "ZAR", "EC": "USD", "IT": "EUR", "VN": "VND", "SB": "SBD", "ET": "ETB",
       "SO": "SOS", "ZW": "ZWL", "SA": "SAR", "ES": "EUR", "ER": "ERN", "ME": "EUR", "MD": "MDL",
       "MG": "MGA", "MF": "EUR", "MA": "MAD", "MC": "EUR", "UZ": "UZS", "MM": "MMK", "ML": "XOF",
       "MO": "MOP", "MN": "MNT", "MH": "USD", "MK": "MKD", "MU": "MUR", "MT": "EUR", "MW": "MWK",
       "MV": "MVR", "MQ": "EUR", "MP": "USD", "MS": "XCD", "MR": "MRO", "IM": "GBP", "UG": "UGX",
       "TZ": "TZS", "MY": "MYR", "MX": "MXN", "IL": "ILS", "FR": "EUR", "IO": "USD", "SH": "SHP",
       "FI": "EUR", "FJ": "FJD", "FK": "FKP", "FM": "USD", "FO": "DKK", "NI": "NIO", "NL": "EUR",
       "NO": "NOK", "NA": "NAD", "VU": "VUV", "NC": "XPF", "NE": "XOF", "NF": "AUD", "NG": "NGN",
       "NZ": "NZD", "NP": "NPR", "NR": "AUD", "NU": "NZD", "CK": "NZD", "XK": "EUR", "CI": "XOF",
       "CH": "CHF", "CO": "COP", "CN": "CNY", "CM": "XAF", "CL": "CLP", "CC": "AUD", "CA": "CAD",
       "CG": "XAF", "CF": "XAF", "CD": "CDF", "CZ": "CZK", "CY": "EUR", "CX": "AUD", "CR": "CRC",
       "CW": "ANG", "CV": "CVE", "CU": "CUP", "SZ": "SZL", "SY": "SYP", "SX": "ANG", "KG": "KGS",
       "KE": "KES", "SS": "SSP", "SR": "SRD", "KI": "AUD", "KH": "KHR", "KN": "XCD", "KM": "KMF",
       "ST": "STD", "SK": "EUR", "KR": "KRW", "SI": "EUR", "KP": "KPW", "KW": "KWD", "SN": "XOF",
       "SM": "EUR", "SL": "SLL", "SC": "SCR", "KZ": "KZT", "KY": "KYD", "SG": "SGD", "SE": "SEK",
       "SD": "SDG", "DO": "DOP", "DM": "XCD", "DJ": "DJF", "DK": "DKK", "VG": "USD", "DE": "EUR",
       "YE": "YER", "DZ": "DZD", "US": "USD", "UY": "UYU", "YT": "EUR", "UM": "USD", "LB": "LBP",
       "LC": "XCD", "LA": "LAK", "TV": "AUD", "TW": "TWD", "TT": "TTD", "TR": "TRY", "LK": "LKR",
       "LI": "CHF", "LV": "EUR", "TO": "TOP", "LT": "LTL", "LU": "EUR", "LR": "LRD", "LS": "LSL",
       "TH": "THB", "TF": "EUR", "TG": "XOF", "TD": "XAF", "TC": "USD", "LY": "LYD", "VA": "EUR",
       "VC": "XCD", "AE": "AED", "AD": "EUR", "AG": "XCD", "AF": "AFN", "AI": "XCD", "VI": "USD",
       "IS": "ISK", "IR": "IRR", "AM": "AMD", "AL": "ALL", "AO": "AOA", "AQ": "", "AS": "USD",
       "AR": "ARS", "AU": "AUD", "AT": "EUR", "AW": "AWG", "IN": "INR", "AX": "EUR", "AZ": "AZN",
       "IE": "EUR", "ID": "IDR", "UA": "UAH", "QA": "QAR", "MZ": "MZN"}

    var loc = "";
    if (ALocale == '') { loc = navigator.language; }
    else { loc = ALocale; }
    loc = loc.substring(3,5);
    loc = loc.toUpperCase();
    res = curr[loc] || '';
  end;
  Result := res;
end;



function GetBrowserLocale: string;
var
  res: string;
begin
  asm
    res = navigator.language
  end;
  Result := res;
end;


function ProcessAccelerator(AValue: string; var Accelerator: string): string;
var
  i,l: integer;
  res: string;
begin
  l := Length(AValue);

  Accelerator := '';

  res := '';

  i := 1;
  while (i <= l) do
  begin
    if (AValue[i] = '&') then
    begin
      if (i + 1 <= l) and (AValue[i + 1] <> '&') then
      begin
        res := res + '<u>' + AValue[i + 1] + '</u>';
        Accelerator := AValue[i + 1];
      end
      else
      if (i + 1 <= l) and (AValue[i + 1] = '&') then
        res := res + '&';
      inc(i, 2);
    end
    else
    begin
      res := res + AValue[i];
      inc(i);
    end;

  end;

  Result := res;

end;

function EscapeHTML(AValue: string): string;
var
  s: string;
begin
  s := AValue;
  asm
    function escapeHTML(AString) {
      return AString.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
    }
    s = escapeHTML(s);
  end;
  Result := s;
end;

{$HINTS OFF}

procedure MessageBeep(AType: integer);
begin
  asm
    var snd = new Audio("data:audio/wav;base64,//uQRAAAAWMSLwUIYAAsYkXgoQwAEaYLWfkWgAI0wWs/ItAAAGDgYtAgAyN+QWaAAihwMWm4G8QQRDiMcCBcH3Cc+CDv/7xA4Tvh9Rz/y8QADBwMWgQAZG/ILNAARQ4GLTcDeI"+
        "IIhxGOBAuD7hOfBB3/94gcJ3w+o5/5eIAIAAAVwWgQAVQ2ORaIQwEMAJiDg95G4nQL7mQVWI6GwRcfsZAcsKkJvxgxEjzFUgfHoSQ9Qq7KNwqHwuB13MA4a1q/DmBrHgPcmjiGoh//EwC5nGPEmS4RcfkVK"+
	"OhJf+WOgoxJclFz3kgn//dBA+ya1GhurNn8zb//9NNutNuhz31f////9vt///z+IdAEAAAK4LQIAKobHItEIYCGAExBwe8jcToF9zIKrEdDYIuP2MgOWFSE34wYiR5iqQPj0JIeoVdlG4VD4XA67mAcNa1f"+
	"hzA1jwHuTRxDUQ//iYBczjHiTJcIuPyKlHQkv/LHQUYkuSi57yQT//uggfZNajQ3Vmz+Zt//+mm3Wm3Q576v////+32///5/EOgAAADVghQAAAAA//uQZAUAB1WI0PZugAAAAAoQwAAAEk3nRd2qAAAAACi"+
	"DgAAAAAAABCqEEQRLCgwpBGMlJkIz8jKhGvj4k6jzRnqasNKIeoh5gI7BJaC1A1AoNBjJgbyApVS4IDlZgDU5WUAxEKDNmmALHzZp0Fkz1FMTmGFl1FMEyodIavcCAUHDWrKAIA4aa2oCgILEBupZgHvAhE"+
	"BcZ6joQBxS76AgccrFlczBvKLC0QI2cBoCFvfTDAo7eoOQInqDPBtvrDEZBNYN5xwNwxQRfw8ZQ5wQVLvO8OYU+mHvFLlDh05Mdg7BT6YrRPpCBznMB2r//xKJjyyOh+cImr2/4doscwD6neZjuZR4AgAABY"+
	"AAAABy1xcdQtxYBYYZdifkUDgzzXaXn98Z0oi9ILU5mBjFANmRwlVJ3/6jYDAmxaiDG3/6xjQQCCKkRb/6kg/wW+kSJ5//rLobkLSiKmqP/0ikJuDaSaSf/6JiLYLEYnW/+kXg1WRVJL/9EmQ1YZIsv/6Qzw"+
	"y5qk7/+tEU0nkls3/zIUMPKNX/6yZLf+kFgAfgGyLFAUwY//uQZAUABcd5UiNPVXAAAApAAAAAE0VZQKw9ISAAACgAAAAAVQIygIElVrFkBS+Jhi+EAuu+lKAkYUEIsmEAEoMeDmCETMvfSHTGkF5RWH7kz/"+
	"ESHWPAq/kcCRhqBtMdokPdM7vil7RG98A2sc7zO6ZvTdM7pmOUAZTnJW+NXxqmd41dqJ6mLTXxrPpnV8avaIf5SvL7pndPvPpndJR9Kuu8fePvuiuhorgWjp7Mf/PRjxcFCPDkW31srioCExivv9lcwKEaHs"+
	"f/7ow2Fl1T/9RkXgEhYElAoCLFtMArxwivDJJ+bR1HTKJdlEoTELCIqgEwVGSQ+hIm0NbK8WXcTEI0UPoa2NbG4y2K00JEWbZavJXkYaqo9CRHS55FcZTjKEk3NKoCYUnSQ0rWxrZbFKbKIhOKPZe1cJKzZS"+
	"aQrIyULHDZmV5K4xySsDRKWOruanGtjLJXFEmwaIbDLX0hIPBUQPVFVkQkDoUNfSoDgQGKPekoxeGzA4DUvnn4bxzcZrtJyipKfPNy5w+9lnXwgqsiyHNeSVpemw4bWb9psYeq//uQZBoABQt4yMVxYAIAAA"+
	"kQoAAAHvYpL5m6AAgAACXDAAAAD59jblTirQe9upFsmZbpMudy7Lz1X1DYsxOOSWpfPqNX2WqktK0DMvuGwlbNj44TleLPQ+Gsfb+GOWOKJoIrWb3cIMeeON6lz2umTqMXV8Mj30yWPpjoSa9ujK8SyeJP5y5"+
	"mOW1D6hvLepeveEAEDo0mgCRClOEgANv3B9a6fikgUSu/DmAMATrGx7nng5p5iimPNZsfQLYB2sDLIkzRKZOHGAaUyDcpFBSLG9MCQALgAIgQs2YunOszLSAyQYPVC2YdGGeHD2dTdJk1pAHGAWDjnkcLKFym"+
	"S3RQZTInzySoBwMG0QueC3gMsCEYxUqlrcxK6k1LQQcsmyYeQPdC2YfuGPASCBkcVMQQqpVJshui1tkXQJQV0OXGAZMXSOEEBRirXbVRQW7ugq7IM7rPWSZyDlM3IuNEkxzCOJ0ny2ThNkyRai1b6ev//3dzN"+
	"GzNb//4uAvHT5sURcZCFcuKLhOFs8mLAAEAt4UWAAIABAAAAAB4qbHo0tIjVkUU//uQZAwABfSFz3ZqQAAAAAngwAAAE1HjMp2qAAAAACZDgAAAD5UkTE1UgZEUExqYynN1qZvqIOREEFmBcJQkwdxiFtw0q"+
	"EOkGYfRDifBui9MQg4QAHAqWtAWHoCxu1Yf4VfWLPIM2mHDFsbQEVGwyqQoQcwnfHeIkNt9YnkiaS1oizycqJrx4KOQjahZxWbcZgztj2c49nKmkId44S71j0c8eV9yDK6uPRzx5X18eDvjvQ6yKo9ZSS6l/"+
	"/8elePK/Lf//IInrOF/FvDoADYAGBMGb7FtErm5MXMlmPAJQVgWta7Zx2go+8xJ0UiCb8LHHdftWyLJE0QIAIsI+UbXu67dZMjmgDGCGl1H+vpF4NSDckSIkk7Vd+sxEhBQMRU8j/12UIRhzSaUdQ+rQU5kG"+
	"eFxm+hb1oh6pWWmv3uvmReDl0UnvtapVaIzo1jZbf/pD6ElLqSX+rUmOQNpJFa/r+sa4e/pBlAABoAAAAA3CUgShLdGIxsY7AUABPRrgCABdDuQ5GC7DqPQCgbbJUAoRSUj+NIEig0YfyWUho1VBBBA//uQZ"+
	"B4ABZx5zfMakeAAAAmwAAAAF5F3P0w9GtAAACfAAAAAwLhMDmAYWMgVEG1U0FIGCBgXBXAtfMH10000EEEEEECUBYln03TTTdNBDZopopYvrTTdNa325mImNg3TTPV9q3pmY0xoO6bv3r00y+IDGid/9aaaZ"+
	"TGMuj9mpu9Mpio1dXrr5HERTZSmqU36A3CumzN/9Robv/Xx4v9ijkSRSNLQhAWumap82WRSBUqXStV/YcS+XVLnSS+WLDroqArFkMEsAS+eWmrUzrO0oEmE40RlMZ5+ODIkAyKAGUwZ3mVKmcamcJnMW26MRP"+
	"gUw6j+LkhyHGVGYjSUUKNpuJUQoOIAyDvEyG8S5yfK6dhZc0Tx1KI/gviKL6qvvFs1+bWtaz58uUNnryq6kt5RzOCkPWlVqVX2a/EEBUdU1KrXLf40GoiiFXK///qpoiDXrOgqDR38JB0bw7SoL+ZB9o1RCk"+
	"QjQ2CBYZKd/+VJxZRRZlqSkKiws0WFxUyCwsKiMy7hUVFhIaCrNQsKkTIsLivwKKigsj8XYlwt/WKi2N4d//uQRCSAAjURNIHpMZBGYiaQPSYyAAABLAAAAAAAACWAAAAApUF/Mg+0aohSIRobBAsMlO//Kk"+
	"4soosy1JSFRYWaLC4qZBYWFRGZdwqKiwkNBVmoWFSJkWFxX4FFRQWR+LsS4W/rFRb////////////////////////////////////////////////////////////////////////////////////////////"+
	"////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////"+
	"////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////"+
	"/////////////VEFHAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAU291bmRib3kuZGUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMjAwNGh0dHA6Ly93d3cuc291bmRib3kuZGUAAAAAAAAAACU=");
    snd.play();
  end;
end;

{$HINTS ON}

end.

