unit UConfirmEmail;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  UDatamodule,
  UGlobvar, WEBLib.DBCtrls, WEBLib.StdCtrls;


type
  TConfirmEmail = class(TForm)
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebButton2: TButton;
    WebButton1: TButton;
    WebLabel1: TLabel;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  ConfirmEmail: TConfirmEmail;

implementation


procedure TConfirmEmail.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TConfirmEmail.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Bestätigungscode muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;
  if WebEdit2.Text <> DataModule1.KundenDataSet.FieldByName('Bestaetigung').AsString then begin
    MessageDlg('Bestätigungscode ist ungültig', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;


  try
    DataModule1.KundenDataSet.Edit;
    DataModule1.KundenDataSet.FieldByName('Status').AsInteger := 1;
    DataModule1.KundenDataSet.FieldByName('Confirmed').AsDateTime := now;
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;

end;

procedure TConfirmEmail.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;

end;

procedure TConfirmEmail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Weblabel301 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  Weblabel301.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'ConfirmEmail';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Weblabel301.SetParentComponent(Self);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 65;
    Weblabel301.Height := 18;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 97;
    WebLabel2.Width := 126;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Best'#228'tigungscode';
    WebLabel2.Color := clBtnFace;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 162;
    WebEdit2.Top := 94;
    WebEdit2.Width := 177;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 8;
    WebEdit2.ElementClassName := 'form-control';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 249;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := 'eMail-Adresse best'#228'tigen';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 265;
    WebButton1.Top := 249;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    Weblabel301.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.