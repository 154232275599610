unit UEditEinrichtung;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,

  WEBLib.ExtCtrls, WEBLib.DBCtrls,

  XData.Web.Client,
  UDatamodule,
  UGlobvar;


type
  TEditEinrichtung = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebButton2: TButton;
    WebButton3: TButton;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebDBEdit1: TDBEdit;
    WebDBEdit2: TDBEdit;
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure After_Insert_Einrichtung(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Letzte_ID : integer;
  end;

var
  EditEinrichtung: TEditEinrichtung;
  Einrichtung_Insert_Mode : boolean;


procedure Edit_Einrichtungen(Sender: TObject);


implementation






procedure Edit_Einrichtungen(Sender: TObject);
var
  newform: TEditEinrichtung;

begin
  Einrichtung_Insert_Mode := false;
  Datamodule1.EinrichtungDataSet.Edit;
  newform := TEditEinrichtung.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditEinrichtung).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;




procedure TEditEinrichtung.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
    i,j : integer;
begin
  hst := Trim(WebDBEdit1.Text);
  i := 0;
  j := 0;
  val(hst,i,j);
  if (i<=0) or (j<>0) then begin
    MessageDlg('Nummer ist ungültig', mtError, [], nil);
    WebDBEdit1.SetFocus;
    exit;
  end;
  hst := Trim(WebDBEdit2.Text);
  WebDBEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Bezeichnung muß erfasst werden', mtError, [], nil);
    WebDBEdit2.SetFocus;
    exit;
  end;

  if not Einrichtung_Insert_Mode then begin
    try
      Datamodule1.EinrichtungDataSet.Post;
      Datamodule1.EinrichtungDataSet.ApplyUpdates;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else begin

//SELECT p.NEWID
//FROM STD_INSERT_TEILNEHMER ('P_ID', 'P_NL_KEY', 'P_KDID', 'P_LFDNO', 'P_NAME',
//    'P_EINRICHTUNG', 'P_GESCHLECHT', 'P_GEBURTSDATUM', 'P_LKZ', 'P_LOESCHUNG', 'P_PLAN1', 'P_PLAN2', 'P_PLAN3', 'P_PLAN4', 'P_PLAN5', 'P_PLAN6', 'P_PLAN7') p;

// SELECT p.NEWID FROM STD_INSERT_EINRICHTUNGEN ('P_ID', 'P_NL_KEY', 'P_NUMMER', 'P_BEZEICHNUNG',
//    'P_EMAIL', 'P_TELEFON') p;


    hst2 := 'Select p.NEWID FROM STD_INSERT_Einrichtungen(0,'+IntToStr(AktFirmaNo)+','
    +AFZ(WebDBEdit1.Text)
    +AFZ(WebDBEdit2.Text)
    +AFZ('')
    +AFZ2('')
    +') p';


  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Einrichtung nicht gespeichert', mtError, [], nil);
        end
        else begin
          Letzte_ID := i;

          Datamodule1.EinrichtungDataSet.Close;
          Datamodule1.EinrichtungDataSet.AfterOpen := After_Insert_Einrichtung;
          if Lade_Daten_2(Datamodule1.EinrichtungDataSet,'EINRICHTUNGEN','$filter=(NL_Key eq '+IntToStr(AktFirmaNo) + ')','') then begin
          end;
//          if Datamodule1.TeilnehmerDataSet.Locate('ID',i,[]) then ;
          Close;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;

//      window.localStorage.setItem(cStorageTokenName, Token);
//      CheckLogin;
    end
  );

  end;



  Close;

end;

procedure TEditEinrichtung.WebButton3Click(Sender: TObject);
begin
  Close;
end;


procedure TEditEinrichtung.After_Insert_Einrichtung(DataSet: TDataSet);
begin
  if Letzte_ID <> -1 then begin
    if Datamodule1.EinrichtungDataSet.Locate('ID',Letzte_ID,[]) then ;
    Letzte_ID := -1;
  end;
  Datamodule1.EinrichtungDataSet.AfterOpen := nil;

end;

procedure TEditEinrichtung.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebDBEdit1 := TDBEdit.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebDBEdit1.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  try
    Name := 'EditEinrichtung';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 108;
    WebLabel2.Width := 59;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Nummer';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 147;
    WebLabel3.Width := 89;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Bezeichnung';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 65;
    Weblabel301.Height := 18;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 361;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 264;
    WebButton3.Top := 361;
    WebButton3.Width := 96;
    WebButton3.Height := 41;
    WebButton3.Caption := 'Zur'#252'ck';
    WebButton3.ChildOrder := 6;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebDBEdit1.SetParentComponent(Self);
    WebDBEdit1.Name := 'WebDBEdit1';
    WebDBEdit1.Left := 144;
    WebDBEdit1.Top := 108;
    WebDBEdit1.Width := 81;
    WebDBEdit1.Height := 22;
    WebDBEdit1.ChildOrder := 5;
    WebDBEdit1.Text := 'WebDBEdit1';
    WebDBEdit1.DataField := 'NUMMER';
    WebDBEdit1.DataSource := DataModule1.EinrichtungSource;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 144;
    WebDBEdit2.Top := 144;
    WebDBEdit2.Width := 266;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 5;
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'BEZEICHNUNG';
    WebDBEdit2.DataSource := DataModule1.EinrichtungSource;
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebDBEdit1.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
  end;
end;

end.