unit UEditPasswort;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, UDatamodule,
  UGlobvar, WEBLib.DBCtrls;

type
  TEditPasswort = class(TForm)
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebButton2: TButton;
    WebButton1: TButton;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebLabel3: TLabel;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  EditPasswort: TEditPasswort;

implementation


procedure TEditPasswort.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TEditPasswort.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Altes Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;
  hst := Trim(WebEdit3.Text);
  WebEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Neues Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Leerzeichen im Kennwort nicht erlaubt', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  if length(hst) < 6 then begin
    MessageDlg('Kennwort muß mindestens 6 Zeichen lang sein', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;



  hst := Trim(WebEdit4.Text);
  WebEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung neues Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit4.SetFocus;
    exit;
  end;

  if WebEdit3.Text <> WebEdit4.Text then begin
    MessageDlg('Neues Kennwort und Wiederholung neues Kennwort nicht identisch', mtError, [], nil);
    WebEdit4.SetFocus;
    exit;
  end;

  if WebEdit2.Text <> DataModule1.KundenDataSet.FieldByName('Kennwort').AsString then begin
    MessageDlg('Altes Kennwort ist ungültig', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;


  try
    DataModule1.KundenDataSet.Edit;
    DataModule1.KundenDataSet.FieldByName('Kennwort').AsString := WebEdit3.Text;
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;

end;

procedure TEditPasswort.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;

end;

procedure TEditPasswort.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  try
    Name := 'EditPasswort';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(Self);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 65;
    Weblabel301.Height := 18;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 120;
    WebLabel2.Width := 102;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'altes Kennwort';
    WebLabel2.Color := clBtnFace;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 6;
    WebLabel4.Top := 151;
    WebLabel4.Width := 111;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'neues Kennwort';
    WebLabel4.Color := clBtnFace;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 6;
    WebLabel5.Top := 182;
    WebLabel5.Width := 96;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'Wiederholung';
    WebLabel5.Color := clBtnFace;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 206;
    WebLabel3.Width := 111;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'neues Kennwort';
    WebLabel3.Color := clBtnFace;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 128;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 249;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 265;
    WebButton1.Top := 249;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 128;
    WebEdit2.Top := 120;
    WebEdit2.Width := 177;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 8;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.PasswordChar := '*';
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 128;
    WebEdit3.Top := 151;
    WebEdit3.Width := 177;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 8;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.PasswordChar := '*';
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 128;
    WebEdit4.Top := 203;
    WebEdit4.Width := 177;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 8;
    WebEdit4.ElementClassName := 'form-control';
    WebEdit4.PasswordChar := '*';
  finally
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
  end;
end;

end.