unit Udatamodule;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Modules, DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection, WEBLib.DB,
  WEBLib.Dialogs, WEBLib.CDS, WEBLib.SQLCDS, XData.Web.Client,
  UGlobvar;


const
  KDDataset_CheckEmail = 1;
  KDDataset_PasswordReset = 2;
  KDDataset_FindKey = 3;



type
  TDataModule1 = class(TDataModule)
    XDataWebConnection1: TXDataWebConnection;
    KundenDataSet: TXDataWebDataSet;
    KundenDataSource: TDataSource;
    XDataClient: TXDataWebClient;
    KundenDataSetID: TIntegerField;
    KundenDataSetNL_KEY: TIntegerField;
    KundenDataSetKDNO: TIntegerField;
    KundenDataSetNAME: TStringField;
    KundenDataSetNAME2: TStringField;
    KundenDataSetSTRASSE: TStringField;
    KundenDataSetPLZ: TStringField;
    KundenDataSetORT: TStringField;
    KundenDataSetTELEFON: TStringField;
    KundenDataSetHANDY: TStringField;
    KundenDataSetEMAILADR: TStringField;
    KundenDataSetKENNWORT: TStringField;
    KundenDataSetDATUHR: TDateTimeField;
    KundenDataSetCONFIRMED: TDateTimeField;
    KundenDataSetUPC_EMAIL: TStringField;
    KundenDataSetDATUHR2: TDateTimeField;
    KundenDataSetBESTAETIGUNG: TStringField;
    KundenDataSetAKTIV: TStringField;
    KundenDataSetSTATUS: TIntegerField;
    KundenDataSetANREDE: TStringField;
    KundenDataSetAKTITEL: TStringField;
    KundenDataSetIBAN: TStringField;
    KundenDataSetBIC: TStringField;
    KundenDataSetBANK: TStringField;
    KundenDataSetKZBERECHTIGUNG: TIntegerField;
    TeilnehmerDataSource: TDataSource;
    TeilnehmerDataSet: TXDataWebDataSet;
    TeilnehmerDataSetID: TIntegerField;
    TeilnehmerDataSetNL_KEY: TIntegerField;
    TeilnehmerDataSetKDID: TIntegerField;
    TeilnehmerDataSetLFDNO: TIntegerField;
    TeilnehmerDataSetNAME: TStringField;
    XDataWebClient1: TXDataWebClient;
    TeilnehmerDataSetEINRICHTUNG: TIntegerField;
    TeilnehmerDataSetGESCHLECHT: TStringField;
    TeilnehmerDataSetGEBURTSDATUM: TDateTimeField;
    TeilnehmerDataSetLKZ: TStringField;
    TeilnehmerDataSetLOESCHUNG: TDateTimeField;
    TeilnehmerDataSetPLAN1: TIntegerField;
    TeilnehmerDataSetPLAN2: TIntegerField;
    TeilnehmerDataSetPLAN3: TIntegerField;
    TeilnehmerDataSetPLAN4: TIntegerField;
    TeilnehmerDataSetPLAN5: TIntegerField;
    TeilnehmerDataSetPLAN6: TIntegerField;
    TeilnehmerDataSetPLAN7: TIntegerField;
    TeilnehmerDataSetEINRICHTUNGBEZ: TStringField;
    TeilnehmerDataSetTT: TIntegerField;
    TeilnehmerDataSetMM: TIntegerField;
    TeilnehmerDataSetJJ: TIntegerField;
    TeilnehmerDataSetKZ_BERECHTIGUNG: TIntegerField;
    EinrichtungSource: TDataSource;
    EinrichtungDataSet: TXDataWebDataSet;
    AllergieDataSet: TXDataWebDataSet;
    AllergieDataSetTEILNEHMERID: TIntegerField;
    AllergieDataSetALLERGENID: TIntegerField;
    VerpflegungsartSource: TDataSource;
    VerpflegungsArtDataSet: TXDataWebDataSet;
    VerpflegungsArtDataSetID: TIntegerField;
    VerpflegungsArtDataSetNL_KEY: TIntegerField;
    VerpflegungsArtDataSetEID: TIntegerField;
    VerpflegungsArtDataSetLFDNO: TIntegerField;
    VerpflegungsArtDataSetBEZEICHNUNG: TStringField;
    VerpflegungsArtDataSetREIHENFOLGE: TIntegerField;
    VerpflegungsArtDataSetKUERZEL: TStringField;
    VariantenSource: TDataSource;
    VariantenDataSet: TXDataWebDataSet;
    IntegerField1: TIntegerField;
    IntegerField2: TIntegerField;
    IntegerField3: TIntegerField;
    IntegerField4: TIntegerField;
    StringField1: TStringField;
    IntegerField5: TIntegerField;
    StringField2: TStringField;
    TeilnehmerDataSetVARIANTE: TIntegerField;
    KundenDataSetADMINLEVEL: TIntegerField;
    EinrichtungDataSetID: TIntegerField;
    EinrichtungDataSetNL_KEY: TIntegerField;
    EinrichtungDataSetNUMMER: TIntegerField;
    EinrichtungDataSetBEZEICHNUNG: TStringField;
    XDataWebClient2: TXDataWebClient;
    EinrichtungDataSetEMAIL: TStringField;
    WebClientConnection1: TClientConnection;
    SpeiseplanSource: TDataSource;
    Speiseplandataset: TXDataWebDataSet;
    SpeiseplandatasetNL_KEY: TIntegerField;
    SpeiseplandatasetARTIKEL: TIntegerField;
    SpeiseplandatasetDATUM: TDateTimeField;
    SpeiseplandatasetENO: TIntegerField;
    SpeiseplandatasetBESTELLT: TIntegerField;
    SpeiseplandatasetGELIEFERT: TIntegerField;
    SpeiseplandatasetBEWERTUNG1: TIntegerField;
    SpeiseplandatasetBEWERTUNG2: TIntegerField;
    SpeiseplandatasetBEWERTUNG3: TIntegerField;
    SpeiseplandatasetBEZEICHNUNG: TStringField;
    SpeiseplandatasetBEZEICHNUNG2: TStringField;
    TeilnehmerDataSetTID: TIntegerField;
    TeilnehmerDataSetCHIPID: TStringField;
    TeilnehmerDataSetAKTIV: TIntegerField;
    KundenDataSetKDGRUPPE: TIntegerField;
    KundenDataSetZAHLBED: TIntegerField;
    KundenDataSetABRECHNUNGSART: TIntegerField;
    KundenDataSetCHIPID: TStringField;
    KundenDataSetPREISLISTE: TIntegerField;
    KundenDataSetKONTOINHABER: TStringField;
    procedure KundenDataSetAfterOpen(DataSet: TDataSet);
    procedure WebDataModuleCreate(Sender: TObject);
    procedure WebClientDataSet2PictureGetText(Sender: TField;var Text: string; DisplayText: Boolean);
    procedure WebClientDataSet2URLGetText(Sender: TField; var Text: string;DisplayText: Boolean);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;




var
  DataModule1: TDataModule1;

  KundenDataSet_Mode : integer;



function AFZ(st : String) : String;

function AFZ2(st : String) : String;

function AFZ_Now(st : String) : String;



function Lade_Daten(var DS : TXDataWebDataSet;AEntity,Qst : String) : boolean;

function Lade_Daten_2(var DS : TXDataWebDataSet;AEntity,Qst,OrderStr : String) : boolean;



function GetKundebyEmail(Modus : integer;Eadr : String) : boolean;

function Do_Post(DS : TXDataWebDataSet) : integer;


function Do_Find(DS : TXDataWebDataSet;FName,V : String) : boolean;


function Get_AktKDID(NLK : integer;eAdr : String) : integer;

function Get_AktEinrichtungID(NLK : integer;Nummer : integer) : integer;



implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}



function AFZ(st : String) : String;
begin
  AFZ := ''''+st+''''+',';
end;

function AFZ2(st : String) : String;
begin
  AFZ2 := ''''+st+'''';
end;

function AFZ_Now(st : String) : String;
begin
  AFZ_Now := ''''+DateTimeToStr(Now)+''''+',';
end;





function Do_Post(DS : TXDataWebDataSet) : integer;
begin
  Do_Post := 0;
  if DS.State in [dsEdit,dsInsert] then begin
    try
      DS.Post;
      DS.ApplyUpdates;
    except
      Do_Post := -1;
    end;
  end;
end;


function GetKundebyEmail(Modus : integer;Eadr : String) : boolean;
var hst : String;
begin
  GetKundebyEmail := false;
  KundenDataSet_Mode := Modus;



  Datamodule1.XDataWebConnection1.Connected := true;
  DataModule1.KundenDataset.Active := false;
  hst := Trim(Uppercase(Eadr));
//  DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
  DataModule1.KundenDataset.QueryString := '$filter=UPC_EMAIL eq '+''''+hst+'''';
//  DataModule1.KundenDataset.Active := true;
  DataModule1.KundenDataset.Load;

  GetKundebyEmail := true;
end;


function Do_Find(DS : TXDataWebDataSet;FName,V : String) : boolean;
begin
  DS.Close;  // Active := false;
  DS.QueryString := '$filter=('+Fname+' eq '+''''+V+''''+')';
  DS.Load;
//  Do_Find := not DS.FieldByName(FName).IsNull;
end;


function Get_AktKDID(NLK : integer;eAdr : String) : integer;
var hst,hst2 : String;
begin
  Get_AktKDID := -1;
  hst2 := 'Select ID FROM KUNDEN where NL_Key='+IntToStr(NLK)+' and UPC_EMail='+''''+uppercase(eAdr) + '''';
  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i > 0 then begin
          AktKDID := i;
        end
        else begin
          MessageDlg('Kunden-ID nicht gefunden', mtError, [], nil);
        end;
      end
      else begin
        MessageDlg('Kunden-ID nicht gefunden', mtError, [], nil);
      end;
    end
  );
end;



procedure TDataModule1.KundenDataSetAfterOpen(DataSet: TDataSet);
begin
  case KundenDataSet_Mode of
    KDDataset_PasswordReset : begin
                             if KundenDataSet.RecordCount = 0 then begin
                               MessageDlg('Kein Kundenkonto mit dieser Email-Adresse gefunden', mtError, [], nil);
                             end

                           end;

  end;

end;

procedure TDataModule1.WebDataModuleCreate(Sender: TObject);
begin
//  Datamodule1.WebClientDataSet2.Active := true;

end;


procedure TDataModule1.WebClientDataSet2PictureGetText(Sender: TField;
  var Text: string; DisplayText: Boolean);
begin
  Text := 'https://download.tmssoftware.com/tmsweb/demos/TMSWEB_ResponsiveGrid/img/' + StringReplace(Sender.AsString, '.jpg', '_96.jpg', [rfReplaceAll]);
end;

procedure TDataModule1.WebClientDataSet2URLGetText(Sender: TField; var Text: string;
  DisplayText: Boolean);
begin
  Text := 'https://' + Sender.AsString;
end;



function Lade_Daten(var DS : TXDataWebDataSet;AEntity,Qst : String) : boolean;


  procedure OnSuccess(Response: TXDataClientResponse);
  begin
    DS.SetJsonData(Response.Result);
    DS.Open;
  end;

begin

  DS.Close;
  DataModule1.XDataWebClient1.List(AEntity,Qst,@OnSuccess);

//
//  TeilnehmerDataSet.Load;
end;


function Lade_Daten_2(var DS : TXDataWebDataSet;AEntity,Qst,OrderStr : String) : boolean;


  procedure OnSuccess(Response: TXDataClientResponse);
  begin
    DS.SetJsonData(Response.Result);
    DS.Open;
  end;

begin

  DS.Close;
  DataModule1.XDataWebClient2.List(AEntity,Qst,@OnSuccess);

//
//  TeilnehmerDataSet.Load;
end;



function Get_AktEinrichtungID(NLK : integer;Nummer : integer) : integer;
var hst,hst2 : String;
begin
  Get_AktEinrichtungID := -1;
  hst2 := 'Select ID FROM Einrichtungen where NL_Key='+IntToStr(NLK)+' and Nummer='+IntToStr(Nummer)+ '''';
  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i > 0 then begin
          AktEinrichtungID := i;
        end
        else begin
          MessageDlg('Einrichtung-ID nicht gefunden', mtError, [], nil);
        end;
      end
      else begin
        MessageDlg('Einrichtung-ID nicht gefunden', mtError, [], nil);
      end;
    end
  );
end;


procedure TDataModule1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  XDataWebConnection1 := TXDataWebConnection.Create(Self);
  KundenDataSet := TXDataWebDataSet.Create(Self);
  KundenDataSetID := TIntegerField.Create(Self);
  KundenDataSetNL_KEY := TIntegerField.Create(Self);
  KundenDataSetKDNO := TIntegerField.Create(Self);
  KundenDataSetNAME := TStringField.Create(Self);
  KundenDataSetNAME2 := TStringField.Create(Self);
  KundenDataSetSTRASSE := TStringField.Create(Self);
  KundenDataSetPLZ := TStringField.Create(Self);
  KundenDataSetORT := TStringField.Create(Self);
  KundenDataSetTELEFON := TStringField.Create(Self);
  KundenDataSetHANDY := TStringField.Create(Self);
  KundenDataSetEMAILADR := TStringField.Create(Self);
  KundenDataSetKENNWORT := TStringField.Create(Self);
  KundenDataSetDATUHR := TDateTimeField.Create(Self);
  KundenDataSetCONFIRMED := TDateTimeField.Create(Self);
  KundenDataSetUPC_EMAIL := TStringField.Create(Self);
  KundenDataSetDATUHR2 := TDateTimeField.Create(Self);
  KundenDataSetBESTAETIGUNG := TStringField.Create(Self);
  KundenDataSetAKTIV := TStringField.Create(Self);
  KundenDataSetSTATUS := TIntegerField.Create(Self);
  KundenDataSetANREDE := TStringField.Create(Self);
  KundenDataSetAKTITEL := TStringField.Create(Self);
  KundenDataSetIBAN := TStringField.Create(Self);
  KundenDataSetBIC := TStringField.Create(Self);
  KundenDataSetBANK := TStringField.Create(Self);
  KundenDataSetKZBERECHTIGUNG := TIntegerField.Create(Self);
  KundenDataSetADMINLEVEL := TIntegerField.Create(Self);
  KundenDataSetKDGRUPPE := TIntegerField.Create(Self);
  KundenDataSetZAHLBED := TIntegerField.Create(Self);
  KundenDataSetABRECHNUNGSART := TIntegerField.Create(Self);
  KundenDataSetCHIPID := TStringField.Create(Self);
  KundenDataSetPREISLISTE := TIntegerField.Create(Self);
  KundenDataSetKONTOINHABER := TStringField.Create(Self);
  KundenDataSource := TDataSource.Create(Self);
  XDataClient := TXDataWebClient.Create(Self);
  TeilnehmerDataSource := TDataSource.Create(Self);
  TeilnehmerDataSet := TXDataWebDataSet.Create(Self);
  TeilnehmerDataSetID := TIntegerField.Create(Self);
  TeilnehmerDataSetNL_KEY := TIntegerField.Create(Self);
  TeilnehmerDataSetKDID := TIntegerField.Create(Self);
  TeilnehmerDataSetLFDNO := TIntegerField.Create(Self);
  TeilnehmerDataSetNAME := TStringField.Create(Self);
  TeilnehmerDataSetEINRICHTUNG := TIntegerField.Create(Self);
  TeilnehmerDataSetGESCHLECHT := TStringField.Create(Self);
  TeilnehmerDataSetGEBURTSDATUM := TDateTimeField.Create(Self);
  TeilnehmerDataSetLKZ := TStringField.Create(Self);
  TeilnehmerDataSetLOESCHUNG := TDateTimeField.Create(Self);
  TeilnehmerDataSetPLAN1 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN2 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN3 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN4 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN5 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN6 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN7 := TIntegerField.Create(Self);
  TeilnehmerDataSetEINRICHTUNGBEZ := TStringField.Create(Self);
  TeilnehmerDataSetTT := TIntegerField.Create(Self);
  TeilnehmerDataSetMM := TIntegerField.Create(Self);
  TeilnehmerDataSetJJ := TIntegerField.Create(Self);
  TeilnehmerDataSetKZ_BERECHTIGUNG := TIntegerField.Create(Self);
  TeilnehmerDataSetVARIANTE := TIntegerField.Create(Self);
  TeilnehmerDataSetTID := TIntegerField.Create(Self);
  TeilnehmerDataSetCHIPID := TStringField.Create(Self);
  TeilnehmerDataSetAKTIV := TIntegerField.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);
  EinrichtungSource := TDataSource.Create(Self);
  EinrichtungDataSet := TXDataWebDataSet.Create(Self);
  EinrichtungDataSetID := TIntegerField.Create(Self);
  EinrichtungDataSetNL_KEY := TIntegerField.Create(Self);
  EinrichtungDataSetNUMMER := TIntegerField.Create(Self);
  EinrichtungDataSetBEZEICHNUNG := TStringField.Create(Self);
  EinrichtungDataSetEMAIL := TStringField.Create(Self);
  AllergieDataSet := TXDataWebDataSet.Create(Self);
  AllergieDataSetTEILNEHMERID := TIntegerField.Create(Self);
  AllergieDataSetALLERGENID := TIntegerField.Create(Self);
  VerpflegungsartSource := TDataSource.Create(Self);
  VerpflegungsArtDataSet := TXDataWebDataSet.Create(Self);
  VerpflegungsArtDataSetID := TIntegerField.Create(Self);
  VerpflegungsArtDataSetNL_KEY := TIntegerField.Create(Self);
  VerpflegungsArtDataSetEID := TIntegerField.Create(Self);
  VerpflegungsArtDataSetLFDNO := TIntegerField.Create(Self);
  VerpflegungsArtDataSetBEZEICHNUNG := TStringField.Create(Self);
  VerpflegungsArtDataSetREIHENFOLGE := TIntegerField.Create(Self);
  VerpflegungsArtDataSetKUERZEL := TStringField.Create(Self);
  VariantenSource := TDataSource.Create(Self);
  VariantenDataSet := TXDataWebDataSet.Create(Self);
  IntegerField1 := TIntegerField.Create(Self);
  IntegerField2 := TIntegerField.Create(Self);
  IntegerField3 := TIntegerField.Create(Self);
  IntegerField4 := TIntegerField.Create(Self);
  StringField1 := TStringField.Create(Self);
  IntegerField5 := TIntegerField.Create(Self);
  StringField2 := TStringField.Create(Self);
  XDataWebClient2 := TXDataWebClient.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);
  SpeiseplanSource := TDataSource.Create(Self);
  Speiseplandataset := TXDataWebDataSet.Create(Self);
  SpeiseplandatasetNL_KEY := TIntegerField.Create(Self);
  SpeiseplandatasetARTIKEL := TIntegerField.Create(Self);
  SpeiseplandatasetDATUM := TDateTimeField.Create(Self);
  SpeiseplandatasetENO := TIntegerField.Create(Self);
  SpeiseplandatasetBESTELLT := TIntegerField.Create(Self);
  SpeiseplandatasetGELIEFERT := TIntegerField.Create(Self);
  SpeiseplandatasetBEWERTUNG1 := TIntegerField.Create(Self);
  SpeiseplandatasetBEWERTUNG2 := TIntegerField.Create(Self);
  SpeiseplandatasetBEWERTUNG3 := TIntegerField.Create(Self);
  SpeiseplandatasetBEZEICHNUNG := TStringField.Create(Self);
  SpeiseplandatasetBEZEICHNUNG2 := TStringField.Create(Self);

  XDataWebConnection1.BeforeLoadDFMValues;
  KundenDataSet.BeforeLoadDFMValues;
  KundenDataSetID.BeforeLoadDFMValues;
  KundenDataSetNL_KEY.BeforeLoadDFMValues;
  KundenDataSetKDNO.BeforeLoadDFMValues;
  KundenDataSetNAME.BeforeLoadDFMValues;
  KundenDataSetNAME2.BeforeLoadDFMValues;
  KundenDataSetSTRASSE.BeforeLoadDFMValues;
  KundenDataSetPLZ.BeforeLoadDFMValues;
  KundenDataSetORT.BeforeLoadDFMValues;
  KundenDataSetTELEFON.BeforeLoadDFMValues;
  KundenDataSetHANDY.BeforeLoadDFMValues;
  KundenDataSetEMAILADR.BeforeLoadDFMValues;
  KundenDataSetKENNWORT.BeforeLoadDFMValues;
  KundenDataSetDATUHR.BeforeLoadDFMValues;
  KundenDataSetCONFIRMED.BeforeLoadDFMValues;
  KundenDataSetUPC_EMAIL.BeforeLoadDFMValues;
  KundenDataSetDATUHR2.BeforeLoadDFMValues;
  KundenDataSetBESTAETIGUNG.BeforeLoadDFMValues;
  KundenDataSetAKTIV.BeforeLoadDFMValues;
  KundenDataSetSTATUS.BeforeLoadDFMValues;
  KundenDataSetANREDE.BeforeLoadDFMValues;
  KundenDataSetAKTITEL.BeforeLoadDFMValues;
  KundenDataSetIBAN.BeforeLoadDFMValues;
  KundenDataSetBIC.BeforeLoadDFMValues;
  KundenDataSetBANK.BeforeLoadDFMValues;
  KundenDataSetKZBERECHTIGUNG.BeforeLoadDFMValues;
  KundenDataSetADMINLEVEL.BeforeLoadDFMValues;
  KundenDataSetKDGRUPPE.BeforeLoadDFMValues;
  KundenDataSetZAHLBED.BeforeLoadDFMValues;
  KundenDataSetABRECHNUNGSART.BeforeLoadDFMValues;
  KundenDataSetCHIPID.BeforeLoadDFMValues;
  KundenDataSetPREISLISTE.BeforeLoadDFMValues;
  KundenDataSetKONTOINHABER.BeforeLoadDFMValues;
  KundenDataSource.BeforeLoadDFMValues;
  XDataClient.BeforeLoadDFMValues;
  TeilnehmerDataSource.BeforeLoadDFMValues;
  TeilnehmerDataSet.BeforeLoadDFMValues;
  TeilnehmerDataSetID.BeforeLoadDFMValues;
  TeilnehmerDataSetNL_KEY.BeforeLoadDFMValues;
  TeilnehmerDataSetKDID.BeforeLoadDFMValues;
  TeilnehmerDataSetLFDNO.BeforeLoadDFMValues;
  TeilnehmerDataSetNAME.BeforeLoadDFMValues;
  TeilnehmerDataSetEINRICHTUNG.BeforeLoadDFMValues;
  TeilnehmerDataSetGESCHLECHT.BeforeLoadDFMValues;
  TeilnehmerDataSetGEBURTSDATUM.BeforeLoadDFMValues;
  TeilnehmerDataSetLKZ.BeforeLoadDFMValues;
  TeilnehmerDataSetLOESCHUNG.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN1.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN2.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN3.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN4.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN5.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN6.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN7.BeforeLoadDFMValues;
  TeilnehmerDataSetEINRICHTUNGBEZ.BeforeLoadDFMValues;
  TeilnehmerDataSetTT.BeforeLoadDFMValues;
  TeilnehmerDataSetMM.BeforeLoadDFMValues;
  TeilnehmerDataSetJJ.BeforeLoadDFMValues;
  TeilnehmerDataSetKZ_BERECHTIGUNG.BeforeLoadDFMValues;
  TeilnehmerDataSetVARIANTE.BeforeLoadDFMValues;
  TeilnehmerDataSetTID.BeforeLoadDFMValues;
  TeilnehmerDataSetCHIPID.BeforeLoadDFMValues;
  TeilnehmerDataSetAKTIV.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  EinrichtungSource.BeforeLoadDFMValues;
  EinrichtungDataSet.BeforeLoadDFMValues;
  EinrichtungDataSetID.BeforeLoadDFMValues;
  EinrichtungDataSetNL_KEY.BeforeLoadDFMValues;
  EinrichtungDataSetNUMMER.BeforeLoadDFMValues;
  EinrichtungDataSetBEZEICHNUNG.BeforeLoadDFMValues;
  EinrichtungDataSetEMAIL.BeforeLoadDFMValues;
  AllergieDataSet.BeforeLoadDFMValues;
  AllergieDataSetTEILNEHMERID.BeforeLoadDFMValues;
  AllergieDataSetALLERGENID.BeforeLoadDFMValues;
  VerpflegungsartSource.BeforeLoadDFMValues;
  VerpflegungsArtDataSet.BeforeLoadDFMValues;
  VerpflegungsArtDataSetID.BeforeLoadDFMValues;
  VerpflegungsArtDataSetNL_KEY.BeforeLoadDFMValues;
  VerpflegungsArtDataSetEID.BeforeLoadDFMValues;
  VerpflegungsArtDataSetLFDNO.BeforeLoadDFMValues;
  VerpflegungsArtDataSetBEZEICHNUNG.BeforeLoadDFMValues;
  VerpflegungsArtDataSetREIHENFOLGE.BeforeLoadDFMValues;
  VerpflegungsArtDataSetKUERZEL.BeforeLoadDFMValues;
  VariantenSource.BeforeLoadDFMValues;
  VariantenDataSet.BeforeLoadDFMValues;
  IntegerField1.BeforeLoadDFMValues;
  IntegerField2.BeforeLoadDFMValues;
  IntegerField3.BeforeLoadDFMValues;
  IntegerField4.BeforeLoadDFMValues;
  StringField1.BeforeLoadDFMValues;
  IntegerField5.BeforeLoadDFMValues;
  StringField2.BeforeLoadDFMValues;
  XDataWebClient2.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  SpeiseplanSource.BeforeLoadDFMValues;
  Speiseplandataset.BeforeLoadDFMValues;
  SpeiseplandatasetNL_KEY.BeforeLoadDFMValues;
  SpeiseplandatasetARTIKEL.BeforeLoadDFMValues;
  SpeiseplandatasetDATUM.BeforeLoadDFMValues;
  SpeiseplandatasetENO.BeforeLoadDFMValues;
  SpeiseplandatasetBESTELLT.BeforeLoadDFMValues;
  SpeiseplandatasetGELIEFERT.BeforeLoadDFMValues;
  SpeiseplandatasetBEWERTUNG1.BeforeLoadDFMValues;
  SpeiseplandatasetBEWERTUNG2.BeforeLoadDFMValues;
  SpeiseplandatasetBEWERTUNG3.BeforeLoadDFMValues;
  SpeiseplandatasetBEZEICHNUNG.BeforeLoadDFMValues;
  SpeiseplandatasetBEZEICHNUNG2.BeforeLoadDFMValues;
  try
    Name := 'DataModule1';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 420;
    Width := 834;
    XDataWebConnection1.SetParentComponent(Self);
    XDataWebConnection1.Name := 'XDataWebConnection1';
    XDataWebConnection1.URL := 'http://167.86.109.231:38709/KWSpeiseplan';
    XDataWebConnection1.Left := 48;
    XDataWebConnection1.Top := 24;
    KundenDataSet.SetParentComponent(Self);
    KundenDataSet.Name := 'KundenDataSet';
    KundenDataSet.EntitySetName := 'KUNDEN';
    KundenDataSet.Connection := XDataWebConnection1;
    KundenDataSet.Left := 272;
    KundenDataSet.Top := 24;
    KundenDataSetID.SetParentComponent(KundenDataSet);
    KundenDataSetID.Name := 'KundenDataSetID';
    KundenDataSetID.FieldName := 'ID';
    KundenDataSetID.Required := True;
    KundenDataSetNL_KEY.SetParentComponent(KundenDataSet);
    KundenDataSetNL_KEY.Name := 'KundenDataSetNL_KEY';
    KundenDataSetNL_KEY.FieldName := 'NL_KEY';
    KundenDataSetNL_KEY.Required := True;
    KundenDataSetKDNO.SetParentComponent(KundenDataSet);
    KundenDataSetKDNO.Name := 'KundenDataSetKDNO';
    KundenDataSetKDNO.FieldName := 'KDNO';
    KundenDataSetKDNO.Required := True;
    KundenDataSetNAME.SetParentComponent(KundenDataSet);
    KundenDataSetNAME.Name := 'KundenDataSetNAME';
    KundenDataSetNAME.FieldName := 'NAME';
    KundenDataSetNAME.Size := 40;
    KundenDataSetNAME2.SetParentComponent(KundenDataSet);
    KundenDataSetNAME2.Name := 'KundenDataSetNAME2';
    KundenDataSetNAME2.FieldName := 'NAME2';
    KundenDataSetNAME2.Size := 40;
    KundenDataSetSTRASSE.SetParentComponent(KundenDataSet);
    KundenDataSetSTRASSE.Name := 'KundenDataSetSTRASSE';
    KundenDataSetSTRASSE.FieldName := 'STRASSE';
    KundenDataSetSTRASSE.Size := 40;
    KundenDataSetPLZ.SetParentComponent(KundenDataSet);
    KundenDataSetPLZ.Name := 'KundenDataSetPLZ';
    KundenDataSetPLZ.FieldName := 'PLZ';
    KundenDataSetPLZ.Size := 10;
    KundenDataSetORT.SetParentComponent(KundenDataSet);
    KundenDataSetORT.Name := 'KundenDataSetORT';
    KundenDataSetORT.FieldName := 'ORT';
    KundenDataSetORT.Size := 40;
    KundenDataSetTELEFON.SetParentComponent(KundenDataSet);
    KundenDataSetTELEFON.Name := 'KundenDataSetTELEFON';
    KundenDataSetTELEFON.FieldName := 'TELEFON';
    KundenDataSetTELEFON.Size := 30;
    KundenDataSetHANDY.SetParentComponent(KundenDataSet);
    KundenDataSetHANDY.Name := 'KundenDataSetHANDY';
    KundenDataSetHANDY.FieldName := 'HANDY';
    KundenDataSetHANDY.Size := 30;
    KundenDataSetEMAILADR.SetParentComponent(KundenDataSet);
    KundenDataSetEMAILADR.Name := 'KundenDataSetEMAILADR';
    KundenDataSetEMAILADR.FieldName := 'EMAILADR';
    KundenDataSetEMAILADR.Size := 60;
    KundenDataSetKENNWORT.SetParentComponent(KundenDataSet);
    KundenDataSetKENNWORT.Name := 'KundenDataSetKENNWORT';
    KundenDataSetKENNWORT.FieldName := 'KENNWORT';
    KundenDataSetDATUHR.SetParentComponent(KundenDataSet);
    KundenDataSetDATUHR.Name := 'KundenDataSetDATUHR';
    KundenDataSetDATUHR.FieldName := 'DATUHR';
    KundenDataSetCONFIRMED.SetParentComponent(KundenDataSet);
    KundenDataSetCONFIRMED.Name := 'KundenDataSetCONFIRMED';
    KundenDataSetCONFIRMED.FieldName := 'CONFIRMED';
    KundenDataSetUPC_EMAIL.SetParentComponent(KundenDataSet);
    KundenDataSetUPC_EMAIL.Name := 'KundenDataSetUPC_EMAIL';
    KundenDataSetUPC_EMAIL.FieldName := 'UPC_EMAIL';
    KundenDataSetUPC_EMAIL.Size := 60;
    KundenDataSetDATUHR2.SetParentComponent(KundenDataSet);
    KundenDataSetDATUHR2.Name := 'KundenDataSetDATUHR2';
    KundenDataSetDATUHR2.FieldName := 'DATUHR2';
    KundenDataSetBESTAETIGUNG.SetParentComponent(KundenDataSet);
    KundenDataSetBESTAETIGUNG.Name := 'KundenDataSetBESTAETIGUNG';
    KundenDataSetBESTAETIGUNG.FieldName := 'BESTAETIGUNG';
    KundenDataSetBESTAETIGUNG.Size := 6;
    KundenDataSetAKTIV.SetParentComponent(KundenDataSet);
    KundenDataSetAKTIV.Name := 'KundenDataSetAKTIV';
    KundenDataSetAKTIV.FieldName := 'AKTIV';
    KundenDataSetAKTIV.Size := 1;
    KundenDataSetSTATUS.SetParentComponent(KundenDataSet);
    KundenDataSetSTATUS.Name := 'KundenDataSetSTATUS';
    KundenDataSetSTATUS.FieldName := 'STATUS';
    KundenDataSetSTATUS.Required := True;
    KundenDataSetANREDE.SetParentComponent(KundenDataSet);
    KundenDataSetANREDE.Name := 'KundenDataSetANREDE';
    KundenDataSetANREDE.FieldName := 'ANREDE';
    KundenDataSetAKTITEL.SetParentComponent(KundenDataSet);
    KundenDataSetAKTITEL.Name := 'KundenDataSetAKTITEL';
    KundenDataSetAKTITEL.FieldName := 'AKTITEL';
    KundenDataSetIBAN.SetParentComponent(KundenDataSet);
    KundenDataSetIBAN.Name := 'KundenDataSetIBAN';
    KundenDataSetIBAN.FieldName := 'IBAN';
    KundenDataSetIBAN.Size := 30;
    KundenDataSetBIC.SetParentComponent(KundenDataSet);
    KundenDataSetBIC.Name := 'KundenDataSetBIC';
    KundenDataSetBIC.FieldName := 'BIC';
    KundenDataSetBANK.SetParentComponent(KundenDataSet);
    KundenDataSetBANK.Name := 'KundenDataSetBANK';
    KundenDataSetBANK.FieldName := 'BANK';
    KundenDataSetBANK.Size := 40;
    KundenDataSetKZBERECHTIGUNG.SetParentComponent(KundenDataSet);
    KundenDataSetKZBERECHTIGUNG.Name := 'KundenDataSetKZBERECHTIGUNG';
    KundenDataSetKZBERECHTIGUNG.FieldName := 'KZBERECHTIGUNG';
    KundenDataSetADMINLEVEL.SetParentComponent(KundenDataSet);
    KundenDataSetADMINLEVEL.Name := 'KundenDataSetADMINLEVEL';
    KundenDataSetADMINLEVEL.FieldName := 'ADMINLEVEL';
    KundenDataSetADMINLEVEL.Required := True;
    KundenDataSetKDGRUPPE.SetParentComponent(KundenDataSet);
    KundenDataSetKDGRUPPE.Name := 'KundenDataSetKDGRUPPE';
    KundenDataSetKDGRUPPE.FieldName := 'KDGRUPPE';
    KundenDataSetKDGRUPPE.Required := True;
    KundenDataSetZAHLBED.SetParentComponent(KundenDataSet);
    KundenDataSetZAHLBED.Name := 'KundenDataSetZAHLBED';
    KundenDataSetZAHLBED.FieldName := 'ZAHLBED';
    KundenDataSetZAHLBED.Required := True;
    KundenDataSetABRECHNUNGSART.SetParentComponent(KundenDataSet);
    KundenDataSetABRECHNUNGSART.Name := 'KundenDataSetABRECHNUNGSART';
    KundenDataSetABRECHNUNGSART.FieldName := 'ABRECHNUNGSART';
    KundenDataSetABRECHNUNGSART.Required := True;
    KundenDataSetCHIPID.SetParentComponent(KundenDataSet);
    KundenDataSetCHIPID.Name := 'KundenDataSetCHIPID';
    KundenDataSetCHIPID.FieldName := 'CHIPID';
    KundenDataSetPREISLISTE.SetParentComponent(KundenDataSet);
    KundenDataSetPREISLISTE.Name := 'KundenDataSetPREISLISTE';
    KundenDataSetPREISLISTE.FieldName := 'PREISLISTE';
    KundenDataSetPREISLISTE.Required := True;
    KundenDataSetKONTOINHABER.SetParentComponent(KundenDataSet);
    KundenDataSetKONTOINHABER.Name := 'KundenDataSetKONTOINHABER';
    KundenDataSetKONTOINHABER.FieldName := 'KONTOINHABER';
    KundenDataSetKONTOINHABER.Size := 60;
    KundenDataSource.SetParentComponent(Self);
    KundenDataSource.Name := 'KundenDataSource';
    KundenDataSource.DataSet := KundenDataSet;
    KundenDataSource.Left := 176;
    KundenDataSource.Top := 24;
    XDataClient.SetParentComponent(Self);
    XDataClient.Name := 'XDataClient';
    XDataClient.Connection := XDataWebConnection1;
    XDataClient.Left := 47;
    XDataClient.Top := 81;
    TeilnehmerDataSource.SetParentComponent(Self);
    TeilnehmerDataSource.Name := 'TeilnehmerDataSource';
    TeilnehmerDataSource.DataSet := TeilnehmerDataSet;
    TeilnehmerDataSource.Left := 176;
    TeilnehmerDataSource.Top := 88;
    TeilnehmerDataSet.SetParentComponent(Self);
    TeilnehmerDataSet.Name := 'TeilnehmerDataSet';
    TeilnehmerDataSet.EntitySetName := 'TEILNEHMER';
    TeilnehmerDataSet.Connection := XDataWebConnection1;
    TeilnehmerDataSet.ServerRecordCountMode := smInlineCount;
    TeilnehmerDataSet.Left := 264;
    TeilnehmerDataSet.Top := 88;
    TeilnehmerDataSetID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetID.Name := 'TeilnehmerDataSetID';
    TeilnehmerDataSetID.FieldName := 'ID';
    TeilnehmerDataSetID.Required := True;
    TeilnehmerDataSetNL_KEY.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetNL_KEY.Name := 'TeilnehmerDataSetNL_KEY';
    TeilnehmerDataSetNL_KEY.FieldName := 'NL_KEY';
    TeilnehmerDataSetNL_KEY.Required := True;
    TeilnehmerDataSetKDID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetKDID.Name := 'TeilnehmerDataSetKDID';
    TeilnehmerDataSetKDID.FieldName := 'KDID';
    TeilnehmerDataSetKDID.Required := True;
    TeilnehmerDataSetLFDNO.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetLFDNO.Name := 'TeilnehmerDataSetLFDNO';
    TeilnehmerDataSetLFDNO.FieldName := 'LFDNO';
    TeilnehmerDataSetLFDNO.Required := True;
    TeilnehmerDataSetNAME.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetNAME.Name := 'TeilnehmerDataSetNAME';
    TeilnehmerDataSetNAME.FieldName := 'NAME';
    TeilnehmerDataSetNAME.Size := 60;
    TeilnehmerDataSetEINRICHTUNG.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetEINRICHTUNG.Name := 'TeilnehmerDataSetEINRICHTUNG';
    TeilnehmerDataSetEINRICHTUNG.FieldName := 'EINRICHTUNG';
    TeilnehmerDataSetGESCHLECHT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetGESCHLECHT.Name := 'TeilnehmerDataSetGESCHLECHT';
    TeilnehmerDataSetGESCHLECHT.FieldName := 'GESCHLECHT';
    TeilnehmerDataSetGEBURTSDATUM.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetGEBURTSDATUM.Name := 'TeilnehmerDataSetGEBURTSDATUM';
    TeilnehmerDataSetGEBURTSDATUM.FieldName := 'GEBURTSDATUM';
    TeilnehmerDataSetLKZ.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetLKZ.Name := 'TeilnehmerDataSetLKZ';
    TeilnehmerDataSetLKZ.FieldName := 'LKZ';
    TeilnehmerDataSetLKZ.Size := 1;
    TeilnehmerDataSetLOESCHUNG.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetLOESCHUNG.Name := 'TeilnehmerDataSetLOESCHUNG';
    TeilnehmerDataSetLOESCHUNG.FieldName := 'LOESCHUNG';
    TeilnehmerDataSetPLAN1.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN1.Name := 'TeilnehmerDataSetPLAN1';
    TeilnehmerDataSetPLAN1.FieldName := 'PLAN1';
    TeilnehmerDataSetPLAN2.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN2.Name := 'TeilnehmerDataSetPLAN2';
    TeilnehmerDataSetPLAN2.FieldName := 'PLAN2';
    TeilnehmerDataSetPLAN3.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN3.Name := 'TeilnehmerDataSetPLAN3';
    TeilnehmerDataSetPLAN3.FieldName := 'PLAN3';
    TeilnehmerDataSetPLAN4.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN4.Name := 'TeilnehmerDataSetPLAN4';
    TeilnehmerDataSetPLAN4.FieldName := 'PLAN4';
    TeilnehmerDataSetPLAN5.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN5.Name := 'TeilnehmerDataSetPLAN5';
    TeilnehmerDataSetPLAN5.FieldName := 'PLAN5';
    TeilnehmerDataSetPLAN6.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN6.Name := 'TeilnehmerDataSetPLAN6';
    TeilnehmerDataSetPLAN6.FieldName := 'PLAN6';
    TeilnehmerDataSetPLAN7.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN7.Name := 'TeilnehmerDataSetPLAN7';
    TeilnehmerDataSetPLAN7.FieldName := 'PLAN7';
    TeilnehmerDataSetEINRICHTUNGBEZ.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetEINRICHTUNGBEZ.Name := 'TeilnehmerDataSetEINRICHTUNGBEZ';
    TeilnehmerDataSetEINRICHTUNGBEZ.FieldName := 'EINRICHTUNGBEZ';
    TeilnehmerDataSetEINRICHTUNGBEZ.Size := 60;
    TeilnehmerDataSetTT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetTT.Name := 'TeilnehmerDataSetTT';
    TeilnehmerDataSetTT.FieldName := 'TT';
    TeilnehmerDataSetMM.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetMM.Name := 'TeilnehmerDataSetMM';
    TeilnehmerDataSetMM.FieldName := 'MM';
    TeilnehmerDataSetJJ.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetJJ.Name := 'TeilnehmerDataSetJJ';
    TeilnehmerDataSetJJ.FieldName := 'JJ';
    TeilnehmerDataSetKZ_BERECHTIGUNG.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetKZ_BERECHTIGUNG.Name := 'TeilnehmerDataSetKZ_BERECHTIGUNG';
    TeilnehmerDataSetKZ_BERECHTIGUNG.FieldName := 'KZ_BERECHTIGUNG';
    TeilnehmerDataSetVARIANTE.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetVARIANTE.Name := 'TeilnehmerDataSetVARIANTE';
    TeilnehmerDataSetVARIANTE.FieldName := 'VARIANTE';
    TeilnehmerDataSetVARIANTE.Required := True;
    TeilnehmerDataSetTID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetTID.Name := 'TeilnehmerDataSetTID';
    TeilnehmerDataSetTID.FieldName := 'TID';
    TeilnehmerDataSetTID.Required := True;
    TeilnehmerDataSetCHIPID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetCHIPID.Name := 'TeilnehmerDataSetCHIPID';
    TeilnehmerDataSetCHIPID.FieldName := 'CHIPID';
    TeilnehmerDataSetAKTIV.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetAKTIV.Name := 'TeilnehmerDataSetAKTIV';
    TeilnehmerDataSetAKTIV.FieldName := 'AKTIV';
    TeilnehmerDataSetAKTIV.Required := True;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Connection := XDataWebConnection1;
    XDataWebClient1.Left := 400;
    XDataWebClient1.Top := 24;
    EinrichtungSource.SetParentComponent(Self);
    EinrichtungSource.Name := 'EinrichtungSource';
    EinrichtungSource.DataSet := EinrichtungDataSet;
    EinrichtungSource.Left := 160;
    EinrichtungSource.Top := 152;
    EinrichtungDataSet.SetParentComponent(Self);
    EinrichtungDataSet.Name := 'EinrichtungDataSet';
    EinrichtungDataSet.EntitySetName := 'EINRICHTUNGEN';
    EinrichtungDataSet.Connection := XDataWebConnection1;
    EinrichtungDataSet.ServerRecordCountMode := smInlineCount;
    EinrichtungDataSet.Left := 264;
    EinrichtungDataSet.Top := 152;
    EinrichtungDataSetID.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetID.Name := 'EinrichtungDataSetID';
    EinrichtungDataSetID.FieldName := 'ID';
    EinrichtungDataSetID.Required := True;
    EinrichtungDataSetNL_KEY.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetNL_KEY.Name := 'EinrichtungDataSetNL_KEY';
    EinrichtungDataSetNL_KEY.FieldName := 'NL_KEY';
    EinrichtungDataSetNL_KEY.Required := True;
    EinrichtungDataSetNUMMER.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetNUMMER.Name := 'EinrichtungDataSetNUMMER';
    EinrichtungDataSetNUMMER.FieldName := 'NUMMER';
    EinrichtungDataSetNUMMER.Required := True;
    EinrichtungDataSetBEZEICHNUNG.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetBEZEICHNUNG.Name := 'EinrichtungDataSetBEZEICHNUNG';
    EinrichtungDataSetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    EinrichtungDataSetBEZEICHNUNG.Size := 60;
    EinrichtungDataSetEMAIL.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetEMAIL.Name := 'EinrichtungDataSetEMAIL';
    EinrichtungDataSetEMAIL.FieldName := 'EMAIL';
    EinrichtungDataSetEMAIL.Size := 100;
    AllergieDataSet.SetParentComponent(Self);
    AllergieDataSet.Name := 'AllergieDataSet';
    AllergieDataSet.EntitySetName := 'TEILNEHMER_ALLERGIEN';
    AllergieDataSet.Connection := XDataWebConnection1;
    AllergieDataSet.ServerRecordCountMode := smInlineCount;
    AllergieDataSet.Left := 368;
    AllergieDataSet.Top := 88;
    AllergieDataSetTEILNEHMERID.SetParentComponent(AllergieDataSet);
    AllergieDataSetTEILNEHMERID.Name := 'AllergieDataSetTEILNEHMERID';
    AllergieDataSetTEILNEHMERID.FieldName := 'TEILNEHMERID';
    AllergieDataSetTEILNEHMERID.Required := True;
    AllergieDataSetALLERGENID.SetParentComponent(AllergieDataSet);
    AllergieDataSetALLERGENID.Name := 'AllergieDataSetALLERGENID';
    AllergieDataSetALLERGENID.FieldName := 'ALLERGENID';
    AllergieDataSetALLERGENID.Required := True;
    VerpflegungsartSource.SetParentComponent(Self);
    VerpflegungsartSource.Name := 'VerpflegungsartSource';
    VerpflegungsartSource.DataSet := VerpflegungsArtDataSet;
    VerpflegungsartSource.Left := 160;
    VerpflegungsartSource.Top := 216;
    VerpflegungsArtDataSet.SetParentComponent(Self);
    VerpflegungsArtDataSet.Name := 'VerpflegungsArtDataSet';
    VerpflegungsArtDataSet.EntitySetName := 'EINRICHTUNGENVERPFLEGUNG';
    VerpflegungsArtDataSet.Connection := XDataWebConnection1;
    VerpflegungsArtDataSet.Left := 264;
    VerpflegungsArtDataSet.Top := 216;
    VerpflegungsArtDataSetID.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetID.Name := 'VerpflegungsArtDataSetID';
    VerpflegungsArtDataSetID.FieldName := 'ID';
    VerpflegungsArtDataSetID.Required := True;
    VerpflegungsArtDataSetNL_KEY.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetNL_KEY.Name := 'VerpflegungsArtDataSetNL_KEY';
    VerpflegungsArtDataSetNL_KEY.FieldName := 'NL_KEY';
    VerpflegungsArtDataSetNL_KEY.Required := True;
    VerpflegungsArtDataSetEID.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetEID.Name := 'VerpflegungsArtDataSetEID';
    VerpflegungsArtDataSetEID.FieldName := 'EID';
    VerpflegungsArtDataSetEID.Required := True;
    VerpflegungsArtDataSetLFDNO.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetLFDNO.Name := 'VerpflegungsArtDataSetLFDNO';
    VerpflegungsArtDataSetLFDNO.FieldName := 'LFDNO';
    VerpflegungsArtDataSetLFDNO.Required := True;
    VerpflegungsArtDataSetBEZEICHNUNG.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetBEZEICHNUNG.Name := 'VerpflegungsArtDataSetBEZEICHNUNG';
    VerpflegungsArtDataSetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    VerpflegungsArtDataSetBEZEICHNUNG.Size := 60;
    VerpflegungsArtDataSetREIHENFOLGE.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetREIHENFOLGE.Name := 'VerpflegungsArtDataSetREIHENFOLGE';
    VerpflegungsArtDataSetREIHENFOLGE.FieldName := 'REIHENFOLGE';
    VerpflegungsArtDataSetREIHENFOLGE.Required := True;
    VerpflegungsArtDataSetKUERZEL.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetKUERZEL.Name := 'VerpflegungsArtDataSetKUERZEL';
    VerpflegungsArtDataSetKUERZEL.FieldName := 'KUERZEL';
    VerpflegungsArtDataSetKUERZEL.Size := 30;
    VariantenSource.SetParentComponent(Self);
    VariantenSource.Name := 'VariantenSource';
    VariantenSource.DataSet := VariantenDataSet;
    VariantenSource.Left := 160;
    VariantenSource.Top := 272;
    VariantenDataSet.SetParentComponent(Self);
    VariantenDataSet.Name := 'VariantenDataSet';
    VariantenDataSet.EntitySetName := 'ESSENSVARIANTEN';
    VariantenDataSet.Connection := XDataWebConnection1;
    VariantenDataSet.Left := 264;
    VariantenDataSet.Top := 272;
    IntegerField1.SetParentComponent(VariantenDataSet);
    IntegerField1.Name := 'IntegerField1';
    IntegerField1.FieldName := 'ID';
    IntegerField1.Required := True;
    IntegerField2.SetParentComponent(VariantenDataSet);
    IntegerField2.Name := 'IntegerField2';
    IntegerField2.FieldName := 'NL_KEY';
    IntegerField2.Required := True;
    IntegerField3.SetParentComponent(VariantenDataSet);
    IntegerField3.Name := 'IntegerField3';
    IntegerField3.FieldName := 'EID';
    IntegerField3.Required := True;
    IntegerField4.SetParentComponent(VariantenDataSet);
    IntegerField4.Name := 'IntegerField4';
    IntegerField4.FieldName := 'LFDNO';
    IntegerField4.Required := True;
    StringField1.SetParentComponent(VariantenDataSet);
    StringField1.Name := 'StringField1';
    StringField1.FieldName := 'BEZEICHNUNG';
    StringField1.Size := 60;
    IntegerField5.SetParentComponent(VariantenDataSet);
    IntegerField5.Name := 'IntegerField5';
    IntegerField5.FieldName := 'REIHENFOLGE';
    IntegerField5.Required := True;
    StringField2.SetParentComponent(VariantenDataSet);
    StringField2.Name := 'StringField2';
    StringField2.FieldName := 'KUERZEL';
    StringField2.Size := 30;
    XDataWebClient2.SetParentComponent(Self);
    XDataWebClient2.Name := 'XDataWebClient2';
    XDataWebClient2.Connection := XDataWebConnection1;
    XDataWebClient2.Left := 47;
    XDataWebClient2.Top := 153;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Left := 536;
    WebClientConnection1.Top := 32;
    SpeiseplanSource.SetParentComponent(Self);
    SpeiseplanSource.Name := 'SpeiseplanSource';
    SpeiseplanSource.DataSet := Speiseplandataset;
    SpeiseplanSource.Left := 160;
    SpeiseplanSource.Top := 336;
    Speiseplandataset.SetParentComponent(Self);
    Speiseplandataset.Name := 'Speiseplandataset';
    Speiseplandataset.EntitySetName := 'SPEISEPLAN_TAG';
    Speiseplandataset.Connection := XDataWebConnection1;
    Speiseplandataset.ServerRecordCountMode := smInlineCount;
    Speiseplandataset.Left := 264;
    Speiseplandataset.Top := 336;
    SpeiseplandatasetNL_KEY.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetNL_KEY.Name := 'SpeiseplandatasetNL_KEY';
    SpeiseplandatasetNL_KEY.FieldName := 'NL_KEY';
    SpeiseplandatasetNL_KEY.Required := True;
    SpeiseplandatasetARTIKEL.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetARTIKEL.Name := 'SpeiseplandatasetARTIKEL';
    SpeiseplandatasetARTIKEL.FieldName := 'ARTIKEL';
    SpeiseplandatasetARTIKEL.Required := True;
    SpeiseplandatasetDATUM.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetDATUM.Name := 'SpeiseplandatasetDATUM';
    SpeiseplandatasetDATUM.FieldName := 'DATUM';
    SpeiseplandatasetDATUM.Required := True;
    SpeiseplandatasetENO.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetENO.Name := 'SpeiseplandatasetENO';
    SpeiseplandatasetENO.FieldName := 'ENO';
    SpeiseplandatasetENO.Required := True;
    SpeiseplandatasetBESTELLT.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBESTELLT.Name := 'SpeiseplandatasetBESTELLT';
    SpeiseplandatasetBESTELLT.FieldName := 'BESTELLT';
    SpeiseplandatasetGELIEFERT.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetGELIEFERT.Name := 'SpeiseplandatasetGELIEFERT';
    SpeiseplandatasetGELIEFERT.FieldName := 'GELIEFERT';
    SpeiseplandatasetBEWERTUNG1.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEWERTUNG1.Name := 'SpeiseplandatasetBEWERTUNG1';
    SpeiseplandatasetBEWERTUNG1.FieldName := 'BEWERTUNG1';
    SpeiseplandatasetBEWERTUNG2.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEWERTUNG2.Name := 'SpeiseplandatasetBEWERTUNG2';
    SpeiseplandatasetBEWERTUNG2.FieldName := 'BEWERTUNG2';
    SpeiseplandatasetBEWERTUNG3.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEWERTUNG3.Name := 'SpeiseplandatasetBEWERTUNG3';
    SpeiseplandatasetBEWERTUNG3.FieldName := 'BEWERTUNG3';
    SpeiseplandatasetBEZEICHNUNG.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEZEICHNUNG.Name := 'SpeiseplandatasetBEZEICHNUNG';
    SpeiseplandatasetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    SpeiseplandatasetBEZEICHNUNG.Size := 60;
    SpeiseplandatasetBEZEICHNUNG2.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEZEICHNUNG2.Name := 'SpeiseplandatasetBEZEICHNUNG2';
    SpeiseplandatasetBEZEICHNUNG2.FieldName := 'BEZEICHNUNG2';
    SpeiseplandatasetBEZEICHNUNG2.Size := 60;
  finally
    XDataWebConnection1.AfterLoadDFMValues;
    KundenDataSet.AfterLoadDFMValues;
    KundenDataSetID.AfterLoadDFMValues;
    KundenDataSetNL_KEY.AfterLoadDFMValues;
    KundenDataSetKDNO.AfterLoadDFMValues;
    KundenDataSetNAME.AfterLoadDFMValues;
    KundenDataSetNAME2.AfterLoadDFMValues;
    KundenDataSetSTRASSE.AfterLoadDFMValues;
    KundenDataSetPLZ.AfterLoadDFMValues;
    KundenDataSetORT.AfterLoadDFMValues;
    KundenDataSetTELEFON.AfterLoadDFMValues;
    KundenDataSetHANDY.AfterLoadDFMValues;
    KundenDataSetEMAILADR.AfterLoadDFMValues;
    KundenDataSetKENNWORT.AfterLoadDFMValues;
    KundenDataSetDATUHR.AfterLoadDFMValues;
    KundenDataSetCONFIRMED.AfterLoadDFMValues;
    KundenDataSetUPC_EMAIL.AfterLoadDFMValues;
    KundenDataSetDATUHR2.AfterLoadDFMValues;
    KundenDataSetBESTAETIGUNG.AfterLoadDFMValues;
    KundenDataSetAKTIV.AfterLoadDFMValues;
    KundenDataSetSTATUS.AfterLoadDFMValues;
    KundenDataSetANREDE.AfterLoadDFMValues;
    KundenDataSetAKTITEL.AfterLoadDFMValues;
    KundenDataSetIBAN.AfterLoadDFMValues;
    KundenDataSetBIC.AfterLoadDFMValues;
    KundenDataSetBANK.AfterLoadDFMValues;
    KundenDataSetKZBERECHTIGUNG.AfterLoadDFMValues;
    KundenDataSetADMINLEVEL.AfterLoadDFMValues;
    KundenDataSetKDGRUPPE.AfterLoadDFMValues;
    KundenDataSetZAHLBED.AfterLoadDFMValues;
    KundenDataSetABRECHNUNGSART.AfterLoadDFMValues;
    KundenDataSetCHIPID.AfterLoadDFMValues;
    KundenDataSetPREISLISTE.AfterLoadDFMValues;
    KundenDataSetKONTOINHABER.AfterLoadDFMValues;
    KundenDataSource.AfterLoadDFMValues;
    XDataClient.AfterLoadDFMValues;
    TeilnehmerDataSource.AfterLoadDFMValues;
    TeilnehmerDataSet.AfterLoadDFMValues;
    TeilnehmerDataSetID.AfterLoadDFMValues;
    TeilnehmerDataSetNL_KEY.AfterLoadDFMValues;
    TeilnehmerDataSetKDID.AfterLoadDFMValues;
    TeilnehmerDataSetLFDNO.AfterLoadDFMValues;
    TeilnehmerDataSetNAME.AfterLoadDFMValues;
    TeilnehmerDataSetEINRICHTUNG.AfterLoadDFMValues;
    TeilnehmerDataSetGESCHLECHT.AfterLoadDFMValues;
    TeilnehmerDataSetGEBURTSDATUM.AfterLoadDFMValues;
    TeilnehmerDataSetLKZ.AfterLoadDFMValues;
    TeilnehmerDataSetLOESCHUNG.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN1.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN2.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN3.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN4.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN5.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN6.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN7.AfterLoadDFMValues;
    TeilnehmerDataSetEINRICHTUNGBEZ.AfterLoadDFMValues;
    TeilnehmerDataSetTT.AfterLoadDFMValues;
    TeilnehmerDataSetMM.AfterLoadDFMValues;
    TeilnehmerDataSetJJ.AfterLoadDFMValues;
    TeilnehmerDataSetKZ_BERECHTIGUNG.AfterLoadDFMValues;
    TeilnehmerDataSetVARIANTE.AfterLoadDFMValues;
    TeilnehmerDataSetTID.AfterLoadDFMValues;
    TeilnehmerDataSetCHIPID.AfterLoadDFMValues;
    TeilnehmerDataSetAKTIV.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
    EinrichtungSource.AfterLoadDFMValues;
    EinrichtungDataSet.AfterLoadDFMValues;
    EinrichtungDataSetID.AfterLoadDFMValues;
    EinrichtungDataSetNL_KEY.AfterLoadDFMValues;
    EinrichtungDataSetNUMMER.AfterLoadDFMValues;
    EinrichtungDataSetBEZEICHNUNG.AfterLoadDFMValues;
    EinrichtungDataSetEMAIL.AfterLoadDFMValues;
    AllergieDataSet.AfterLoadDFMValues;
    AllergieDataSetTEILNEHMERID.AfterLoadDFMValues;
    AllergieDataSetALLERGENID.AfterLoadDFMValues;
    VerpflegungsartSource.AfterLoadDFMValues;
    VerpflegungsArtDataSet.AfterLoadDFMValues;
    VerpflegungsArtDataSetID.AfterLoadDFMValues;
    VerpflegungsArtDataSetNL_KEY.AfterLoadDFMValues;
    VerpflegungsArtDataSetEID.AfterLoadDFMValues;
    VerpflegungsArtDataSetLFDNO.AfterLoadDFMValues;
    VerpflegungsArtDataSetBEZEICHNUNG.AfterLoadDFMValues;
    VerpflegungsArtDataSetREIHENFOLGE.AfterLoadDFMValues;
    VerpflegungsArtDataSetKUERZEL.AfterLoadDFMValues;
    VariantenSource.AfterLoadDFMValues;
    VariantenDataSet.AfterLoadDFMValues;
    IntegerField1.AfterLoadDFMValues;
    IntegerField2.AfterLoadDFMValues;
    IntegerField3.AfterLoadDFMValues;
    IntegerField4.AfterLoadDFMValues;
    StringField1.AfterLoadDFMValues;
    IntegerField5.AfterLoadDFMValues;
    StringField2.AfterLoadDFMValues;
    XDataWebClient2.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
    SpeiseplanSource.AfterLoadDFMValues;
    Speiseplandataset.AfterLoadDFMValues;
    SpeiseplandatasetNL_KEY.AfterLoadDFMValues;
    SpeiseplandatasetARTIKEL.AfterLoadDFMValues;
    SpeiseplandatasetDATUM.AfterLoadDFMValues;
    SpeiseplandatasetENO.AfterLoadDFMValues;
    SpeiseplandatasetBESTELLT.AfterLoadDFMValues;
    SpeiseplandatasetGELIEFERT.AfterLoadDFMValues;
    SpeiseplandatasetBEWERTUNG1.AfterLoadDFMValues;
    SpeiseplandatasetBEWERTUNG2.AfterLoadDFMValues;
    SpeiseplandatasetBEWERTUNG3.AfterLoadDFMValues;
    SpeiseplandatasetBEZEICHNUNG.AfterLoadDFMValues;
    SpeiseplandatasetBEZEICHNUNG2.AfterLoadDFMValues;
  end;
end;

end.
