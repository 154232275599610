program PSpeiseplan_Client;

uses
  WEBLib.Forms,
  ULogin in 'ULogin.pas'{*.html},
  Udatamodule in 'Udatamodule.pas',
  UNeuesKonto in 'UNeuesKonto.pas'{*.html},
  UHMenu in 'UHMenu.pas'{*.html},
  UAnzSpeiseplan in 'UAnzSpeiseplan.pas'{*.html},
  UGlobvar in 'UGlobvar.pas'{*.html},
  UGetAuswahl in 'UGetAuswahl.pas'{*.html},
  UResetPassword in 'UResetPassword.pas'{*.html},
  UEditKonto4 in 'UEditKonto4.pas'{*.html},
  UKontoMenu in 'UKontoMenu.pas'{*.html},
  UEditBank in 'UEditBank.pas'{*.html},
  UEditPasswort in 'UEditPasswort.pas'{*.html},
  UChangeEmail in 'UChangeEmail.pas'{*.html},
  UConfirmEmail in 'UConfirmEmail.pas'{*.html},
  UTeilnehmer in 'UTeilnehmer.pas'{*.html},
  UEditTeilnehmer in 'UEditTeilnehmer.pas'{*.html},
  UVWAllergien in 'UVWAllergien.pas'{*.html},
  UWochenplan in 'UWochenplan.pas'{*.html},
  UHMenu2 in 'UHMenu2.pas'{*.html},
  UVW_Einrichtungen in 'UVW_Einrichtungen.pas'{*.html},
  UEditEinrichtung in 'UEditEinrichtung.pas'{*.html},
  UStd_Edit in 'UStd_Edit.pas'{*.html},
  UStd_EditForm in 'UStd_EditForm.pas'{*.html},
  USTD_Suche in 'USTD_Suche.pas'{*.html},
  UAnzSpeiseplanTag2 in 'UAnzSpeiseplanTag2.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TLoginForm, LoginForm);
  Application.CreateForm(TDataModule1, DataModule1);
  Application.CreateForm(TNeuesKonto, NeuesKonto);
  Application.CreateForm(THMenu2, HMenu2);
  Application.CreateForm(TEditEinrichtung, EditEinrichtung);
  Application.CreateForm(TSTD_Edit, STD_Edit);
  Application.CreateForm(TStd_Editform, Std_Editform);
  Application.CreateForm(TSTD_Suche, STD_Suche);
  Application.CreateForm(TAnzSpeiseplanTag2, AnzSpeiseplanTag2);
  //  Application.CreateForm(TVW_Einrichtungen, VW_Einrichtungen);
  //  Application.CreateForm(TMain_Form, Main_Form);
  Application.CreateForm(THMenu, HMenu);
  Application.CreateForm(TAnzSpeiseplan, AnzSpeiseplan);
  Application.CreateForm(TGlobvar, Globvar);
  Application.CreateForm(TKontoMenu, KontoMenu);
  Application.CreateForm(TEditBank, EditBank);
  Application.CreateForm(TEditPasswort, EditPasswort);
  Application.CreateForm(TChangeEmail, ChangeEmail);
  Application.CreateForm(TConfirmEmail, ConfirmEmail);
  Application.CreateForm(TEditTeilnehmer, EditTeilnehmer);
  Application.CreateForm(TVWAllergien, VWAllergien);
  Application.CreateForm(TWochenplan, Wochenplan);
  //  Application.CreateForm(TTeilnehmer, Teilnehmer);
  //  Application.CreateForm(TEditKonto3, EditKonto3);
  Application.CreateForm(TResetPassword, ResetPassword);
  Application.CreateForm(TGetAuswahl, GetAuswahl);
  Application.CreateForm(TGetAuswahl, GetAuswahl);
  Application.CreateForm(TResetPassword, ResetPassword);
//  Application.CreateForm(TEditKonto3, EditKonto3);
//  Application.CreateForm(TEditKonto4, EditKonto4);
  Application.Run;
end.
