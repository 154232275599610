unit UHMenu2;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  DB,

  UDatamodule,
  UGlobvar,
  UVW_Einrichtungen,
  UEditEinrichtung,
  UStd_Edit;

type
  THMenu2 = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebButton5: TButton;
    WebButton1: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton2: TButton;
    WebButton4: TButton;
    WebButton3: TButton;
    WebButton8: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure Zeige_Einrichtungen(DataSet: TDataSet);
    procedure WebButton8Click(Sender: TObject);
//    procedure Edit_Einrichtungen(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  HMenu2: THMenu2;

implementation


procedure THMenu2.WebButton1Click(Sender: TObject);
var Newform: TVW_Einrichtungen;
    i : integer;


begin

//  Datamodule1.EinrichtungDataSet.Close;
//  Datamodule1.EinrichtungDataSet.AfterOpen := Zeige_Einrichtungen;
//  if Lade_Daten_2(Datamodule1.EinrichtungDataSet,'EINRICHTUNGEN','$filter=NL_Key eq '+IntToStr(AktFirmaNo)+'') then begin
//  end;


begin




//  i := Datamodule1.TeilnehmerDataSet.ServerRecordCount;
    Newform := TVW_Einrichtungen.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TVW_Einrichtungen).Caption := 'Einrichtungen';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );
  end;


end;

procedure THMenu2.WebButton6Click(Sender: TObject);
begin
  Close;
end;


{

procedure THMenu2.Edit_Einrichtungen(Sender: TObject);
var
  newform: TEditEinrichtung;

begin
  Einrichtung_Insert_Mode := false;
  Datamodule1.EinrichtungDataSet.Edit;
  newform := TEditEinrichtung.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditEinrichtung).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;

}

procedure THMenu2.WebButton8Click(Sender: TObject);
begin
  Standard_Edit_Form(Datamodule1.EinrichtungDataSet,'EINRICHTUNGEN','$filter=(NL_Key eq '+IntToStr(AktFirmaNo) + ')',
    'Nummer;Nummer;100;Bezeichnung;Bezeichnung;200;Email;Email;250;',
    'Nummer;Nummer;100;I;F;T;Bezeichnung;Bezeichnung;200;S;F;T;Email;Email;250;S;F;F;');

end;

procedure THMenu2.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;

end;


procedure THMenu2.Zeige_Einrichtungen(DataSet: TDataSet);
var Newform: TVW_Einrichtungen;
    i : integer;
begin
//  i := Datamodule1.TeilnehmerDataSet.ServerRecordCount;
    Newform := TVW_Einrichtungen.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TVW_Einrichtungen).Caption := 'Einrichtungen';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );
end;


procedure THMenu2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  try
    Name := 'HMenu2';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 65;
    Weblabel301.Height := 18;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton5.SetParentComponent(WebPanel1);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 496;
    WebButton5.Top := 48;
    WebButton5.Width := 96;
    WebButton5.Height := 25;
    WebButton5.Caption := 'WebButton5';
    WebButton5.ChildOrder := 4;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 110;
    WebButton1.Width := 273;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Einrichtungen';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn-secondary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 24;
    WebButton6.Top := 361;
    WebButton6.Width := 273;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Programm beenden';
    WebButton6.ChildOrder := 5;
    WebButton6.ElementClassName := 'btn-secondary';
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 24;
    WebButton7.Top := 305;
    WebButton7.Width := 273;
    WebButton7.Height := 33;
    WebButton7.Caption := 'email-Adresse best'#228'tigen';
    WebButton7.ChildOrder := 4;
    WebButton7.ElementClassName := 'btn-secondary';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 266;
    WebButton2.Width := 273;
    WebButton2.Height := 33;
    WebButton2.Caption := 'Kennwort '#228'ndern';
    WebButton2.ChildOrder := 4;
    WebButton2.ElementClassName := 'btn-secondary';
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 24;
    WebButton4.Top := 227;
    WebButton4.Width := 273;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Konto bearbeiten';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn-secondary';
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 24;
    WebButton3.Top := 188;
    WebButton3.Width := 273;
    WebButton3.Height := 33;
    WebButton3.Caption := 'Essen bestellen';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn-secondary';
    WebButton8.SetParentComponent(Self);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 24;
    WebButton8.Top := 149;
    WebButton8.Width := 273;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Essen abbestellen';
    WebButton8.ChildOrder := 1;
    WebButton8.ElementClassName := 'btn-secondary';
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
  end;
end;

end.