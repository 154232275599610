unit UKontoMenu;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,

  UDatamodule,
  UGlobvar,
  UEditKonto4,
  UEditBank,
  UEditPasswort,
  UChangeEMail,
  UTeilnehmer;

type
  TKontoMenu = class(TForm)
    WebLabel1: TLabel;
    Benutzer: TLabel;
    WebEdit1: TEdit;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure Zeige_Teilnehmer(DataSet: TDataSet);
    procedure Enable_Teilnehmer(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  KontoMenu: TKontoMenu;

implementation


procedure TKontoMenu.WebButton1Click(Sender: TObject);
var
  newform: TEditKonto4;
begin
  newform := TEditKonto4.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditKonto4).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );
end;

procedure TKontoMenu.WebButton2Click(Sender: TObject);
var
  newform: TEditBank;
begin
  newform := TEditBank.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditBank).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;


procedure TKontoMenu.Zeige_Teilnehmer(DataSet: TDataSet);
var Newform: TTeilnehmer;
    i : integer;
begin
//  i := Datamodule1.TeilnehmerDataSet.ServerRecordCount;
    Newform := TTeilnehmer.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TTeilnehmer).Caption := 'Teilnehmer';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );
end;



procedure TKontoMenu.WebButton3Click(Sender: TObject);
var Newform: TTeilnehmer;
    i : integer;
begin
//  i := Datamodule1.TeilnehmerDataSet.ServerRecordCount;
    Newform := TTeilnehmer.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TTeilnehmer).Caption := 'Teilnehmer';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );
end;

procedure TKontoMenu.WebButton4Click(Sender: TObject);
var
  newform: TChangeEmail;
begin
  newform := TChangeEmail.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TChangeEmail).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TKontoMenu.WebButton5Click(Sender: TObject);
var
  newform: TEditPasswort;
begin
  newform := TEditPasswort.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditPasswort).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TKontoMenu.WebButton7Click(Sender: TObject);
begin
  Close;
end;

procedure TKontoMenu.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
//  WebButton3.Visible := false;


{
  KundenDataSet_Mode := KDDataset_FindKey;
  Datamodule1.XDataWebConnection1.Connected := true;
  DataModule1.TeilnehmerDataset.Active := false;
  DataModule1.TeilnehmerDataset.AfterOpen := Enable_Teilnehmer;
  DataModule1.TeilnehmerDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (KDID eq '
    + DataModule1.KundenDataset.FieldByName('ID').AsString+')';
  DataModule1.TeilnehmerDataset.Load;
}
end;


procedure TKontoMenu.Enable_Teilnehmer(DataSet: TDataSet);
begin
//  WebButton3.Visible := true;
end;



procedure TKontoMenu.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  Benutzer := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  Benutzer.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  try
    Name := 'KontoMenu';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Benutzer.SetParentComponent(Self);
    Benutzer.Name := 'Benutzer';
    Benutzer.Left := 24;
    Benutzer.Top := 53;
    Benutzer.Width := 44;
    Benutzer.Height := 14;
    Benutzer.Caption := 'Benutzer';
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 88;
    WebEdit1.Top := 50;
    WebEdit1.Width := 209;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 86;
    WebButton1.Width := 273;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Adresse/Telefon erfassen/'#228'ndern';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-secondary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 125;
    WebButton2.Width := 273;
    WebButton2.Height := 33;
    WebButton2.Caption := 'Bankverbindung erfassen/'#228'ndern';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn-secondary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 24;
    WebButton3.Top := 164;
    WebButton3.Width := 273;
    WebButton3.Height := 33;
    WebButton3.Caption := 'Essensteilnehmer/Kinder erfassen/'#228'ndern';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn-secondary';
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 24;
    WebButton4.Top := 203;
    WebButton4.Width := 273;
    WebButton4.Height := 33;
    WebButton4.Caption := 'eMail-Adresse '#228'ndern';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn-secondary';
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 24;
    WebButton5.Top := 242;
    WebButton5.Width := 273;
    WebButton5.Height := 33;
    WebButton5.Caption := 'Kennwort '#228'ndern';
    WebButton5.ChildOrder := 4;
    WebButton5.ElementClassName := 'btn-secondary';
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 24;
    WebButton6.Top := 362;
    WebButton6.Width := 273;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Konto l'#246'schen';
    WebButton6.ChildOrder := 5;
    WebButton6.ElementClassName := 'btn-secondary';
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 24;
    WebButton7.Top := 401;
    WebButton7.Width := 273;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Zur'#252'ck';
    WebButton7.ChildOrder := 5;
    WebButton7.ElementClassName := 'btn-primary';
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton8.SetParentComponent(Self);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 24;
    WebButton8.Top := 281;
    WebButton8.Width := 273;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Historie';
    WebButton8.ChildOrder := 5;
    WebButton8.ElementClassName := 'btn-secondary';
    WebButton9.SetParentComponent(Self);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 24;
    WebButton9.Top := 320;
    WebButton9.Width := 273;
    WebButton9.Height := 33;
    WebButton9.Caption := 'Nachrichten';
    WebButton9.ChildOrder := 5;
    WebButton9.ElementClassName := 'btn-secondary';
  finally
    WebLabel1.AfterLoadDFMValues;
    Benutzer.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
  end;
end;

end.