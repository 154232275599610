unit UGlobvar;

interface

uses
  SysUtils, Classes,  WEBLib.StdCtrls, WEBLib.Controls,JS, Web, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs;

type
  TGlobvar = class(TForm)
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;


const
      maxAllergene = 14;

      Action_New = 1;
      Action_Edit = 2;
      Action_Delete = 3;
      Action_First = 4;
      Action_Last = 5;
      Action_Next = 6;
      Action_Prior = 7;
      Action_PgUp = 8;
      Action_PgDn = 9;
      Action_Scan = 10;
      Action_Home = 11;
      Action_Copy = 12;
      Action_Close = 13;
      Action_Cancel = 14;
      Action_Save = 15;
      Action_Print = 16;
      Action_Filter = 17;
      Action_Help = 18;
      Action_Tools = 19;
      Action_Menu = 20;
      Action_Refresh = 21;

      Action_Detail_Plus = 22;
      Action_Detail_Minus = 23;
      Action_Detail_Search = 24;
      Action_Detail_OK = 25;
      Action_Detail_Cancel = 26;
      Action_Search = 27;
      Action_SelectAll = 28;
      Action_UnSelectAll = 29;
      Action_DeleteAll = 30;
      Action_Detail_Menu = 31;
      Action_Einfuegen_Vor = 32;
      Action_Einfuegen_Nach = 33;
      Action_Anhaengen = 34;
      Action_Ersetzen = 35;
      Action_Kein_Bild = 36;
      Action_Ganz_Hoch = 37;
      Action_Hoch = 38;
      Action_Runter = 39;
      Action_Ganz_Runter = 40;
      Action_Verlauf = 41;
      Action_Add_Detail_Plus = 42;
      Action_Add_Detail_Minus = 43;
      Action_Add_Detail_Edit = 44;
      Action_Kalender = 45;
      Action_JahrMinus = 46;
      Action_JahrPlus = 47;
      Action_MonatPlus = 48;
      Action_MonatMinus = 49;
      Action_WocheMinus = 50;
      Action_WochePlus = 51;
      Action_KalenderEdit = 52;
      Action_KalenderLoeschen = 53;
      Action_Publish = 54;



var
  Globvar: TGlobvar;

  AktPrgName,
  AktFirmaName,
  Akt_JWToken,
  AktKDName : String;
  AktFirmaNo,
  AktKDID,
  AktTeilnehmerID,
  AktEinrichtungID : integer;
  AktTeilnehmer : integer;
  AktTeilnehmerName : String;
  AktEinrichtung : integer;
  AktEinrichtungName : String;

  AllergenBez : array[1 .. maxAllergene] of String;
  AllergenID : array[1 .. maxAllergene] of integer;


procedure Init_Globvar;


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);


procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);


implementation


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;

 procedure DialogProc(AValue: TModalResult);
 begin
   Meldungs_Fenster := AValue;
 end;

begin
  case Art of
    mtWarning : MessageDlg(msgtxt, mtWarning, [], @DialogProc);
    mtError : MessageDlg(msgtxt, mtError, [], @DialogProc);
    mtInformation : MessageDlg(msgtxt, mtInformation, [], @DialogProc);
    mtConfirmation: MessageDlg(msgtxt, mtConfirmation, [mbYes, mbNo, mbCancel], @DialogProc);
    mtCustom : MessageDlg(msgtxt, mtCustom, [mbAbort, mbRetry, mbClose], @DialogProc);
  end;
end;




procedure Init_Globvar;
begin
  AktFirmaNo := 10;

  AllergenBez[1] := 'Glutenhaltiges Getreide';
  AllergenBez[2] := 'Krebstiere und daraus gewonnene Erzeugnisse';
  AllergenBez[3] := 'Eier und daraus gewonnene Erzeugnisse';
  AllergenBez[4] := 'Fische und daraus gewonnene Erzeugnisse';
  AllergenBez[5] := 'Erdnüsse und daraus gewonnene Erzeugnisse';
  AllergenBez[6] := 'Sojabohnen und daraus gewonnene Erzeugnisse';
  AllergenBez[7] := 'Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)';
  AllergenBez[8] := 'Schalenfrüchte';
  AllergenBez[9] := 'Sellerie und daraus gewonnene Erzeugnisse';
  AllergenBez[10] := 'Senf und daraus gewonnene Erzeugnisse';
  AllergenBez[11] := 'Sesamsamen und daraus gewonnene Erzeugnisse';
  AllergenBez[12] := 'Schwefeldioxid und Sulfite';
  AllergenBez[13] := 'Lupinen und daraus gewonnene Erzeugnisse';
  AllergenBez[14] := 'Weichtiere und daraus gewonnene Erzeugnisse';
  AllergenID[1] := 1;
  AllergenID[2] := 2;
  AllergenID[3] := 3;
  AllergenID[4] := 4;
  AllergenID[5] := 5;
  AllergenID[6] := 6;
  AllergenID[7] := 7;
  AllergenID[8] := 8;
  AllergenID[9] := 9;
  AllergenID[10] := 10;
  AllergenID[11] := 11;
  AllergenID[12] := 12;
  AllergenID[13] := 13;
  AllergenID[14] := 14;

end;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);
end;

procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);

  i := pos(';',AString);
  DT := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  RO := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  PF := Copy(AString,1,i-1);
  system.Delete(AString,1,i);


end;



procedure TGlobvar.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'Globvar';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
  finally
  end;
end;

end.