unit UNeuesKonto;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, XData.Web.Client,
  UGlobvar,
  UDataModule;

type
  TNeuesKonto = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebLabel3: TLabel;
    WebComboBox1: TComboBox;
    WebLabel4: TLabel;
    WebEdit3: TEdit;
    WebLabel5: TLabel;
    WebEdit4: TEdit;
    WebLabel6: TLabel;
    WebEdit5: TEdit;
    WebButton1: TButton;
    WebButton2: TButton;
    WebLabel7: TLabel;
    WebEdit6: TEdit;
    WebLabel8: TLabel;
    WebEdit7: TEdit;
    WebLabel9: TLabel;
    WebEdit8: TEdit;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    WebEdit9: TEdit;
    WebLabel12: TLabel;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    WebLabel15: TLabel;
    WebLabel16: TLabel;
    WebLabel17: TLabel;
    WebLabel18: TLabel;
    WebLabel19: TLabel;
    WebLabel20: TLabel;
    WebLabel21: TLabel;
    WebEdit10: TEdit;
    WebLabel22: TLabel;
    WebLabel23: TLabel;
    WebEdit11: TEdit;
    WebLabel24: TLabel;
    WebLabel25: TLabel;
    WebLabel26: TLabel;
    WebLabel27: TLabel;
    WebEdit12: TEdit;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  NeuesKonto: TNeuesKonto;



implementation




procedure TNeuesKonto.WebButton1Click(Sender: TObject);
begin
//   MessageDlg('Anrede muß erfasst werden', mtError, [], nil);

  Close;
end;

procedure TNeuesKonto.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  if WebCombobox1.ItemIndex = -1 then begin
    MessageDlg('Anrede muß erfasst werden', mtError, [], nil);
    WebCombobox1.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Name muß erfasst werden', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;
  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Vorname muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit3.Text);
  WebEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Strasse muß erfasst werden', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit4.Text);
  WebEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('PLZ muß erfasst werden', mtError, [], nil);
    WebEdit4.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit5.Text);
  WebEdit5.Text := hst;
  if hst = '' then begin
    MessageDlg('Ort muß erfasst werden', mtError, [], nil);
    WebEdit5.SetFocus;
    exit;
  end;


  hst := Trim(WebEdit7.Text);
  WebEdit7.Text := hst;
  if hst = '' then begin
    MessageDlg('Email muß erfasst werden', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;

{  if pos('@',hst) < 0 then begin
    MessageDlg('Dies kann keine gültige Email-Adresse sein', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;
}

  hst := Trim(WebEdit8.Text);
  WebEdit8.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung Email muß erfasst werden', mtError, [], nil);
    WebEdit8.SetFocus;
    exit;
  end;

  if WebEdit8.Text <> WebEdit7.Text then begin
    MessageDlg('Email und Wiederholung Email stimmen nicht überein', mtError, [], nil);
    WebEdit8.SetFocus;
    exit;
  end;



  hst := Trim(WebEdit10.Text);
  WebEdit10.Text := hst;
  if hst = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Leerzeichen im Kennwort nicht erlaubt', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;

  if length(hst) < 6 then begin
    MessageDlg('Kennwort muß mindestens 6 Zeichen lang sein', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;


  hst := Trim(WebEdit11.Text);
  WebEdit11.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit11.SetFocus;
    exit;
  end;

  if WebEdit11.Text <> WebEdit10.Text then begin
    MessageDlg('Kennwort und Wiederholung Kennwort stimmen nicht überein', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;
  end;

{FROM STD_INSERT_KUNDEN ('P_ID', 'P_NL_KEY', 'P_KDNO', 'P_NAME', 'P_NAME2',
    'P_STRASSE', 'P_PLZ', 'P_ORT', 'P_TELEFON', 'P_HANDY', 'P_EMAILADR', 'P_KENNWORT',

     'P_DATUHR', 'P_CONFIRMED', 'P_UPC_EMAIL', 'P_DATUHR2', 'P_BESTAETIGUNG',
     'P_AKTIV', 'P_STATUS', 'P_ANREDE', 'P_AKTITEL', 'P_IBAN', 'P_BIC',
     'P_BANK', 'P_KZBERECHTIGUNG') p;

}

  hst2 := 'Select p.NEWID FROM STD_INSERT_KUNDEN (0,'+IntToStr(AktFirmaNo)+',0,'+AFZ(WebEdit1.Text)
    +AFZ(WebEdit2.Text)
    +AFZ(WebEdit3.Text)
    +AFZ(WebEdit4.Text)
    +AFZ(WebEdit5.Text)
    +AFZ(WebEdit6.Text)
    +AFZ(WebEdit12.Text)
    +AFZ(WebEdit7.Text)
    +AFZ(WebEdit10.Text)
    +AFZ_Now('')
    +'NULL,'
    +AFZ(Uppercase(WebEdit7.Text))
    +'NULL,'
    +'NULL,'
    +AFZ('T')
    +'0,'
    +AFZ(WebCombobox1.Text)
    +AFZ(WebEdit9.Text)
    +'NULL,'
    +'NULL,'
    +'NULL,'
    +AFZ2('0')
    +') p';


  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Kundenkoto mit dieser eMail-Adresse bereits vorhanden', mtError, [], nil);
        end
        else begin
          MessageDlg('Kundenkonto Nr. '+hst+' erstellt. Bitte prüfen Sie Ihr email-Postfach ggf. auch den Spam-Ordner', mtInformation, [], nil);
          Close;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;

//      window.localStorage.setItem(cStorageTokenName, Token);
//      CheckLogin;
    end
  );



end;

procedure TNeuesKonto.WebFormCreate(Sender: TObject);
begin
  WebLabel26.Caption := AktFirmaName;
end;

procedure TNeuesKonto.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebLabel27 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebEdit6 := TEdit.Create(Self);
  WebEdit7 := TEdit.Create(Self);
  WebEdit8 := TEdit.Create(Self);
  WebEdit9 := TEdit.Create(Self);
  WebEdit10 := TEdit.Create(Self);
  WebEdit11 := TEdit.Create(Self);
  WebEdit12 := TEdit.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebLabel27.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebEdit7.BeforeLoadDFMValues;
  WebEdit8.BeforeLoadDFMValues;
  WebEdit9.BeforeLoadDFMValues;
  WebEdit10.BeforeLoadDFMValues;
  WebEdit11.BeforeLoadDFMValues;
  WebEdit12.BeforeLoadDFMValues;
  try
    Name := 'NeuesKonto';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 6;
    WebLabel1.Top := 82;
    WebLabel1.Width := 42;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Name';
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 6;
    WebLabel2.Top := 113;
    WebLabel2.Width := 63;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Vorname';
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 6;
    WebLabel3.Top := 52;
    WebLabel3.Width := 51;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Anrede';
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 6;
    WebLabel4.Top := 144;
    WebLabel4.Width := 54;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Strasse';
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 6;
    WebLabel5.Top := 175;
    WebLabel5.Width := 29;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'PLZ';
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 6;
    WebLabel6.Top := 205;
    WebLabel6.Width := 21;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'Ort';
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 6;
    WebLabel7.Top := 236;
    WebLabel7.Width := 49;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Telefon';
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 6;
    WebLabel8.Top := 298;
    WebLabel8.Width := 38;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'eMail';
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 6;
    WebLabel9.Top := 325;
    WebLabel9.Width := 96;
    WebLabel9.Height := 18;
    WebLabel9.Caption := 'Wiederholung';
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 6;
    WebLabel10.Top := 344;
    WebLabel10.Width := 38;
    WebLabel10.Height := 18;
    WebLabel10.Caption := 'eMail';
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 230;
    WebLabel11.Top := 54;
    WebLabel11.Width := 28;
    WebLabel11.Height := 18;
    WebLabel11.Caption := 'Titel';
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 200;
    WebLabel12.Top := 56;
    WebLabel12.Width := 11;
    WebLabel12.Height := 18;
    WebLabel12.Caption := '*)';
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 367;
    WebLabel13.Top := 88;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel14.SetParentComponent(Self);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 367;
    WebLabel14.Top := 113;
    WebLabel14.Width := 11;
    WebLabel14.Height := 18;
    WebLabel14.Caption := '*)';
    WebLabel15.SetParentComponent(Self);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 367;
    WebLabel15.Top := 144;
    WebLabel15.Width := 11;
    WebLabel15.Height := 18;
    WebLabel15.Caption := '*)';
    WebLabel16.SetParentComponent(Self);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 367;
    WebLabel16.Top := 173;
    WebLabel16.Width := 11;
    WebLabel16.Height := 18;
    WebLabel16.Caption := '*)';
    WebLabel17.SetParentComponent(Self);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 367;
    WebLabel17.Top := 205;
    WebLabel17.Width := 11;
    WebLabel17.Height := 18;
    WebLabel17.Caption := '*)';
    WebLabel18.SetParentComponent(Self);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 367;
    WebLabel18.Top := 294;
    WebLabel18.Width := 11;
    WebLabel18.Height := 18;
    WebLabel18.Caption := '*)';
    WebLabel19.SetParentComponent(Self);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 367;
    WebLabel19.Top := 332;
    WebLabel19.Width := 11;
    WebLabel19.Height := 18;
    WebLabel19.Caption := '*)';
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 112;
    WebLabel20.Top := 432;
    WebLabel20.Width := 95;
    WebLabel20.Height := 18;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel21.SetParentComponent(Self);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 6;
    WebLabel21.Top := 370;
    WebLabel21.Width := 65;
    WebLabel21.Height := 18;
    WebLabel21.Caption := 'Kennwort';
    WebLabel22.SetParentComponent(Self);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 6;
    WebLabel22.Top := 401;
    WebLabel22.Width := 96;
    WebLabel22.Height := 18;
    WebLabel22.Caption := 'Wiederholung';
    WebLabel23.SetParentComponent(Self);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 6;
    WebLabel23.Top := 420;
    WebLabel23.Width := 65;
    WebLabel23.Height := 18;
    WebLabel23.Caption := 'Kennwort';
    WebLabel24.SetParentComponent(Self);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 367;
    WebLabel24.Top := 370;
    WebLabel24.Width := 11;
    WebLabel24.Height := 18;
    WebLabel24.Caption := '*)';
    WebLabel25.SetParentComponent(Self);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 367;
    WebLabel25.Top := 407;
    WebLabel25.Width := 11;
    WebLabel25.Height := 18;
    WebLabel25.Caption := '*)';
    WebLabel26.SetParentComponent(Self);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 9;
    WebLabel26.Top := 8;
    WebLabel26.Width := 85;
    WebLabel26.Height := 19;
    WebLabel26.Caption := 'WebLabel1';
    WebLabel26.Font.Charset := DEFAULT_CHARSET;
    WebLabel26.Font.Color := clWindowText;
    WebLabel26.Font.Height := -16;
    WebLabel26.Font.Name := 'Arial';
    WebLabel26.Font.Style := [fsBold];
    WebLabel26.ParentFont := False;
    WebLabel27.SetParentComponent(Self);
    WebLabel27.Name := 'WebLabel27';
    WebLabel27.Left := 6;
    WebLabel27.Top := 267;
    WebLabel27.Width := 44;
    WebLabel27.Height := 18;
    WebLabel27.Caption := 'Handy';
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 112;
    WebEdit1.Top := 85;
    WebEdit1.Width := 249;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.TabOrder := 2;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 112;
    WebEdit2.Top := 113;
    WebEdit2.Width := 249;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 1;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.TabOrder := 3;
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 113;
    WebComboBox1.Top := 53;
    WebComboBox1.Width := 81;
    WebComboBox1.Height := 22;
    WebComboBox1.Font.Charset := DEFAULT_CHARSET;
    WebComboBox1.Font.Color := clWindowText;
    WebComboBox1.Font.Height := -16;
    WebComboBox1.Font.Name := 'Arial';
    WebComboBox1.Font.Style := [];
    WebComboBox1.ParentFont := False;
    WebComboBox1.ItemIndex := -1;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('Herr');
      WebComboBox1.Items.Add('Frau');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 112;
    WebEdit3.Top := 145;
    WebEdit3.Width := 249;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 1;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.TabOrder := 4;
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 112;
    WebEdit4.Top := 175;
    WebEdit4.Width := 249;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 1;
    WebEdit4.ElementClassName := 'form-control';
    WebEdit4.TabOrder := 5;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Left := 112;
    WebEdit5.Top := 204;
    WebEdit5.Width := 249;
    WebEdit5.Height := 22;
    WebEdit5.ChildOrder := 1;
    WebEdit5.ElementClassName := 'form-control';
    WebEdit5.TabOrder := 6;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 265;
    WebButton1.Top := 454;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn-secondary';
    WebButton1.TabOrder := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 456;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Neues Kundenkonto erstellen';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebEdit6.SetParentComponent(Self);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Left := 112;
    WebEdit6.Top := 235;
    WebEdit6.Width := 249;
    WebEdit6.Height := 22;
    WebEdit6.ChildOrder := 1;
    WebEdit6.ElementClassName := 'form-control';
    WebEdit6.TabOrder := 7;
    WebEdit7.SetParentComponent(Self);
    WebEdit7.Name := 'WebEdit7';
    WebEdit7.Left := 112;
    WebEdit7.Top := 294;
    WebEdit7.Width := 249;
    WebEdit7.Height := 22;
    WebEdit7.ChildOrder := 1;
    WebEdit7.ElementClassName := 'form-control';
    WebEdit7.TabOrder := 9;
    WebEdit8.SetParentComponent(Self);
    WebEdit8.Name := 'WebEdit8';
    WebEdit8.Left := 112;
    WebEdit8.Top := 332;
    WebEdit8.Width := 249;
    WebEdit8.Height := 22;
    WebEdit8.ChildOrder := 1;
    WebEdit8.ElementClassName := 'form-control';
    WebEdit8.TabOrder := 10;
    WebEdit9.SetParentComponent(Self);
    WebEdit9.Name := 'WebEdit9';
    WebEdit9.Left := 268;
    WebEdit9.Top := 55;
    WebEdit9.Width := 93;
    WebEdit9.Height := 22;
    WebEdit9.ChildOrder := 22;
    WebEdit9.ElementClassName := 'form-control';
    WebEdit9.TabOrder := 1;
    WebEdit10.SetParentComponent(Self);
    WebEdit10.Name := 'WebEdit10';
    WebEdit10.Left := 112;
    WebEdit10.Top := 368;
    WebEdit10.Width := 249;
    WebEdit10.Height := 22;
    WebEdit10.ChildOrder := 1;
    WebEdit10.ElementClassName := 'form-control';
    WebEdit10.PasswordChar := '*';
    WebEdit10.TabOrder := 11;
    WebEdit11.SetParentComponent(Self);
    WebEdit11.Name := 'WebEdit11';
    WebEdit11.Left := 112;
    WebEdit11.Top := 404;
    WebEdit11.Width := 249;
    WebEdit11.Height := 22;
    WebEdit11.ChildOrder := 1;
    WebEdit11.ElementClassName := 'form-control';
    WebEdit11.PasswordChar := '*';
    WebEdit11.TabOrder := 12;
    WebEdit12.SetParentComponent(Self);
    WebEdit12.Name := 'WebEdit12';
    WebEdit12.Left := 112;
    WebEdit12.Top := 266;
    WebEdit12.Width := 249;
    WebEdit12.Height := 22;
    WebEdit12.ChildOrder := 1;
    WebEdit12.ElementClassName := 'form-control';
    WebEdit12.TabOrder := 8;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebLabel27.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebEdit7.AfterLoadDFMValues;
    WebEdit8.AfterLoadDFMValues;
    WebEdit9.AfterLoadDFMValues;
    WebEdit10.AfterLoadDFMValues;
    WebEdit11.AfterLoadDFMValues;
    WebEdit12.AfterLoadDFMValues;
  end;
end;

end.
