unit UHMenu;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  UDatamodule,
  UGlobvar,
  UAnzSpeiseplan,
  UGetAuswahl,
  UKontoMenu,
  UConfirmEmail;

type
  THMenu = class(TForm)
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    Benutzer: TLabel;
    WebEdit1: TEdit;
    WebLabel1: TLabel;
    WebButton7: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure Zeige_Speiseplan(DataSet: TDataSet);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);

  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  HMenu: THMenu;



// procedure Zeige_Speiseplan(DataSet: TDataSet);


implementation


procedure THMenu.Zeige_Speiseplan(DataSet: TDataSet);
var
  newform: TAnzSpeiseplan;

begin
  newform := TAnzSpeiseplan.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TAnzSpeiseplan).Caption := 'Speiseplan';
      (AForm as TAnzSpeiseplan).WebEdit1.Text := AktKDName;
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//    ShowMessage('Form 2 closed with new value:');
//    WebEdit1.Text := newform.frm2Edit.Text;
  end
  );
end;


procedure THMenu.WebButton1Click(Sender: TObject);
begin
  Abbestell_Modus := 0;
  Datamodule1.TeilnehmerDataSet.Close;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Speiseplan;
  if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((KDID eq '+IntToStr(AktKDID)+'))') then begin
  end;

end;

procedure THMenu.WebButton2Click(Sender: TObject);
var T : TDateTime;
    HH,MM,SS,MS : word;
begin
  T := time;
  DecodeTime(T,HH,MM,SS,MS);
  if HH >= 8 then begin
    MessageDlg('Abbestellung nur bis 8 Uhr möglich', mtError, [], nil);

    exit;
  end;
  Abbestell_Modus := 1;
  Datamodule1.TeilnehmerDataSet.Close;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Speiseplan;
  if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((KDID eq '+IntToStr(AktKDID)+'))') then begin
  end;

end;

procedure THMenu.WebButton3Click(Sender: TObject);
begin
  Abbestell_Modus := 2;
  Datamodule1.TeilnehmerDataSet.Close;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Speiseplan;
  if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((KDID eq '+IntToStr(AktKDID)+'))') then begin
  end;

end;

procedure THMenu.WebButton4Click(Sender: TObject);
var
  newform: TKontoMenu;
begin
  newform := TKontoMenu.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TKontoMenu).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


{

  i := Get_Auswahl('Konto bearbeiten;Essensteilnehmer/Kinder;Kennwort ändern;Konto löschen');
  i := 1;
  case i of
    1 : begin
          Do_Edit_Konto4;
        end;

  end;
}

end;

procedure THMenu.WebButton5Click(Sender: TObject);
begin
  Abbestell_Modus := 4;
end;

procedure THMenu.WebButton6Click(Sender: TObject);
begin
//  Datamodule1.XDataWebConnection1.Connected := false;
  Close;
end;

procedure THMenu.WebButton7Click(Sender: TObject);
begin
  Abbestell_Modus := 3;
  Datamodule1.TeilnehmerDataSet.Close;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Speiseplan;
  if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((KDID eq '+IntToStr(AktKDID)+'))') then begin
  end;

end;

procedure THMenu.WebFormCreate(Sender: TObject);
var b : boolean;

begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
  b := DataModule1.KundenDataSet.FieldByName('Status').AsInteger > 0;
  WebButton1.Visible := b;
  WebButton2.Visible := b;
  WebButton3.Visible := b;
  WebButton4.Visible := b;
  WebButton5.Visible := b;
  WebButton7.Visible := b;


end;

procedure THMenu.WebFormShow(Sender: TObject);
var b : boolean;
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
  b := DataModule1.KundenDataSet.FieldByName('Status').AsInteger > 0;
  WebButton1.Visible := b;
  WebButton2.Visible := b;
  WebButton3.Visible := b;
  WebButton4.Visible := b;
  WebButton5.Visible := b;
  WebButton7.Visible := b;



end;

procedure THMenu.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Benutzer := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton7 := TButton.Create(Self);

  Benutzer.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  try
    Name := 'HMenu';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Benutzer.SetParentComponent(Self);
    Benutzer.Name := 'Benutzer';
    Benutzer.Left := 24;
    Benutzer.Top := 53;
    Benutzer.Width := 44;
    Benutzer.Height := 14;
    Benutzer.Caption := 'Benutzer';
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 86;
    WebButton1.Width := 273;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Speiseplan';
    WebButton1.ElementClassName := 'btn-secondary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 125;
    WebButton2.Width := 273;
    WebButton2.Height := 33;
    WebButton2.Caption := 'Essen f'#252'r heute abbestellen';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn-secondary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 24;
    WebButton3.Top := 164;
    WebButton3.Width := 273;
    WebButton3.Height := 33;
    WebButton3.Caption := 'Essen f'#252'r morgen abbestellen';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn-secondary';
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 24;
    WebButton4.Top := 281;
    WebButton4.Width := 273;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Konto bearbeiten';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn-secondary';
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 24;
    WebButton5.Top := 242;
    WebButton5.Width := 273;
    WebButton5.Height := 33;
    WebButton5.Caption := 'Essen f'#252'r Rest der Woche abbestellen';
    WebButton5.ChildOrder := 4;
    WebButton5.ElementClassName := 'btn-secondary';
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 24;
    WebButton6.Top := 337;
    WebButton6.Width := 273;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Programm beenden';
    WebButton6.ChildOrder := 5;
    WebButton6.ElementClassName := 'btn-secondary';
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 88;
    WebEdit1.Top := 50;
    WebEdit1.Width := 209;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 24;
    WebButton7.Top := 203;
    WebButton7.Width := 273;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Essen f'#252'r '#252'bermorgen abbestellen';
    WebButton7.ChildOrder := 4;
    WebButton7.ElementClassName := 'btn-secondary';
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
  finally
    Benutzer.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
  end;
end;

end.