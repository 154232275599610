{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2019                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.SQLCDS;

interface

uses
  Classes, SysUtils, JS, web, db, JSONDataset, WEBLib.CDS;

Type

  { TSQLRestConnection }

  TSQLRestConnection = class(TClientConnection)
  private
    FConnectionsResourceName: string;
    FCustomViewResourceName: string;
    FMetaDataResourceName: string;
    FonGetResources: TNotifyEvent;
    FResourceList: TStrings;
    procedure DoResources(Sender: TObject);
    function DoStoreMetadata: Boolean;
  protected
    procedure SetupRequest(aXHR : TJSXMLHttpRequest); override;
    function GetUpdateBaseURL(aRequest: TRecordUpdateDescriptor): string; override;
    function GetReadBaseURL(aRequest: TDataRequest): string; override;
  public
    constructor create(aOwner : TComponent); override;
    destructor Destroy; override;
    procedure GetResources(OnResult : TNotifyEvent = nil);
    property ResourceList: TStrings Read FResourceList;
  published
    property OnGetResources: TNotifyEvent read FonGetResources write FOnGetResources;
    property MetaDataResourceName: string read FMetaDataResourceName write FMetaDataResourceName stored DoStoreMetadata;
    property ConnectionsResourceName: string read FConnectionsResourceName write FConnectionsResourceName;
    property CustomViewResourceName: string read FCustomViewResourceName write FCustomViewResourceName;
  end;


  TWebSQLRestConnection = Class(TSQLRestConnection);

  { TSQLRestClientDataset }

  TSQLRestClientDataset = class(TClientDataset)
  private
    FDatabaseConnection: string;
    FResourceName: string;
    FSQL: TStrings;
    FParams: TParams;
    function CleanSQL: string;
    function CustomViewResourceName: String;
    procedure DoSQLChange(Sender: TObject);
    function MyURL: string;
    procedure SetResourceName(AValue: String);
    procedure SetSQL(AValue: TStrings);
    procedure SetParams(const Value: TParams);  Protected
  public
    constructor Create(aOwner : TComponent); override;
    destructor Destroy; override;
  published
    property DatabaseConnection : string read FDatabaseConnection write FDatabaseConnection;
    property IDField;
    property Params: TParams read FParams write SetParams;
    property ResourceName: string read FResourceName write SetResourceName;
    property SQL: TStrings read FSQL write SetSQL;
    property UseServerMetaData;
  end;

implementation

type

  { TServiceRequest }

  TServiceRequest = Class(TObject)
  private
    FOnMyDone,
    FOnDone : TNotifyEvent;
    FXHR: TJSXMLHttpRequest;
    function GetResult: String;
    function GetResultJSON: TJSObject;
    function GetStatusCode: Integer;
    function onLoad(Event{%H-}: TEventListenerEvent): boolean;
  public
    constructor Create(Const aMethod,aURL,aUserName,aPassword : String; aOnDone1 : TNotifyEvent; aOnDone2 : TNotifyEvent = Nil);
    procedure Execute;
    property RequestResult : string read GetResult;
    property ResultJSON : TJSObject read GetResultJSON;
    property OnDone : TNotifyEvent read FOnDone;
    property StatusCode : Integer read GetStatusCode;
  end;

{ TServiceRequest }

constructor TServiceRequest.Create(const aMethod,aURL, aUserName, aPassword: String; aOnDone1 : TNotifyEvent; aOnDone2 : TNotifyEvent = Nil);
begin
  FOnMyDone := aOnDone1;
  FOnDone := aOnDone2;
  FXHR := TJSXMLHttpRequest.New;
  FXHR.AddEventListener('load',@onLoad);
  FXHR.open(aMethod,aURL,true);
(*  else
    begin
//    FXHR.withCredentials := true;
    FXHR.open(aMethod,aURL,true,aUserName,aPassword);
    end;*)
  FXHR.setRequestHeader('Content-Type', 'application/json');
  FXHR.setRequestHeader('Authorization', 'Basic '+window.btoa(aUserName+':'+aPassword));
end;

procedure TServiceRequest.Execute;
begin
  FXHR.send;
end;

function TServiceRequest.GetResult: string;
begin
  Result := FXHR.responseText;
end;

function TServiceRequest.GetResultJSON: TJSObject;
begin
  if SameText(FXHR.getResponseHeader('Content-Type'),'application/json') then
    Result := TJSJSON.parseObject(RequestResult)
  else
    Result := nil;
end;

function TServiceRequest.GetStatusCode: Integer;
begin
  Result := FXHR.Status;
end;

function TServiceRequest.onLoad(Event: TEventListenerEvent): boolean;
begin
  if Assigned(FOnMyDone) then
    FOnMyDone(Self);
  Result := True;
end;

{ TSQLRestConnection }

function TSQLRestConnection.DoStoreMetadata: Boolean;
begin
  Result := (FMetadataResourceName <> 'metadata');
end;


procedure TSQLRestConnection.SetupRequest(aXHR: TJSXMLHttpRequest);
begin
  inherited SetupRequest(aXHR);
  aXHR.setRequestHeader('Content-Type', 'application/json');
  aXHR.setRequestHeader('Accept', 'application/json');
end;


function TSQLRestConnection.GetReadBaseURL(aRequest: TDataRequest): String;
var
  S : TSQLRestClientDataset;

begin
  Result := URI;
  if aRequest.Dataset is TSQLRestClientDataset then
  begin
    S := aRequest.Dataset as TSQLRestClientDataset;
    Result := IncludeTrailingPathDelimiter(Result) + S.MyURL;
  end;
  if Assigned(OnGetURL) then
    OnGetURL(Self,aRequest.Dataset,utGet,Result);
end;


function TSQLRestConnection.GetUpdateBaseURL(aRequest: TRecordUpdateDescriptor): String;
begin
  Result := URI;
  if aRequest.Dataset is TSQLRestClientDataset then
    Result := IncludeTrailingPathDelimiter(URI)+(aRequest.Dataset as TSQLRestClientDataset).MyURL;
  if Assigned(OnGetURL) then
    OnGetURL(Self,aRequest.Dataset,StatusToURLType(aRequest.Status),Result);
end;

procedure TSQLRestConnection.DoResources(Sender: TObject);
var
  R : TServiceRequest absolute Sender;
  J,Res : TJSObject;
  A : TJSArray;
  i : Integer;

begin
  FResourceList.Clear;
  if (R.StatusCode = 200) then
  begin
    J := R.ResultJSON;
    if J = nil then
       Exit;
    A := TJSArray(J.Properties['data']);
    for I := 0 to A.Length - 1 do
    begin
      Res := TJSObject(A[i]);
      FResourceList.Add(String(Res.Properties['name']));
    end;
  end;
  if Assigned(R.OnDone) then
    R.OnDone(Self);

  if Assigned(OnGetResources) then
    OnGetResources(Self);
end;

constructor TSQLRestConnection.create(aOwner: TComponent);
begin
  inherited create(aOwner);
  FResourceList := TStringList.Create;
  FMetaDataResourceName := 'metadata';
  metaDataNode := 'metaData';
  DataNode := 'data';
  TStringList(FResourceList).Sorted := true;
end;

destructor TSQLRestConnection.Destroy;
begin
  FreeAndNil(FResourceList);
  inherited Destroy;
end;

procedure TSQLRestConnection.GetResources(OnResult: TNotifyEvent);
var
  aURL : String;
  R : TServiceRequest;
begin
  aURL := IncludeTrailingPathDelimiter(URI)+MetaDataResourceName+'?fmt=json';
  R := TServiceRequest.Create('GET',aURL,Self.User,Self.Password,@DoResources,OnResult);
  R.Execute;
end;

{ TSQLRestClientDataset }


function TSQLRestClientDataset.MyURL: String;
var
  S,Pars : String;
  I : integer;

begin
  Result := DatabaseConnection;
  if (Result <> '') and (Result[Length(Result)] <> '/') then
    Result := Result + '/';

  Result := Result + ResourceName;
  if SameText(ResourceName,CustomViewResourceName) then
    Result := Result + '?SQL=' + EncodeURIComponent(CleanSQL);

  Pars := '';

  for I := 0 to Params.Count-1 do
  begin
    if Pars <> '' then
      Pars := Pars + '&';
    case Params[i].DataType of
     ftDateTime,ftDate,ftTime :
       S := FormatDateTime('yyyy"-"mm"-"dd"T"hh":"nn":"ss"',Params[i].AsDateTime);
     ftString:
       S := Params[i].AsString;
     ftInteger:
       S := IntToStr(Params[i].asInteger);
     ftLargeInt:
       S := IntToStr(Params[i].asLargeInt);
     ftFloat:
       Str(Params[i].asFloat,S);
     ftBoolean:
       BoolToStr(Params[i].asBoolean,True);
    else
       S := Params[i].asString;
    end;
    Pars := Pars + Params[i].Name + '=' + EncodeUriComponent(S);
  end;

  if Pars <> '' then
    begin
    if Pos('?',Result)<>0 then
      Result := Result + '&'
    else
      Result := Result + '?';
    Result := Result + Pars;
  end;
end;

procedure TSQLRestClientDataset.DoSQLChange(Sender: TObject);
begin
  if Trim(FSQL.Text) <> '' then
    FResourceName := CustomViewResourceName;
end;

procedure TSQLRestClientDataset.SetParams(const Value: TParams);
begin
  FParams := Value;
end;

procedure TSQLRestClientDataset.SetResourceName(AValue: String);
begin
  if FResourceName=AValue then
    Exit;

  CheckInactive;

  if Not SameText(aValue,CustomViewResourceName) then
    FSQL.Clear;
  FResourceName := AValue;
end;

function TSQLRestClientDataset.CustomViewResourceName : String;
begin
  if Assigned(Connection) and (Connection is TSQLRestConnection) then
    Result := (Connection as TSQLRestConnection).CustomViewResourceName
  else
    Result := 'customView';
end;

function TSQLRestClientDataset.CleanSQL: String;
begin
  Result := StringReplace(SQL.Text,#13#10,' ',[rfReplaceAll]);
  Result := StringReplace(Result,#10,' ',[rfReplaceAll]);
  Result := StringReplace(Result,#10,' ',[rfReplaceAll]);
end;

procedure TSQLRestClientDataset.SetSQL(AValue: TStrings);
begin
  if FSQL = AValue then
    Exit;
  FSQL.Assign(AValue);
end;


constructor TSQLRestClientDataset.Create(aOwner: TComponent);
begin
  inherited Create(aOwner);
  FSQL := TStringList.Create;
  FParams := TParams.Create(Self);
  TStringList(FSQL).OnChange := @DoSQLChange;
  UseServerMetadata := True;
end;

destructor TSQLRestClientDataset.Destroy;
begin
  FreeAndNil(FParams);
  FreeAndNil(FSQL);
  inherited Destroy;
end;

end.

