unit ULogin;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  UDataModule, WEBLib.DBCtrls,
  Types,
  UGlobvar,
  UNeuesKonto,
//  UMain_form,
  UHMenu,
  UHMenu2,
  UResetPassword;

const
  cStorageTokenName = 'KWSpeiseplan-token';



type

  TLoginProc = reference to function(const UserName, Password: string): Boolean;


  TLoginForm = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebButton1: TButton;
    WebImageControl1: TImageControl;
    WebButton2: TButton;
    WebButton3: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
    procedure PasswordResetAfterOpen(DataSet: TDataSet);
    function Check_Email_Reset : boolean;
    procedure Zeige_Haupt_Menu(DataSet: TDataSet);
    procedure WebButton4Click(Sender: TObject);
    procedure Zeige_Haupt_Menu_2(DataSet: TDataSet);
  private
    { Private-Deklarationen }
    FOnLogin: TLoginProc;
    FOnCreateAccount: TLoginProc;

    procedure CheckLogin;
    procedure DoLogout;
    procedure UpdateButtons;
    procedure DisableTasks;
    procedure EnableTasks;
    function GetToken: string;
  protected
    procedure LoadDFMValues; override;

  public
    { Public-Deklarationen
    class function DoLogin(LoginProc, CreateAccountProc: TLoginProc): Boolean;
    property OnLogin: TLoginProc read FOnLogin write FOnLogin;
    property OnCreateAccount: TLoginProc read FOnCreateAccount write FOnCreateAccount;
}

  end;

var
  LoginForm: TLoginForm;

implementation





procedure TLoginForm.DisableTasks;
begin
//  pnlTasks.Visible := False;
//  pnlLogin.Visible := True;
//  pnlTasks.Top := pnlLogin.Top + pnlLogin.Height + 8;
//  Datamodule1.XDataWebDataset.Close;
end;

procedure TLoginForm.DoLogout;
begin
  window.localStorage.RemoveItem(cStorageTokenName);
  CheckLogin;
end;

procedure TLoginForm.EnableTasks;
begin
//  pnlTasks.Visible := True;
//  pnlLogin.Visible := False;
//  pnlTasks.Top := pnlLogin.Top;
//  XDataWebDataset.Close;
//  XDataWebDataset.Load;
end;

function TLoginForm.GetToken: string;
begin
  if not JS.IsNull(window.localStorage.getItem(cStorageTokenName)) then
    Result := window.localStorage.getItem(cStorageTokenName)
  else
    Result := '';
end;



procedure TLoginForm.UpdateButtons;
begin
{
  if XDataWebDataset.Active then
  begin
    btUpdateTask.Enabled := XDataWebDataset.State in [dsEdit, dsInsert];
    btCancel.Enabled := XDataWebDataset.State in [dsInsert, dsEdit];
    btDelete.Enabled := not XDataWebDataset.IsEmpty;
    btNewTask.Enabled := XDataWebDataset.State in [dsBrowse];
  end
  else
  begin
    btUpdateTask.Enabled := false;
    btCancel.Enabled := false;
    btDelete.Enabled := false;
    btNewTask.Enabled := false;
  end;
}
end;



procedure TLoginForm.WebButton1Click(Sender: TObject);
var hst,
    hst2 : String;
    Values: TJSArray;
    Values2: TJSValueDynArray;
    Menuform: THMenu;

begin

  Datamodule1.XDataWebConnection1.Connected := true;


  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;
  hst2 := Trim(WebEdit2.Text);
  WebEdit2.Text := hst2;
  if hst2 = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    exit;
  end;

  hst := Uppercase(hst);
  DataModule1.XDataClient.RawInvoke('IAuthService.Login', [hst, hst2],
    procedure(Response: TXDataClientResponse)
    var
      Token: string;
    begin
      Token := JS.ToString(JS.ToObject(Response.Result)['value']);
      window.localStorage.setItem(cStorageTokenName, Token);
      if Token <> '' then begin
        Akt_JWToken := Token;
        AktKDName := WebEdit1.Text;
//        if Get_AktKDID(AktFirmaNo,WebEdit1.Text) < 0 then ;
//        ShowMessage(IntToStr(AktKDID));

        KundenDataSet_Mode := KDDataset_FindKey;
        Datamodule1.XDataWebConnection1.Connected := true;
        DataModule1.KundenDataset.Active := false;
        DataModule1.KundenDataset.AfterOpen := Zeige_Haupt_Menu;
        hst := Trim(Uppercase(WebEdit1.Text));
        DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
        DataModule1.KundenDataset.Load;

{        Menuform := THMenu.CreateNew(procedure(AForm: TObject)
          begin
            (AForm as THMenu).Caption := 'Hauptmenü';
//            (AForm as THMenu).WebEdit1.Text := WebEdit1.Text;

          end
        );
        Menuform.Border := fbSingle;

        Menuform.ShowModal(procedure(AValue: TModalResult)
        begin
          Close;
//          ShowMessage('Form 2 closed with new value:');
      //    WebEdit1.Text := newform.frm2Edit.Text;
        end
        );
}

      end;
    end
  );

{
  KundenDataSet_Mode := KDDataset_FindKey;
  Datamodule1.XDataWebConnection1.Connected := true;
  DataModule1.KundenDataset.Active := false;
  DataModule1.KundenDataset.AfterOpen := Zeige_Haupt_Menu;
  hst := Trim(Uppercase(WebEdit1.Text));
  DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
  DataModule1.KundenDataset.Load;
}

{

  Datamodule1.XDataWebConnection1.Connected := true;


  DataModule1.KundenDataset.Close;  // Active := false;

  hst := Trim(Uppercase(hst));
//  MessageDlg(hst, mtError, [], nil);

  DataModule1.KundenDataset.QueryString := '$filter=(UPC_EMAIL eq '+''''+hst+''''+')';
//  DataModule1.GetKD_Dataset.Active := true;

  DataModule1.KundenDataset.Load;
//  WebEdit3.Text := IntToStr(DataModule1.GetKD_Dataset.RecordCount);         [
//   Values := [mcustname.Value, mSampleDate.Date];

//  Values2 := [''''+hst+''''];

//  if DataModule1.KundenDataset.Locate('UPC_EMAIL', values2, []) then begin

  if  Do_Find(DataModule1.KundenDataset,'UPC_EMAIL',hst) then ;
//    if (DataModule1.KundenDataset.FieldByName('UPC_EMAIL').AsString <> hst)
//      or (DataModule1.KundenDataset.FieldByName('Kennwort').AsString <> hst2) then begin
{  if DataModule1.KundenDataset.Active then begin
      if (WebDBEdit2.Text <> hst) or (WebDBEdit1.Text <> hst2) then begin
        MessageDlg('Email-Adresse oder Kennwort ungültig', mtError, [], nil);
      end
      else begin
        MessageDlg('OK', mtError, [], nil);
      end;
    end
    else begin
      MessageDlg('Warten', mtError, [], nil);

    end;
}

//  Datamodule1.XDataWebConnection1.Connected := true;
//  Datamodule1.KundenDataSet.Active := true;
//  Datamodule1.KundenDataSet.Load;
//  Datamodule1.KundenDataSet.Edit;
end;

procedure TLoginForm.WebButton2Click(Sender: TObject);
var
  newform: TNeuesKonto;

begin
  newform := TNeuesKonto.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TNeuesKonto).Caption := 'Neues Konto';
//      (AForm as TNeuesKonto).WebLabel1.Caption := AktFirmaName;
//      frm2Edit.Text := WebEdit1.Text;
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//    WebEdit1.Text := newform.frm2Edit.Text;
  end
  );



end;


{
var i,j : integer;
    hst : String;

  hst := 'Select blablabla';
  i := 4711;
  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [i,hst],
    procedure(Response: TXDataClientResponse)
    var
      k :  integer;
      Token : String;
    begin
      Token := JS.ToString(JS.ToObject(Response.Result)['value']);
//      k := JS.ToInteger(JS.ToObject(Response.Result)['value']);

//      window.localStorage.setItem(cStorageTokenName, Token);
//      CheckLogin;
    end
  );


//  if Do_post(Datamodule1.KundenDataSet) > 0 then ;

//  Datamodule1.KundenDataSet.Post;
//  Datamodule1.KundenDataSet.ApplyUpdates;
//  Datamodule1.XDataWebConnection1.Connected := false;
end;

{
  procedure DialogProc(AValue: TModalResult);
  var
    s: string;
  begin
    if AValue = mrOk then
      s := 'OK clicked'
    else if AValue = mrYes then
      s := 'Yes clicked'
    else if AValue = mrNo then
      s := 'No clicked'
    else if AValue = mrAbort then
      s := 'Abort clicked'
    else if AValue = mrRetry then
      s := 'Retry clicked'
    else if AValue = mrClose then
      s := 'Close clicked'
    else if AValue = mrCancel then
      s := 'Cancelled';

    WebLabel2.Caption := s;
  end;

begin
  case WebListBox2.ItemIndex of
    0: MessageDlg(WebEdit1.Text, mtWarning, [], @DialogProc);
    1: MessageDlg(WebEdit1.Text, mtError, [], @DialogProc);
    2: MessageDlg(WebEdit1.Text, mtInformation, [], @DialogProc);
    3: MessageDlg(WebEdit1.Text, mtConfirmation, [mbYes, mbNo, mbCancel], @DialogProc);
    4: MessageDlg(WebEdit1.Text, mtCustom, [mbAbort, mbRetry, mbClose], @DialogProc);
  end;
end;
}


procedure TLoginForm.WebButton3Click(Sender: TObject);
var hst : String;
    i : integer;
begin
  Datamodule1.XDataWebConnection1.Connected := true;

  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;
  if Check_Email_Reset  then ;

//  if GetKundebyEmail(KDDataset_PasswordReset,WebEdit1.Text) then ;


end;

procedure TLoginForm.WebButton4Click(Sender: TObject);
var hst,
    hst2 : String;
    Values: TJSArray;
    Values2: TJSValueDynArray;
    Menuform: THMenu;

begin

  Datamodule1.XDataWebConnection1.Connected := true;


  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;
  hst2 := Trim(WebEdit2.Text);
  WebEdit2.Text := hst2;
  if hst2 = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    exit;
  end;

  hst := Uppercase(hst);
  DataModule1.XDataClient.RawInvoke('IAuthService.Login', [hst, hst2],
    procedure(Response: TXDataClientResponse)
    var
      Token: string;
    begin
      Token := JS.ToString(JS.ToObject(Response.Result)['value']);
      window.localStorage.setItem(cStorageTokenName, Token);
      if Token <> '' then begin
        Akt_JWToken := Token;
        AktKDName := WebEdit1.Text;

        KundenDataSet_Mode := KDDataset_FindKey;
        Datamodule1.XDataWebConnection1.Connected := true;
        DataModule1.KundenDataset.Active := false;
        DataModule1.KundenDataset.AfterOpen := Zeige_Haupt_Menu_2;
        hst := Trim(Uppercase(WebEdit1.Text));
        DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
        DataModule1.KundenDataset.Load;
      end;
    end
  );

end;

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
  Application.OnImageCacheReady := WebFormShow;
  Datamodule1.XDataWebConnection1.Connected := True;

  AktFirmaName := 'Fleischerei Haustein';
  AktPrgname := 'KW-Speiseplan';
  Akt_JWToken := '';

end;

procedure TLoginForm.WebFormShow(Sender: TObject);
begin
  application.OnImageCacheReady := nil;
  Init_Globvar;
end;

procedure TLoginForm.CheckLogin;
begin
  if GetToken = '' then
    DisableTasks
  else begin

  end;
end;

procedure TLoginForm.XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
begin
  if GetToken <> '' then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + GetToken);
end;






procedure TLoginForm.PasswordResetAfterOpen(DataSet: TDataSet);
begin
  case KundenDataSet_Mode of
    KDDataset_PasswordReset : begin
                                if Datamodule1.KundenDataSet.RecordCount = 0 then begin
                                  MessageDlg('Kein Kundenkonto mit dieser Email-Adresse gefunden', mtError, [], nil);
                                end
                                else begin
                                  AktKDName := WebEdit1.Text;
                                  Do_ResetPassword;
                                end;
                           end;

  end;

end;



procedure TLoginForm.Zeige_Haupt_Menu(DataSet: TDataSet);
var Menuform: THMenu;
    NewForm : THMenu2;

    i : integer;
begin
  if Akt_JWToken = '' then exit;
//  i := DataModule1.KundenDataSet.ServerRecordCount;
  if DataModule1.KundenDataSet.RecordCount = 0 then exit;
  AktKDID := DataModule1.KundenDataset.Fieldbyname('ID').AsInteger;
//  ShowMessage(IntToStr(AktKDID));

  if DataModule1.KundenDataSet.Fieldbyname('AdminLevel').AsInteger > 0 then begin
    WebEdit1.Text := '';
    WebEdit2.Text := '';
    Newform := THMenu2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu2).Caption := 'Hauptmenü';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );

  end
  else begin
    WebEdit1.Text := '';
    WebEdit2.Text := '';
    Menuform := THMenu.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu).Caption := 'Hauptmenü';
      end
    );
    Menuform.Border := fbSingle;

    Menuform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );
  end;
end;



procedure TLoginForm.Zeige_Haupt_Menu_2(DataSet: TDataSet);
var Newform: THMenu2;
    i : integer;
begin
  if Akt_JWToken = '' then exit;
//  i := DataModule1.KundenDataSet.ServerRecordCount;
  if DataModule1.KundenDataSet.RecordCount = 0 then exit;

    WebEdit1.Text := '';
    WebEdit2.Text := '';
    Newform := THMenu2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu2).Caption := 'Hauptmenü';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );

end;



function TLoginForm.Check_Email_Reset : boolean;
var hst : String;
begin
  KundenDataSet_Mode := KDDataset_PasswordReset;
  Datamodule1.XDataWebConnection1.Connected := true;
  DataModule1.KundenDataset.Active := false;
  DataModule1.KundenDataset.AfterOpen := PasswordResetAfterOpen;
  hst := Trim(Uppercase(WebEdit1.Text));
  DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
  DataModule1.KundenDataset.Load;
//  DataModule1.TeilnehmerDataset.Load;
//  Datamodule1.WebClientDataSet2.Active := true;

end;


procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 240;
    WebLabel1.Width := 70;
    WebLabel1.Height := 14;
    WebLabel1.Caption := 'eMail-Adresse';
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 300;
    WebLabel2.Width := 48;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Kennwort';
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 8;
    WebImageControl1.Top := 8;
    WebImageControl1.Width := 305;
    WebImageControl1.Height := 185;
    WebImageControl1.ChildOrder := 5;
    WebImageControl1.Picture.LoadFromFile('ULogin.WebImageControl1.Picture.bmp');
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 16;
    WebEdit1.Top := 260;
    WebEdit1.Width := 289;
    WebEdit1.Height := 22;
    WebEdit1.AutoCompletion := acUserName;
    WebEdit1.ChildOrder := 1;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 16;
    WebEdit2.Top := 320;
    WebEdit2.Width := 145;
    WebEdit2.Height := 22;
    WebEdit2.AutoCompletion := acCurrentPassword;
    WebEdit2.ChildOrder := 3;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.PasswordChar := '*';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 8;
    WebButton1.Top := 368;
    WebButton1.Width := 121;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Anmelden';
    WebButton1.ChildOrder := 4;
    WebButton1.ElementClassName := 'btn btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 135;
    WebButton2.Top := 368;
    WebButton2.Width := 170;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Neues Konto erstellen';
    WebButton2.ChildOrder := 7;
    WebButton2.ElementClassName := 'btn-secondary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 183;
    WebButton3.Top := 319;
    WebButton3.Width := 122;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Kennwort vergessen';
    WebButton3.ChildOrder := 8;
    WebButton3.ElementClassName := 'btn-secondary';
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
  end;
end;

end.
