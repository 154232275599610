{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Calendar;

interface

uses
  Classes, Web, JS, WEBLib.Controls, libcalendar;

type
  TCalendarInactiveDays = class(TPersistent)
  private
    FSunday: Boolean;
    FWednesday: Boolean;
    FTuesday: Boolean;
    FFriday: Boolean;
    FThursday: Boolean;
    FMonday: Boolean;
    FSaturday: Boolean;
    FOnChanged: TNotifyEvent;
    procedure SetFriday(const Value: Boolean);
    procedure SetMonday(const Value: Boolean);
    procedure SetSaturday(const Value: Boolean);
    procedure SetSunday(const Value: Boolean);
    procedure SetThursday(const Value: Boolean);
    procedure SetTuesDay(const Value: Boolean);
    procedure SetWednesday(const Value: Boolean);
  protected
    procedure Changed;
  public
    constructor Create; virtual;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property Sunday: Boolean read FSunday write SetSunday;
    property Monday: Boolean read FMonday write SetMonday;
    property Tuesday: Boolean read FTuesday write SetTuesDay;
    property Wednesday: Boolean read FWednesday write SetWednesday;
    property Thursday: Boolean read FThursday write SetThursday;
    property Friday: Boolean read FFriday write SetFriday;
    property Saturday: Boolean read FSaturday write SetSaturday;
  end;

  TCalendarWeekDayNames = class(TPersistent)
  private
    FSunday: string;
    FWednesday: string;
    FTuesday: string;
    FFriday: string;
    FThursday: string;
    FMonday: string;
    FSaturday: string;
    FOnChanged: TNotifyEvent;
    procedure SetFriday(const Value: string);
    procedure SetMonday(const Value: string);
    procedure SetSaturday(const Value: string);
    procedure SetSunday(const Value: string);
    procedure SetThursday(const Value: string);
    procedure SetTuesDay(const Value: string);
    procedure SetWednesday(const Value: string);
  protected
    procedure Changed;
  public
    constructor Create; virtual;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property Sunday: string read FSunday write SetSunday;
    property Monday: string read FMonday write SetMonday;
    property Tuesday: string read FTuesday write SetTuesDay;
    property Wednesday: string read FWednesday write SetWednesday;
    property Thursday: string read FThursday write SetThursday;
    property Friday: string read FFriday write SetFriday;
    property Saturday: string read FSaturday write SetSaturday;
  end;

  TCalendarMonthNames = class(TPersistent)
  private
    FApril: string;
    FNovember: string;
    FMay: string;
    FDecember: string;
    FAugust: string;
    FFebruary: string;
    FJune: string;
    FMarch: string;
    FJuly: string;
    FOctober: string;
    FSeptember: string;
    FJanuary: string;
    FOnChanged: TNotifyEvent;
    procedure SetApril(const Value: string);
    procedure SetAugust(const Value: string);
    procedure SetDecember(const Value: string);
    procedure SetFebruary(const Value: string);
    procedure SetJanuary(const Value: string);
    procedure SetJuly(const Value: string);
    procedure SetJune(const Value: string);
    procedure SetMarch(const Value: string);
    procedure SetMay(const Value: string);
    procedure SetNovember(const Value: string);
    procedure SetOctober(const Value: string);
    procedure SetSeptember(const Value: string);
  protected
    procedure Changed;
  public
    constructor Create; virtual;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property January: string read FJanuary write SetJanuary;
    property February: string read FFebruary write SetFebruary;
    property March: string read FMarch write SetMarch;
    property April: string read FApril write SetApril;
    property May: string read FMay write SetMay;
    property June: string read FJune write SetJune;
    property July: string read FJuly write SetJuly;
    property August: string read FAugust write SetAugust;
    property September: string read FSeptember write SetSeptember;
    property October: string read FOctober write SetOctober;
    property November: string read FNovember write SetNovember;
    property December: string read FDecember write SetDecember;
  end;

  TCalendarSelectDateEvent = procedure(Sender: TObject; ADate: TDateTime) of object;
  TCalendarDates = array of TDateTime;

  TCalendar = class(TCustomControl)
  private
    FSelectedDate: TDateTime;
    FCalendar: TJSCalendar;
    FMultiSelect: Boolean;
    FDay: Integer;
    FMonth: Integer;
    FYear: Integer;
    FFirstDay: Integer;
    FNameOfDays: TCalendarWeekDayNames;
    FMonthNames: TCalendarMonthNames;
    FEnablePastDates: Boolean;
    FInactiveDays: TCalendarInactiveDays;
    FShowToday: Boolean;
    FOnDateSelected: TCalendarSelectDateEvent;
    FReadOnly: Boolean;
    FHintNext: string;
    FHintPrev: string;
    FMaxDate: TDateTime;
    FMinDate: TDateTime;
    FElementHeaderClassName: TElementClassName;
    FElementBackgroundClassName: TElementClassName;
    FElementDayNamesClassName: TElementClassName;
    FElementSelectedDateClassName: TElementClassName;
    FElementCurrentDateClassName: TElementClassName;
    FOnDateUnselected: TCalendarSelectDateEvent;
    function GetDay: Integer;
    function GetMonth: Integer;
    function GetSelectedDate: TDateTime;
    function GetYear: Integer;
    procedure SetDay(const Value: Integer);
    procedure SetMonth(const Value: Integer);
    procedure SetSelectedDate(const Value: TDateTime);
    procedure SetYear(const Value: Integer);
    procedure SetMultiSelect(const Value: Boolean);
    procedure SetFirstDay(const Value: Integer);
    procedure SetNameOfDays(const Value: TCalendarWeekDayNames);
    procedure SetMonthNames(const Value: TCalendarMonthNames);
    procedure SetEnablePastDates(const Value: Boolean);
    procedure SetInactiveDays(const Value: TCalendarInactiveDays);
    procedure SetShowToday(const Value: Boolean);
    procedure SetReadOnly(const Value: Boolean);
    procedure SetHintNext(const Value: string);
    procedure SetHintPrev(const Value: string);
    procedure SetMaxDate(const Value: TDateTime);
    procedure SetMinDate(const Value: TDateTime);
    procedure SetElementBackgroundClassName(const Value: TElementClassName);
    procedure SetElementHeaderClassName(const Value: TElementClassName);
    procedure SetElementSelectedDateClassName(const Value: TElementClassName);
    procedure SetElementDayNamesClassName(const Value: TElementClassName);
    procedure SetElementCurrentDateClassName(const Value: TElementClassName);
  protected
    procedure SetElementClassName(AValue: string); override;

    function CreateElement: TJSElement; override;
    function GetNameOfDaysArray: TJSArray;
    function GetMonthNamesArray: TJSArray;
    function GetEnabledDaysArray: TJSArray;
    function GetID: string; override;
    procedure InactiveDaysChanged(Sender: TObject);
    procedure NameOfDaysChanged(Sender: TObject);
    procedure MonthNamesChanged(Sender: TObject);
    procedure HandleDateSelect(Data: TJSDate);
    procedure HandleDateUnselect(Data: TJSDate);
  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
    procedure EndUpdate; override;
    function SelectedDates: TCalendarDates;
  published
    property Align;
    property Anchors;
    property ChildOrder;
    property ShowHint;
    property Visible;
    property TabOrder;
    property TabStop;
    property ElementSelectedDateClassName: TElementClassName read FElementSelectedDateClassName write SetElementSelectedDateClassName;
    property ElementCurrentDateClassName: TElementClassName read FElementCurrentDateClassName write SetElementCurrentDateClassName;
    property ElementBackgroundClassName: TElementClassName read FElementBackgroundClassName write SetElementBackgroundClassName;
    property ElementDayNamesClassName: TElementClassName read FElementDayNamesClassName write SetElementDayNamesClassName;
    property ElementHeaderClassName: TElementClassName read FElementHeaderClassName write SetElementHeaderClassName;
    property SelectedDate: TDateTime read GetSelectedDate write SetSelectedDate;
    property Year: Integer read GetYear write SetYear;
    property Month: Integer read GetMonth write SetMonth;
    property Day: Integer read GetDay write SetDay;
    property Font;
    property HintNext: string read FHintNext write SetHintNext;
    property HintPrev: string read FHintPrev write SetHintPrev;
    property MaxDate: TDateTime read FMaxDate write SetMaxDate;
    property MinDate: TDateTime read FMinDate write SetMinDate;
    property MultiSelect: Boolean read FMultiSelect write SetMultiSelect;
    property FirstDay: Integer read FFirstDay write SetFirstDay;
    property EnablePastDates: Boolean read FEnablePastDates write SetEnablePastDates;
    property InactiveDays: TCalendarInactiveDays read FInactiveDays write SetInactiveDays;
    property NameOfMonths: TCalendarMonthNames read FMonthNames write SetMonthNames;
    property NameOfDays: TCalendarWeekDayNames read FNameOfDays write SetNameOfDays;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly;
    property ShowToday: Boolean read FShowToday write SetShowToday;
    property OnDateSelected: TCalendarSelectDateEvent read FOnDateSelected write FOnDateSelected;
    property OnDateUnselected: TCalendarSelectDateEvent read FOnDateUnselected write FOnDateUnselected;
    property OnEnter;
    property OnExit;
    property OnMouseDown;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnMouseMove;
    property OnMouseUp;
  end;

  TWebCalendar = class(TCalendar);

implementation

uses
  DateUtils, SysUtils;

{ TCalendar }

function TCalendar.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
  Result.setAttribute('class','vanilla-calendar');
end;

procedure TCalendar.CreateInitialize;
begin
  inherited;
  FShowToday := True;
  FSelectedDate := Int(Now);
  FYear := YearOf(Now);
  FMonth := MonthOf(Now);
  FDay := DayOf(Now);
  FEnablePastDates := True;
  FMultiSelect := True;
  FReadOnly := False;
  FFirstDay := 1;
  FHintNext := '';
  FHintPrev := '';
  FElementHeaderClassName := '';
  FElementBackgroundClassName := '';
  FElementDayNamesClassName := '';
  FElementSelectedDateClassName := '';
  FElementCurrentDateClassName := '';
  FInactiveDays := TCalendarInactiveDays.Create;
  FInactiveDays.OnChanged := InactiveDaysChanged;
  FNameOfDays := TCalendarWeekDayNames.Create;
  FNameOfDays.OnChanged := NameOfDaysChanged;
  FMonthNames := TCalendarMonthNames.Create;
  FMonthNames.OnChanged := MonthNamesChanged;
  Width := 250;
  Height := 250;
end;

destructor TCalendar.Destroy;
begin
  FInactiveDays.Free;
  FNameOfDays.Free;
  FMonthNames.Free;
  inherited;
end;

procedure TCalendar.InactiveDaysChanged(Sender: TObject);
var
  arr: TJSArray;
begin
  if Assigned(FCalendar) then
  begin
    arr := GetEnabledDaysArray;
    if Assigned(arr) then
    begin
      FCalendar.setDatesFilter(True);
      FCalendar.setEnabledWeekDays(arr);
    end;
  end;
end;

procedure TCalendar.EndUpdate;
var
  opt: TJSCalendarOptions;
  arr: TJSArray;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.setAttribute('class','vanilla-calendar');
    if ElementClassName <> '' then
      ElementHandle.classList.add(ElementClassName);

    if FElementBackgroundClassName <> '' then
      ElementHandle.classList.add(FElementBackgroundClassName)
    else
      ElementHandle.classList.add('tms-calendar-background-default');

    opt := TJSCalendarOptions(new([]));
    opt.selector := '#' + ElementHandle.id;
    opt.multiselect := FMultiSelect;
    opt.firstDay := FFirstDay - 1;
    opt.pastDates := FEnablePastDates;
    opt.showToday := FShowToday;
    opt.readOnly := FReadOnly;
    opt.selectedDate := DateTimeToJSDate(FSelectedDate);
    opt.buttonHintNext := FHintNext;
    opt.buttonHintPrev := FHintPrev;
    opt.backgroundClass := FElementBackgroundClassName;
    opt.selectedClass := FElementSelectedDateClassName;
    opt.currentClass := FElementCurrentDateClassName;
    opt.weekClass := FElementDayNamesClassName;
    opt.headerClass := FElementHeaderClassName;
    opt.onSelect := @HandleDateSelect;
    opt.onUnselect := @HandleDateUnselect;

    if FMinDate <> 0.0 then
    begin
      opt.datesFilter := True;
      opt.minDate := DateTimeToJSDate(FMinDate);
    end;

    if FMaxDate <> 0.0 then
    begin
      opt.datesFilter := True;
      opt.maxDate := DateTimeToJSDate(FMaxDate);
    end;

    arr := GetEnabledDaysArray;
    if Assigned(arr) then
    begin
      opt.datesFilter := True;
      opt.availableWeekDays := arr;
    end;

    arr := GetNameOfDaysArray;
    if Assigned(arr) then
      opt.shortWeekday := arr;

    arr := GetMonthNamesArray;
    if Assigned(arr) then
      opt.months := arr;

    FCalendar := TJSCalendar.new(opt);
  end;
end;

function TCalendar.GetDay: Integer;
begin
  if Assigned(FCalendar) then
    FDay := FCalendar.getSelectedDay;

  Result := FDay;
end;

function TCalendar.GetEnabledDaysArray: TJSArray;
begin
  Result := nil;
  if Assigned(FInactiveDays) then
  begin
    Result := TJSArray.new;
    if not FInactiveDays.Sunday then
      Result.push(new(['day', 'sunday']));
    if not FInactiveDays.Monday then
      Result.push(new(['day', 'monday']));
    if not FInactiveDays.Tuesday then
      Result.push(new(['day', 'tuesday']));
    if not FInactiveDays.Wednesday then
      Result.push(new(['day', 'wednesday']));
    if not FInactiveDays.Thursday then
      Result.push(new(['day', 'thursday']));
    if not FInactiveDays.Friday then
      Result.push(new(['day', 'friday']));
    if not FInactiveDays.Saturday then
      Result.push(new(['day', 'saturday']));
  end;
end;

function TCalendar.GetID: string;
begin
  Result := inherited GetID;

  if Result = '' then
  begin
    ElementID := GetNewName;
    Result := ElementID;
  end;
end;

function TCalendar.GetMonth: Integer;
begin
  if Assigned(FCalendar) then
    FMonth := FCalendar.getSelectedMonth;

  Result := FMonth;
end;

function TCalendar.GetMonthNamesArray: TJSArray;
begin
  Result := nil;
  if Assigned(FMonthNames) then
  begin
    Result := TJSArray.new;
    Result.push(FMonthNames.January);
    Result.push(FMonthNames.February);
    Result.push(FMonthNames.March);
    Result.push(FMonthNames.April);
    Result.push(FMonthNames.May);
    Result.push(FMonthNames.June);
    Result.push(FMonthNames.July);
    Result.push(FMonthNames.August);
    Result.push(FMonthNames.September);
    Result.push(FMonthNames.October);
    Result.push(FMonthNames.November);
    Result.push(FMonthNames.December);
  end;
end;

function TCalendar.GetNameOfDaysArray: TJSArray;
begin
  Result := nil;
  if Assigned(FNameOfDays) then
  begin
    Result := TJSArray.new;
    Result.push(FNameOfDays.Sunday);
    Result.push(FNameOfDays.Monday);
    Result.push(FNameOfDays.Tuesday);
    Result.push(FNameOfDays.Wednesday);
    Result.push(FNameOfDays.Thursday);
    Result.push(FNameOfDays.Friday);
    Result.push(FNameOfDays.Saturday);
  end;
end;

function TCalendar.GetSelectedDate: TDateTime;
begin
  if Assigned(FCalendar) then
    FSelectedDate := JSDateToDateTime(FCalendar.getSelectedDate);

  Result := FSelectedDate
end;

function TCalendar.GetYear: Integer;
begin
  if Assigned(FCalendar) then
    FYear := FCalendar.getSelectedYear;

  Result := FYear;
end;

procedure TCalendar.HandleDateSelect(Data: TJSDate);
begin
  if Assigned(OnDateSelected) then
    OnDateSelected(Self, JSDateToDateTime(Data));
end;

procedure TCalendar.HandleDateUnselect(Data: TJSDate);
begin
  if Assigned(OnDateUnselected) then
    OnDateUnselected(Self, JSDateToDateTime(Data));
end;

procedure TCalendar.MonthNamesChanged(Sender: TObject);
var
  arr: TJSArray;
begin
  if Assigned(FCalendar) then
  begin
    arr := GetMonthNamesArray;
    if Assigned(arr) then
      FCalendar.setMonths(arr);
  end;
end;

procedure TCalendar.NameOfDaysChanged(Sender: TObject);
var
  arr: TJSArray;
begin
  if Assigned(FCalendar) then
  begin
    arr := GetNameOfDaysArray;
    if Assigned(arr) then
      FCalendar.setShortWeekdays(arr);
  end;
end;

function TCalendar.SelectedDates: TCalendarDates;
var
  arr: TJSArray;
  d: TJSDate;
  I: Integer;
begin
  if Assigned(FCalendar) then
  begin
    SetLength(Result, FCalendar.getSelectedDateCount);
    arr := FCalendar.getSelectedDays;
    for I := 0 to arr.length - 1 do
    begin
      d := TJSDate.new(JS.toString(arr[I]));
      Result[I] := JSDateToDateTime(d);
    end;
  end;
end;

procedure TCalendar.SetDay(const Value: Integer);
begin
  FDay := Value;
  if Assigned(FCalendar) then
    FCalendar.setSelectedDay(Value);
end;

procedure TCalendar.SetInactiveDays(const Value: TCalendarInactiveDays);
var
  arr: TJSArray;
begin
  FInactiveDays := Value;
  if Assigned(FCalendar) then
  begin
    arr := GetEnabledDaysArray;
    if Assigned(arr) then
      FCalendar.setEnabledWeekDays(arr);
  end;
end;

procedure TCalendar.SetElementBackgroundClassName(
  const Value: TElementClassName);
begin
  if Assigned(ElementHandle) and (FElementBackgroundClassName <> '') then
    ElementHandle.classList.remove(FElementBackgroundClassName);

  FElementBackgroundClassName := Value;
  if Assigned(ElementHandle) then
   begin
    if FElementBackgroundClassName <> '' then
      ElementHandle.classList.add(Value)
    else
      ElementHandle.classList.add('tms-calendar-background-default');
   end;
end;

procedure TCalendar.SetElementClassName(AValue: string);
begin
  inherited;
  BeginUpdate;
  EndUpdate;
end;

procedure TCalendar.SetElementCurrentDateClassName(
  const Value: TElementClassName);
begin
  FElementCurrentDateClassName := Value;
  if Assigned(FCalendar) then
    FCalendar.setCurrentClass(Value);
end;

procedure TCalendar.SetElementHeaderClassName(const Value: TElementClassName);
begin
  FElementHeaderClassName := Value;
  if Assigned(FCalendar) then
    FCalendar.setHeaderClass(Value);
end;

procedure TCalendar.SetElementSelectedDateClassName(
  const Value: TElementClassName);
begin
  FElementSelectedDateClassName := Value;
  if Assigned(FCalendar) then
    FCalendar.setSelectedClass(Value);
end;

procedure TCalendar.SetElementDayNamesClassName(const Value: TElementClassName);
begin
  FElementDayNamesClassName := Value;
  if Assigned(FCalendar) then
    FCalendar.setWeekClass(Value);
end;

procedure TCalendar.SetEnablePastDates(const Value: Boolean);
begin
  FEnablePastDates := Value;
  if Assigned(FCalendar) then
    FCalendar.setPastDates(Value);
end;

procedure TCalendar.SetFirstDay(const Value: Integer);
begin
  if (Value > 0) and (Value < 8)  then
  begin
    FFirstDay := Value;
    if Assigned(FCalendar) then
      FCalendar.setFirstDay(Value - 1);
  end;
end;

procedure TCalendar.SetHintNext(const Value: string);
begin
  FHintNext := Value;
  if Assigned(FCalendar) then
    FCalendar.setButtonHintNext(Value);
end;

procedure TCalendar.SetHintPrev(const Value: string);
begin
  FHintPrev := Value;
  if Assigned(FCalendar) then
    FCalendar.setButtonHintPrev(Value);
end;

procedure TCalendar.SetMaxDate(const Value: TDateTime);
begin
  FMaxDate := Value;
  if Assigned(FCalendar) then
  begin
    if Value = 0.0 then
      FCalendar.setMaxDate(nil)
    else
    begin
      FCalendar.setDatesFilter(True);
      FCalendar.setMaxDate(DateTimeToJSDate(Value));
    end;
  end;
end;

procedure TCalendar.SetMinDate(const Value: TDateTime);
begin
  FMinDate := Value;
  if Assigned(FCalendar) then
  begin
    if Value = 0.0 then
      FCalendar.setMinDate(nil)
    else
    begin
      FCalendar.setDatesFilter(True);
      FCalendar.setMinDate(DateTimeToJSDate(Value));
    end;
  end;
end;

procedure TCalendar.SetMonth(const Value: Integer);
begin
  FMonth := Value;
  if Assigned(FCalendar) then
    FCalendar.setSelectedMonth(Value);
end;

procedure TCalendar.SetMonthNames(const Value: TCalendarMonthNames);
var
  arr: TJSArray;
begin
  FMonthNames := Value;
  if Assigned(FCalendar) then
  begin
    arr := GetMonthNamesArray;
    if Assigned(arr) then
      FCalendar.setMonths(arr);
  end;
end;

procedure TCalendar.SetMultiSelect(const Value: Boolean);
begin
  FMultiSelect := Value;
  if Assigned(FCalendar) then
    FCalendar.setMultiSelect(Value);
end;

procedure TCalendar.SetNameOfDays(const Value: TCalendarWeekDayNames);
var
  arr: TJSArray;
begin
  FNameOfDays := Value;
  if Assigned(FCalendar) then
  begin
    arr := GetNameOfDaysArray;
    if Assigned(arr) then
      FCalendar.setShortWeekdays(arr);
  end;
end;

procedure TCalendar.SetReadOnly(const Value: Boolean);
begin
  FReadOnly := Value;
  if Assigned(FCalendar) then
    FCalendar.setReadOnly(Value);
end;

procedure TCalendar.SetSelectedDate(const Value: TDateTime);
begin
  FSelectedDate := Int(Value);
  if Assigned(FCalendar) then
    FCalendar.setSelectedDate(DateTimeToJSDate(FSelectedDate));
end;

procedure TCalendar.SetShowToday(const Value: Boolean);
begin
  FShowToday := Value;
  if Assigned(FCalendar) then
    FCalendar.setShowToday(Value);
end;

procedure TCalendar.SetYear(const Value: Integer);
begin
  FYear := Value;
  if Assigned(FCalendar) then
    FCalendar.setSelectedYear(Value);
end;

{ TCalendarWeekDayNames }

procedure TCalendarWeekDayNames.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TCalendarWeekDayNames.Create;
begin
  FSunday := 'Sun';
  FMonday := 'Mon';
  FTuesday := 'Tue';
  FWednesday := 'Wed';
  FThursday := 'Thu';
  FFriday := 'Fri';
  FSaturday := 'Sat';
end;

procedure TCalendarWeekDayNames.SetFriday(const Value: string);
begin
  FFriday := Value;
  Changed;
end;

procedure TCalendarWeekDayNames.SetMonday(const Value: string);
begin
  FMonday := Value;
  Changed;
end;

procedure TCalendarWeekDayNames.SetSaturday(const Value: string);
begin
  FSaturday := Value;
  Changed;
end;

procedure TCalendarWeekDayNames.SetSunday(const Value: string);
begin
  FSunday := Value;
  Changed;
end;

procedure TCalendarWeekDayNames.SetThursday(const Value: string);
begin
  FThursday := Value;
  Changed;
end;

procedure TCalendarWeekDayNames.SetTuesDay(const Value: string);
begin
  FTuesday := Value;
  Changed;
end;

procedure TCalendarWeekDayNames.SetWednesday(const Value: string);
begin
  FWednesday := Value;
  Changed;
end;

{ TCalendarMonthNames }

procedure TCalendarMonthNames.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TCalendarMonthNames.Create;
begin
  FJanuary := 'January';
  FFebruary := 'February';
  FMarch := 'March';
  FApril := 'April';
  FMay := 'May';
  FJune := 'June';
  FJuly := 'July';
  FAugust := 'August';
  FSeptember := 'September';
  FOctober := 'October';
  FNovember := 'November';
  FDecember := 'December';
end;

procedure TCalendarMonthNames.SetApril(const Value: string);
begin
  FApril := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetAugust(const Value: string);
begin
  FAugust := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetDecember(const Value: string);
begin
  FDecember := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetFebruary(const Value: string);
begin
  FFebruary := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetJanuary(const Value: string);
begin
  FJanuary := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetJuly(const Value: string);
begin
  FJuly := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetJune(const Value: string);
begin
  FJune := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetMarch(const Value: string);
begin
  FMarch := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetMay(const Value: string);
begin
  FMay := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetNovember(const Value: string);
begin
  FNovember := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetOctober(const Value: string);
begin
  FOctober := Value;
  Changed;
end;

procedure TCalendarMonthNames.SetSeptember(const Value: string);
begin
  FSeptember := Value;
  Changed;
end;

{ TCalendarInactiveDays }

procedure TCalendarInactiveDays.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TCalendarInactiveDays.Create;
begin
  FSunday := False;
  FMonday := False;
  FTuesday := False;
  FWednesday := False;
  FThursday := False;
  FFriday := False;
  FSaturday := False;
end;

procedure TCalendarInactiveDays.SetFriday(const Value: Boolean);
begin
  FFriday := Value;
  Changed;
end;

procedure TCalendarInactiveDays.SetMonday(const Value: Boolean);
begin
  FMonday := Value;
  Changed;
end;

procedure TCalendarInactiveDays.SetSaturday(const Value: Boolean);
begin
  FSaturday := Value;
  Changed;
end;

procedure TCalendarInactiveDays.SetSunday(const Value: Boolean);
begin
  FSunday := Value;
  Changed;
end;

procedure TCalendarInactiveDays.SetThursday(const Value: Boolean);
begin
  FThursday := Value;
  Changed;
end;

procedure TCalendarInactiveDays.SetTuesDay(const Value: Boolean);
begin
  FTuesday := Value;
  Changed;
end;

procedure TCalendarInactiveDays.SetWednesday(const Value: Boolean);
begin
  FWednesday := Value;
  Changed;
end;

end.
