unit UEditTeilnehmer;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.Client,
  WEBLib.ExtCtrls, WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.Imaging.pngimage;

type
  TEditTeilnehmer = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebDBEdit2: TDBEdit;
    WebLabel13: TLabel;
    WebLabel20: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebDBComboBox1: TDBComboBox;
    WebLabel5: TLabel;
    WebComboBox1: TComboBox;
    WebComboBox2: TComboBox;
    WebComboBox3: TComboBox;
    WebComboBox4: TComboBox;
    WebLabel6: TLabel;
    WebComboBox5: TComboBox;
    WebButton1: TButton;
    WebButton2: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure After_Insert_Teilnehmer(DataSet: TDataSet);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Letzte_ID : integer;
    A_ID,
    E_ID : array[1 .. 100] of integer;
  end;

var
  EditTeilnehmer: TEditTeilnehmer;
  Teilnehmer_Insert_Mode : boolean;
  Teilnehmer_Delete_Mode : boolean;
  Teilnehmer_Activate_Mode : boolean;
  Teilnehmer_Deactivate_Mode : boolean;


implementation


procedure TEditTeilnehmer.WebButton1Click(Sender: TObject);
var hst,hst2,TT,MM,JJ : String;
begin
  if (not Teilnehmer_Delete_Mode) and (not Teilnehmer_Activate_Mode) and (not Teilnehmer_DeActivate_Mode) then begin
      hst := Trim(WebDBEdit2.Text);
      WebDBEdit2.Text := hst;
      if hst = '' then begin
        MessageDlg('Name muß erfasst werden', mtError, [], nil);
        WebDBEdit2.SetFocus;
        exit;
      end;

      if WebComboBox1.ItemIndex < 1 then begin
        MessageDlg('Geburtsdatum (Tag) muß erfasst werden', mtError, [], nil);
        WebComboBox1.SetFocus;
        exit;
      end;

      if WebComboBox2.ItemIndex < 1 then begin
        MessageDlg('Geburtsdatum (Monat) muß erfasst werden', mtError, [], nil);
        WebComboBox2.SetFocus;
        exit;
      end;

      if WebComboBox3.ItemIndex < 1 then begin
        MessageDlg('Geburtsdatum (Jahr) muß erfasst werden', mtError, [], nil);
        WebComboBox3.SetFocus;
        exit;
      end;

      Datamodule1.TeilnehmerDataSet.FieldByName('TT').AsInteger := WebComboBox1.ItemIndex;
      Datamodule1.TeilnehmerDataSet.FieldByName('MM').AsInteger := WebComboBox2.ItemIndex;
      hst := WebComboBox3.Text;
      Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsInteger := StrToInt(hst);
      Datamodule1.TeilnehmerDataSet.FieldByName('Geburtsdatum').AsDateTime := EncodeDate(Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsInteger,
                                                                                         Datamodule1.TeilnehmerDataSet.FieldByName('MM').AsInteger,
                                                                                         Datamodule1.TeilnehmerDataSet.FieldByName('TT').AsInteger);


      if WebComboBox4.ItemIndex < 1 then begin
        MessageDlg('Einrichtung/Schule muß erfasst werden', mtError, [], nil);
        WebComboBox4.SetFocus;
        exit;
      end;
{      if WebComboBox5.ItemIndex < 1 then begin
        MessageDlg('Variante muß erfasst werden', mtError, [], nil);
        WebComboBox5.SetFocus;
        exit;
      end;
}

      Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger := E_ID[WebComboBox4.ItemIndex];
      Datamodule1.TeilnehmerDataSet.FieldByName('Variante').AsInteger := A_ID[WebComboBox5.ItemIndex];


    {  hst := Trim(WebDBEdit3.Text);
      WebDBEdit3.Text := hst;
      if hst = '' then begin
        MessageDlg('Vorname muß erfasst werden', mtError, [], nil);
        WebDBEdit3.SetFocus;
        exit;
      end;
    }

    {  if WebDBCombobox1.ItemIndex = -1 then begin
        MessageDlg('Anrede muß erfasst werden', mtError, [], nil);
        WebDBCombobox1.SetFocus;
        exit;
      end;
    }
  end;
  if Teilnehmer_Delete_Mode then begin
    try
      Datamodule1.TeilnehmerDataSet.Delete;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
    except
      MessageDlg('Fehler beim Löschen', mtError, [], nil);
      exit;
    end;
    Close;
  end
  else if Teilnehmer_Activate_Mode then begin
    DataModule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger := 1;
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else if Teilnehmer_DeActivate_Mode then begin
    DataModule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger := 0;
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end

  else if not Teilnehmer_Insert_Mode then begin
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else begin

//SELECT p.NEWID
//FROM STD_INSERT_TEILNEHMER ('P_ID', 'P_NL_KEY', 'P_KDID', 'P_LFDNO', 'P_NAME',
//    'P_EINRICHTUNG', 'P_GESCHLECHT', 'P_GEBURTSDATUM', 'P_LKZ', 'P_LOESCHUNG', 'P_PLAN1', 'P_PLAN2', 'P_PLAN3', 'P_PLAN4', 'P_PLAN5', 'P_PLAN6', 'P_PLAN7') p;

    TT := WebCombobox1.Text;
    MM := IntToStr(WebCombobox2.ItemIndex);
    JJ := WebCombobox3.Text;

    hst2 := 'Select p.NEWID FROM STD_INSERT_Teilnehmer(0,'
      +IntToStr(AktFirmaNo)+','+
      IntToStr(Datamodule1.KundenDataSet.Fieldbyname('ID').AsInteger)+','
      + Datamodule1.TeilnehmerDataSet.Fieldbyname('lfdno').AsString+','
      +AFZ(WebDBEdit2.Text)
      +AFZ(IntToStr(E_ID[WebComboBox4.ItemIndex]))
      +AFZ(WebDBCombobox1.Text)
      +'NULL,'
      +AFZ('F')
      +'NULL,'
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ(WebCombobox4.Text)
      +AFZ(TT)
      +AFZ(MM)
      +AFZ(JJ)
      +AFZ('0')
      +AFZ2(IntToStr(A_ID[WebComboBox5.ItemIndex])) // Variante

    +') p';


  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Teilnehmer nicht gespeichert', mtError, [], nil);
        end
        else begin
          Letzte_ID := i;
          Datamodule1.TeilnehmerDataSet.Close;
          Datamodule1.TeilnehmerDataSet.AfterOpen := After_Insert_Teilnehmer;
          if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((NL_Key eq '+IntToStr(AktFirmaNo)+' AND (KDID eq '
            + Datamodule1.KundenDataSet.Fieldbyname('ID').AsString+')))') then begin
          end;
//          if Datamodule1.TeilnehmerDataSet.Locate('ID',i,[]) then ;
          Close;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;

//      window.localStorage.setItem(cStorageTokenName, Token);
//      CheckLogin;
    end
  );
  end;

end;

procedure TEditTeilnehmer.WebButton2Click(Sender: TObject);
begin
  DataModule1.TeilnehmerDataSet.Cancel;
  Close;

end;

procedure TEditTeilnehmer.WebFormCreate(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
  Letzte_ID := -1;

  if Teilnehmer_Delete_Mode or Teilnehmer_Activate_Mode or Teilnehmer_DeActivate_Mode then begin
    WebDBEdit2.ReadOnly := true;
    WebComboBox1.Enabled := false;
    WebComboBox2.Enabled := false;
    WebComboBox3.Enabled := false;
    WebDBComboBox1.Enabled := false;
    WebComboBox4.Enabled := false;
    WebComboBox5.Enabled := false;


    if Teilnehmer_Delete_Mode then begin
      WebButton1.Caption := 'Löschen';
    end
    else if Teilnehmer_Activate_Mode then begin
      WebButton1.Caption := 'Aktivieren';
    end
    else if Teilnehmer_DeActivate_Mode then begin
      WebButton1.Caption := 'Deaktivieren';
    end
    else begin
      WebButton1.Caption := 'Speichern';
    end;

    WebLabel20.Visible := false;
    WebLabel13.Visible := false;
  end;



end;

procedure TEditTeilnehmer.WebFormShow(Sender: TObject);
var i,j : integer;
    TT,MM,JJ : word;
    hst : String;
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;

  if Teilnehmer_Delete_Mode or Teilnehmer_Activate_Mode or Teilnehmer_DeActivate_Mode then begin
    WebDBEdit2.ReadOnly := true;
    WebComboBox1.Enabled := false;
    WebComboBox2.Enabled := false;
    WebComboBox3.Enabled := false;
    WebDBComboBox1.Enabled := false;
    WebComboBox4.Enabled := false;
    WebComboBox5.Enabled := false;

    if Teilnehmer_Delete_Mode then begin
      WebButton1.Caption := 'Löschen';
    end
    else if Teilnehmer_Activate_Mode then begin
      WebButton1.Caption := 'Aktivieren';
    end
    else if Teilnehmer_DeActivate_Mode then begin
      WebButton1.Caption := 'Deaktivieren';
    end
    else begin
      WebButton1.Caption := 'Speichern';
    end;

    WebLabel20.Visible := false;
    WebLabel13.Visible := false;
  end;





  WebComboBox1.ItemIndex := Datamodule1.TeilnehmerDataSet.FieldByName('TT').AsInteger;
  WebComboBox2.ItemIndex := Datamodule1.TeilnehmerDataSet.FieldByName('MM').AsInteger;
  WebComboBox3.Items.Clear;
  WebComboBox3.Items.Add('--');
  DecodeDate(Date,JJ,MM,TT);
  for i := JJ downto JJ-120 do begin
    WebComboBox3.Items.Add(IntToStr(i));
  end;
  if Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsInteger = 0 then begin
    WebComboBox3.ItemIndex := 0;
  end
  else begin
    hst := Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsString;
    WebComboBox3.ItemIndex := WebComboBox3.Items.IndexOf(hst);
  end;

  if Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger = 0 then begin
    WebComboBox4.ItemIndex := 0;
  end
  else begin
    hst := Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsString;
    WebComboBox4.ItemIndex := WebComboBox3.Items.IndexOf(hst);
  end;

  Datamodule1.EinrichtungDataSet.First;
  WebComboBox4.Items.Clear;
  WebComboBox4.Items.Add('-----------');
  for i := 1 to 100 do begin
    E_ID[i] := 0;
  end;
  i := 0;
  j := -1;
  while not Datamodule1.EinrichtungDataSet.Eof do begin
    i := i + 1;
    E_ID[i] := Datamodule1.EinrichtungDataSet.FieldByName('ID').AsInteger;
    if E_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger then j := i;
    WebComboBox4.Items.Add(Datamodule1.EinrichtungDataSet.FieldByName('Bezeichnung').AsString);
    Datamodule1.EinrichtungDataSet.Next;
  end;
  if j <> -1 then begin
    WebComboBox4.ItemIndex := j;
  end;


  WebComboBox5.Items.Clear;
  WebComboBox5.Items.Add('-----------');
  for i := 1 to 100 do begin
    A_ID[i] := 0;
  end;
  i := 0;
  j := -1;
  Datamodule1.VariantenDataSet.First;
  while not Datamodule1.VariantenDataSet.Eof do begin
    i := i + 1;
    A_ID[i] := Datamodule1.VariantenDataSet.FieldByName('ID').AsInteger;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Variante').AsInteger then j := i;
    WebComboBox5.Items.Add(Datamodule1.VariantenDataSet.FieldByName('Bezeichnung').AsString);
    Datamodule1.VariantenDataSet.Next;
  end;
  if j <> -1 then begin
    WebComboBox5.ItemIndex := j;
  end;



end;


procedure TEditTeilnehmer.After_Insert_Teilnehmer(DataSet: TDataSet);
begin
  if Letzte_ID <> -1 then begin
    if Datamodule1.TeilnehmerDataSet.Locate('ID',Letzte_ID,[]) then ;
    Letzte_ID := -1;
  end;
  Datamodule1.TeilnehmerDataSet.AfterOpen := nil;
end;




procedure TEditTeilnehmer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebDBComboBox1 := TDBComboBox.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);
  WebComboBox3 := TComboBox.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebComboBox5 := TComboBox.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebDBComboBox1.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  WebComboBox3.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebComboBox5.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'EditTeilnehmer';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 7;
    WebLabel2.Top := 119;
    WebLabel2.Width := 34;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Name';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 263;
    WebLabel13.Top := 141;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel13.Color := clBtnFace;
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 7;
    WebLabel20.Top := 329;
    WebLabel20.Width := 95;
    WebLabel20.Height := 18;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel20.Color := clBtnFace;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 7;
    WebLabel3.Top := 169;
    WebLabel3.Width := 82;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Geburtsdatum';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 7;
    WebLabel4.Top := 221;
    WebLabel4.Width := 66;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Geschlecht';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 7;
    WebLabel5.Top := 271;
    WebLabel5.Width := 65;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Einrichtung';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 361;
    WebLabel6.Top := 168;
    WebLabel6.Width := 58;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'Variante';
    WebLabel6.Color := clBtnFace;
    WebLabel6.Visible := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 105;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 10;
    Weblabel301.Top := 33;
    Weblabel301.Width := 56;
    Weblabel301.Height := 16;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    Weblabel301.Font.Charset := DEFAULT_CHARSET;
    Weblabel301.Font.Color := clWindowText;
    Weblabel301.Font.Height := -13;
    Weblabel301.Font.Name := 'Arial';
    Weblabel301.Font.Style := [];
    Weblabel301.ParentFont := False;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 10;
    WebEdit1.Top := 55;
    WebEdit1.Width := 272;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 7;
    WebDBEdit2.Top := 141;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'NAME';
    WebDBEdit2.DataSource := DataModule1.TeilnehmerDataSource;
    WebDBComboBox1.SetParentComponent(Self);
    WebDBComboBox1.Name := 'WebDBComboBox1';
    WebDBComboBox1.Left := 7;
    WebDBComboBox1.Top := 243;
    WebDBComboBox1.Width := 248;
    WebDBComboBox1.Height := 22;
    WebDBComboBox1.ItemIndex := -1;
    WebDBComboBox1.Items.BeginUpdate;
    try
      WebDBComboBox1.Items.Clear;
      WebDBComboBox1.Items.Add('Weiblich');
      WebDBComboBox1.Items.Add('M'#228'nnlich');
    finally
      WebDBComboBox1.Items.EndUpdate;
    end;
    WebDBComboBox1.DataField := 'GESCHLECHT';
    WebDBComboBox1.DataSource := DataModule1.TeilnehmerDataSource;
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 7;
    WebComboBox1.Top := 193;
    WebComboBox1.Width := 41;
    WebComboBox1.Height := 22;
    WebComboBox1.Text := '--';
    WebComboBox1.ItemIndex := 0;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('--');
      WebComboBox1.Items.Add('01');
      WebComboBox1.Items.Add('02');
      WebComboBox1.Items.Add('03');
      WebComboBox1.Items.Add('04');
      WebComboBox1.Items.Add('05');
      WebComboBox1.Items.Add('06');
      WebComboBox1.Items.Add('07');
      WebComboBox1.Items.Add('08');
      WebComboBox1.Items.Add('09');
      WebComboBox1.Items.Add('10');
      WebComboBox1.Items.Add('11');
      WebComboBox1.Items.Add('12');
      WebComboBox1.Items.Add('13');
      WebComboBox1.Items.Add('14');
      WebComboBox1.Items.Add('15');
      WebComboBox1.Items.Add('16');
      WebComboBox1.Items.Add('17');
      WebComboBox1.Items.Add('18');
      WebComboBox1.Items.Add('19');
      WebComboBox1.Items.Add('20');
      WebComboBox1.Items.Add('21');
      WebComboBox1.Items.Add('22');
      WebComboBox1.Items.Add('23');
      WebComboBox1.Items.Add('24');
      WebComboBox1.Items.Add('25');
      WebComboBox1.Items.Add('26');
      WebComboBox1.Items.Add('27');
      WebComboBox1.Items.Add('28');
      WebComboBox1.Items.Add('29');
      WebComboBox1.Items.Add('30');
      WebComboBox1.Items.Add('31');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebComboBox2.SetParentComponent(Self);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 62;
    WebComboBox2.Top := 193;
    WebComboBox2.Width := 87;
    WebComboBox2.Height := 22;
    WebComboBox2.Text := '--';
    WebComboBox2.ItemIndex := 0;
    WebComboBox2.Items.BeginUpdate;
    try
      WebComboBox2.Items.Clear;
      WebComboBox2.Items.Add('--');
      WebComboBox2.Items.Add('Januar');
      WebComboBox2.Items.Add('Februar');
      WebComboBox2.Items.Add('M'#228'rz');
      WebComboBox2.Items.Add('April');
      WebComboBox2.Items.Add('Mai');
      WebComboBox2.Items.Add('Juni');
      WebComboBox2.Items.Add('Juli');
      WebComboBox2.Items.Add('August');
      WebComboBox2.Items.Add('September');
      WebComboBox2.Items.Add('Oktober');
      WebComboBox2.Items.Add('November');
      WebComboBox2.Items.Add('Dezember');
    finally
      WebComboBox2.Items.EndUpdate;
    end;
    WebComboBox3.SetParentComponent(Self);
    WebComboBox3.Name := 'WebComboBox3';
    WebComboBox3.Left := 161;
    WebComboBox3.Top := 193;
    WebComboBox3.Width := 87;
    WebComboBox3.Height := 22;
    WebComboBox3.Text := '--';
    WebComboBox3.ItemIndex := 0;
    WebComboBox3.Items.BeginUpdate;
    try
      WebComboBox3.Items.Clear;
      WebComboBox3.Items.Add('--');
      WebComboBox3.Items.Add('Januar');
      WebComboBox3.Items.Add('Februar');
      WebComboBox3.Items.Add('M'#228'rz');
      WebComboBox3.Items.Add('April');
      WebComboBox3.Items.Add('Mai');
      WebComboBox3.Items.Add('Juni');
      WebComboBox3.Items.Add('Juli');
      WebComboBox3.Items.Add('August');
      WebComboBox3.Items.Add('September');
      WebComboBox3.Items.Add('Oktober');
      WebComboBox3.Items.Add('November');
      WebComboBox3.Items.Add('Dezember');
    finally
      WebComboBox3.Items.EndUpdate;
    end;
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 7;
    WebComboBox4.Top := 293;
    WebComboBox4.Width := 247;
    WebComboBox4.Height := 22;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebComboBox5.SetParentComponent(Self);
    WebComboBox5.Name := 'WebComboBox5';
    WebComboBox5.Left := 425;
    WebComboBox5.Top := 168;
    WebComboBox5.Width := 40;
    WebComboBox5.Height := 22;
    WebComboBox5.Text := '--';
    WebComboBox5.Visible := False;
    WebComboBox5.ItemIndex := 0;
    WebComboBox5.Items.BeginUpdate;
    try
      WebComboBox5.Items.Clear;
      WebComboBox5.Items.Add('--');
      WebComboBox5.Items.Add('Januar');
      WebComboBox5.Items.Add('Februar');
      WebComboBox5.Items.Add('M'#228'rz');
      WebComboBox5.Items.Add('April');
      WebComboBox5.Items.Add('Mai');
      WebComboBox5.Items.Add('Juni');
      WebComboBox5.Items.Add('Juli');
      WebComboBox5.Items.Add('August');
      WebComboBox5.Items.Add('September');
      WebComboBox5.Items.Add('Oktober');
      WebComboBox5.Items.Add('November');
      WebComboBox5.Items.Add('Dezember');
    finally
      WebComboBox5.Items.EndUpdate;
    end;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 9;
    WebButton1.Top := 368;
    WebButton1.Width := 121;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Speichern';
    WebButton1.ChildOrder := 15;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 161;
    WebButton2.Top := 368;
    WebButton2.Width := 121;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Zur'#252'ck';
    WebButton2.ChildOrder := 16;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebDBComboBox1.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
    WebComboBox3.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebComboBox5.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.