unit UEditKonto4;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  WEBLib.StdCtrls,
  XData.Web.Client,
  WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar;

type
  TEditKonto4 = class(TForm)
    WebButton1: TButton;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebLabel3: TLabel;
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel20: TLabel;
    WebLabel17: TLabel;
    WebLabel16: TLabel;
    WebLabel15: TLabel;
    WebLabel14: TLabel;
    WebDBEdit1: TDBEdit;
    WebLabel11: TLabel;
    WebLabel12: TLabel;
    WebLabel13: TLabel;
    WebDBComboBox1: TDBComboBox;
    WebDBEdit2: TDBEdit;
    WebDBEdit3: TDBEdit;
    WebDBEdit4: TDBEdit;
    WebDBEdit5: TDBEdit;
    WebDBEdit6: TDBEdit;
    WebDBEdit7: TDBEdit;
    WebEdit1: TEdit;
    WebButton2: TButton;
    WebLabel9: TLabel;
    WebDBEdit8: TDBEdit;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  EditKonto4: TEditKonto4;



procedure Do_Edit_Konto4;


implementation



procedure Do_Edit_Konto4;
var
  newform: TEditKonto4;
begin
  newform := TEditKonto4.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditKonto4).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;





procedure TEditKonto4.WebButton1Click(Sender: TObject);
begin
  DataModule1.KundenDataSet.Cancel;
  Close;
end;

procedure TEditKonto4.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  if WebDBCombobox1.ItemIndex = -1 then begin
    MessageDlg('Anrede muß erfasst werden', mtError, [], nil);
    WebDBCombobox1.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit2.Text);
  WebDBEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Name muß erfasst werden', mtError, [], nil);
    WebDBEdit2.SetFocus;
    exit;
  end;
  hst := Trim(WebDBEdit3.Text);
  WebDBEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Vorname muß erfasst werden', mtError, [], nil);
    WebDBEdit3.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit4.Text);
  WebDBEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('Strasse muß erfasst werden', mtError, [], nil);
    WebDBEdit4.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit5.Text);
  WebDBEdit5.Text := hst;
  if hst = '' then begin
    MessageDlg('PLZ muß erfasst werden', mtError, [], nil);
    WebDBEdit5.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit6.Text);
  WebDBEdit6.Text := hst;
  if hst = '' then begin
    MessageDlg('Ort muß erfasst werden', mtError, [], nil);
    WebDBEdit6.SetFocus;
    exit;
  end;

  try
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;



end;

procedure TEditKonto4.WebFormShow(Sender: TObject);
begin
  DataModule1.KundenDataSet.Edit;
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;

end;

procedure TEditKonto4.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebDBEdit1 := TDBEdit.Create(Self);
  WebDBComboBox1 := TDBComboBox.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebDBEdit3 := TDBEdit.Create(Self);
  WebDBEdit4 := TDBEdit.Create(Self);
  WebDBEdit5 := TDBEdit.Create(Self);
  WebDBEdit6 := TDBEdit.Create(Self);
  WebDBEdit7 := TDBEdit.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebDBEdit8 := TDBEdit.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebDBEdit1.BeforeLoadDFMValues;
  WebDBComboBox1.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebDBEdit3.BeforeLoadDFMValues;
  WebDBEdit4.BeforeLoadDFMValues;
  WebDBEdit5.BeforeLoadDFMValues;
  WebDBEdit6.BeforeLoadDFMValues;
  WebDBEdit7.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebDBEdit8.BeforeLoadDFMValues;
  try
    Name := 'EditKonto4';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(Self);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 65;
    Weblabel301.Height := 18;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 6;
    WebLabel3.Top := 90;
    WebLabel3.Width := 51;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Anrede';
    WebLabel3.Color := clBtnFace;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 6;
    WebLabel2.Top := 120;
    WebLabel2.Width := 42;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Name';
    WebLabel2.Color := clBtnFace;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 6;
    WebLabel4.Top := 151;
    WebLabel4.Width := 63;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Vorname';
    WebLabel4.Color := clBtnFace;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 6;
    WebLabel5.Top := 182;
    WebLabel5.Width := 54;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'Strasse';
    WebLabel5.Color := clBtnFace;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 6;
    WebLabel6.Top := 213;
    WebLabel6.Width := 29;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'PLZ';
    WebLabel6.Color := clBtnFace;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 6;
    WebLabel7.Top := 243;
    WebLabel7.Width := 21;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Ort';
    WebLabel7.Color := clBtnFace;
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 6;
    WebLabel8.Top := 274;
    WebLabel8.Width := 49;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'Telefon';
    WebLabel8.Color := clBtnFace;
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 116;
    WebLabel20.Top := 337;
    WebLabel20.Width := 95;
    WebLabel20.Height := 18;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel20.Color := clBtnFace;
    WebLabel17.SetParentComponent(Self);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 367;
    WebLabel17.Top := 243;
    WebLabel17.Width := 11;
    WebLabel17.Height := 18;
    WebLabel17.Caption := '*)';
    WebLabel17.Color := clBtnFace;
    WebLabel16.SetParentComponent(Self);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 367;
    WebLabel16.Top := 211;
    WebLabel16.Width := 11;
    WebLabel16.Height := 18;
    WebLabel16.Caption := '*)';
    WebLabel16.Color := clBtnFace;
    WebLabel15.SetParentComponent(Self);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 367;
    WebLabel15.Top := 182;
    WebLabel15.Width := 11;
    WebLabel15.Height := 18;
    WebLabel15.Caption := '*)';
    WebLabel15.Color := clBtnFace;
    WebLabel14.SetParentComponent(Self);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 367;
    WebLabel14.Top := 151;
    WebLabel14.Width := 11;
    WebLabel14.Height := 18;
    WebLabel14.Caption := '*)';
    WebLabel14.Color := clBtnFace;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 230;
    WebLabel11.Top := 92;
    WebLabel11.Width := 28;
    WebLabel11.Height := 18;
    WebLabel11.Caption := 'Titel';
    WebLabel11.Color := clBtnFace;
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 200;
    WebLabel12.Top := 92;
    WebLabel12.Width := 11;
    WebLabel12.Height := 18;
    WebLabel12.Caption := '*)';
    WebLabel12.Color := clBtnFace;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 367;
    WebLabel13.Top := 126;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel13.Color := clBtnFace;
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 6;
    WebLabel9.Top := 304;
    WebLabel9.Width := 44;
    WebLabel9.Height := 18;
    WebLabel9.Caption := 'Handy';
    WebLabel9.Color := clBtnFace;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 264;
    WebButton1.Top := 361;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebDBEdit1.SetParentComponent(Self);
    WebDBEdit1.Name := 'WebDBEdit1';
    WebDBEdit1.Left := 264;
    WebDBEdit1.Top := 91;
    WebDBEdit1.Width := 97;
    WebDBEdit1.Height := 22;
    WebDBEdit1.ChildOrder := 28;
    WebDBEdit1.ElementClassName := 'form-control';
    WebDBEdit1.Text := 'WebDBEdit1';
    WebDBEdit1.DataField := 'AKTITEL';
    WebDBEdit1.DataSource := DataModule1.KundenDataSource;
    WebDBComboBox1.SetParentComponent(Self);
    WebDBComboBox1.Name := 'WebDBComboBox1';
    WebDBComboBox1.Left := 112;
    WebDBComboBox1.Top := 91;
    WebDBComboBox1.Width := 80;
    WebDBComboBox1.Height := 22;
    WebDBComboBox1.ItemIndex := -1;
    WebDBComboBox1.Items.BeginUpdate;
    try
      WebDBComboBox1.Items.Clear;
      WebDBComboBox1.Items.Add('Herr');
      WebDBComboBox1.Items.Add('Frau');
    finally
      WebDBComboBox1.Items.EndUpdate;
    end;
    WebDBComboBox1.DataField := 'ANREDE';
    WebDBComboBox1.DataSource := DataModule1.KundenDataSource;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 113;
    WebDBEdit2.Top := 121;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'NAME';
    WebDBEdit2.DataSource := DataModule1.KundenDataSource;
    WebDBEdit3.SetParentComponent(Self);
    WebDBEdit3.Name := 'WebDBEdit3';
    WebDBEdit3.Left := 113;
    WebDBEdit3.Top := 151;
    WebDBEdit3.Width := 248;
    WebDBEdit3.Height := 22;
    WebDBEdit3.ChildOrder := 28;
    WebDBEdit3.ElementClassName := 'form-control';
    WebDBEdit3.Text := 'WebDBEdit1';
    WebDBEdit3.DataField := 'NAME2';
    WebDBEdit3.DataSource := DataModule1.KundenDataSource;
    WebDBEdit4.SetParentComponent(Self);
    WebDBEdit4.Name := 'WebDBEdit4';
    WebDBEdit4.Left := 113;
    WebDBEdit4.Top := 181;
    WebDBEdit4.Width := 248;
    WebDBEdit4.Height := 22;
    WebDBEdit4.ChildOrder := 28;
    WebDBEdit4.ElementClassName := 'form-control';
    WebDBEdit4.Text := 'WebDBEdit1';
    WebDBEdit4.DataField := 'STRASSE';
    WebDBEdit4.DataSource := DataModule1.KundenDataSource;
    WebDBEdit5.SetParentComponent(Self);
    WebDBEdit5.Name := 'WebDBEdit5';
    WebDBEdit5.Left := 113;
    WebDBEdit5.Top := 212;
    WebDBEdit5.Width := 248;
    WebDBEdit5.Height := 22;
    WebDBEdit5.ChildOrder := 28;
    WebDBEdit5.ElementClassName := 'form-control';
    WebDBEdit5.Text := 'WebDBEdit1';
    WebDBEdit5.DataField := 'PLZ';
    WebDBEdit5.DataSource := DataModule1.KundenDataSource;
    WebDBEdit6.SetParentComponent(Self);
    WebDBEdit6.Name := 'WebDBEdit6';
    WebDBEdit6.Left := 113;
    WebDBEdit6.Top := 245;
    WebDBEdit6.Width := 248;
    WebDBEdit6.Height := 22;
    WebDBEdit6.ChildOrder := 28;
    WebDBEdit6.ElementClassName := 'form-control';
    WebDBEdit6.Text := 'WebDBEdit1';
    WebDBEdit6.DataField := 'ORT';
    WebDBEdit6.DataSource := DataModule1.KundenDataSource;
    WebDBEdit7.SetParentComponent(Self);
    WebDBEdit7.Name := 'WebDBEdit7';
    WebDBEdit7.Left := 113;
    WebDBEdit7.Top := 275;
    WebDBEdit7.Width := 248;
    WebDBEdit7.Height := 22;
    WebDBEdit7.ChildOrder := 28;
    WebDBEdit7.ElementClassName := 'form-control';
    WebDBEdit7.Text := 'WebDBEdit1';
    WebDBEdit7.DataField := 'TELEFON';
    WebDBEdit7.DataSource := DataModule1.KundenDataSource;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 112;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 361;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebDBEdit8.SetParentComponent(Self);
    WebDBEdit8.Name := 'WebDBEdit8';
    WebDBEdit8.Left := 113;
    WebDBEdit8.Top := 305;
    WebDBEdit8.Width := 248;
    WebDBEdit8.Height := 22;
    WebDBEdit8.ChildOrder := 28;
    WebDBEdit8.ElementClassName := 'form-control';
    WebDBEdit8.Text := 'WebDBEdit1';
    WebDBEdit8.DataField := 'HANDY';
    WebDBEdit8.DataSource := DataModule1.KundenDataSource;
  finally
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebDBEdit1.AfterLoadDFMValues;
    WebDBComboBox1.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebDBEdit3.AfterLoadDFMValues;
    WebDBEdit4.AfterLoadDFMValues;
    WebDBEdit5.AfterLoadDFMValues;
    WebDBEdit6.AfterLoadDFMValues;
    WebDBEdit7.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebDBEdit8.AfterLoadDFMValues;
  end;
end;

end.