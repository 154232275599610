unit UStd_Edit;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  XData.Web.Dataset,
  WEBLib.CDS,
  UDatamodule,
  UGlobvar, WEBLib.Grids, WEBLib.DBCtrls,
  UEditEinrichtung, DB, WEBLib.DB, WEBLib.Imaging.jpeg, WEBLib.Buttons,
  WEBLib.Imaging.pngimage,
  UStd_EditForm,
  UStd_Suche;


type
  TSTD_Edit = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebPanel2: TPanel;
    WebPanel3: TPanel;
    WebButton7: TButton;
    WebButton6: TButton;
    WebButton1: TButton;
    WebDataSource1: TDataSource;
    WebPanel4: TPanel;
    WebGroupBox1: TGroupBox;
    WebDBGrid1: TDBGrid;
    WebImageControl1: TImageControl;
    WebImageControl2: TImageControl;
    WebImageControl3: TImageControl;
    WebImageControl4: TImageControl;
    WebImageControl5: TImageControl;
    WebImageControl6: TImageControl;
    WebImageControl7: TImageControl;
    WebImageControl8: TImageControl;
    WebPanel5: TPanel;
    WebImageControl9: TImageControl;
    WebImageControl10: TImageControl;
    WebImageControl11: TImageControl;
    WebImageControl12: TImageControl;
    WebImageControl13: TImageControl;
    WebImageControl14: TImageControl;
    WebImageControl15: TImageControl;
    WebImageControl16: TImageControl;
    WebEdit2: TEdit;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebImageControl1Click(Sender: TObject);
    procedure WebImageControl11Click(Sender: TObject);
    procedure WebImageControl14Click(Sender: TObject);
    procedure WebImageControl12Click(Sender: TObject);
    procedure WebImageControl16Click(Sender: TObject);
    procedure WebImageControl15Click(Sender: TObject);
    procedure WebImageControl13Click(Sender: TObject);
    procedure WebImageControl8Click(Sender: TObject);
    procedure WebImageControl2Click(Sender: TObject);
    procedure WebImageControl10Click(Sender: TObject);
    procedure WebDBGrid1FixedCellClick(Sender: TObject; ACol, ARow: Integer);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Akt_DS : TXDataWebDataSet;
//    Akt_DS : TWebClientDataSet;
    Akt_Entity : String;
    Akt_FString,
    Akt_FieldString,
    Akt_EFString : String;
    Akt_Edit_Proc : TProcedure;

  end;

var
  STD_Edit: TSTD_Edit;



procedure Standard_Edit_Form(var DS : TXDataWebDataSet; EntityName,FString,FieldString,EDitString : String);


implementation


procedure Standard_Edit_Form(var DS : TXDataWebDataSet; EntityName,FString,FieldString,EDitString : String);

var newForm : TSTD_Edit;
    fn,
    dl,
    hst  : String;
    f : TField;
    i,j,
    fcnt : integer;

begin
  Newform := TSTD_Edit.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TSTD_Edit).Font.Size := 12;
      (AForm as TSTD_Edit).Caption := 'Einrichtungen';
      (AForm as TSTD_Edit).WebDataSource1.DataSet := nil;
//      (AForm as TSTD_Edit).Akt_DS := TXDataWebDataSet.Create(Application);
//      (AForm as TSTD_Edit).Akt_DS.Connection := Datamodule1.XDataWebConnection1;

      (AForm as TSTD_Edit).Akt_DS := DS;
      (AForm as TSTD_Edit).Akt_Entity := EntityName;
      (AForm as TSTD_Edit).Akt_FString := FString;
      (AForm as TSTD_Edit).Akt_FieldString := FieldString;
      (AForm as TSTD_Edit).Akt_EFString := EditString;

      (AForm as TSTD_Edit).webDBGrid1.Columns.Clear;
      (AForm as TSTD_Edit).webDBGrid1.Font.Size := 12;
      (AForm as TSTD_Edit).webDBGrid1.Font.Style := [fsBold];
      (AForm as TSTD_Edit).WebDBGrid1.RowHeights[0] := 24;
      (AForm as TSTD_Edit).WebDBGrid1.DefaultRowHeight := 35;

      hst := FieldString;
      fcnt := 0;
      repeat
        Parse_Grid_Field(hst,fn,dl,j);
        if fn <> '' then begin
          (AForm as TSTD_Edit).webDBGrid1.Columns.Add;
          (AForm as TSTD_Edit).webDBGrid1.Columns[fcnt].DataField := fn;
          (AForm as TSTD_Edit).webDBGrid1.Columns[fcnt].DataType := cdText;
          (AForm as TSTD_Edit).webDBGrid1.Columns[fcnt].Title := dl;
          (AForm as TSTD_Edit).webDBGrid1.Columns[fcnt].Width := j;
          fcnt := fcnt + 1;
        end;
      until hst = '';
      (AForm as TSTD_Edit).WebDataSource1.DataSet := DS;
    end
  );
  Newform.Border := fbSingle;

  Newform.ShowModal(procedure(AValue: TModalResult)
  begin
//      Close;
  end
  );
end;


procedure TSTD_Edit.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TSTD_Edit.WebDBGrid1FixedCellClick(Sender: TObject; ACol,
  ARow: Integer);
var
  LIndex: string;
  desc: boolean;
  opt: TIndexOptions;
begin
  Lindex := 'By'+ WebDBGrid1.Columns[ACol].DataField;

  // toggle sort order when the same column is clicked
  desc := SameText(Akt_DS.ActiveIndex, LIndex);

  if desc then
    LIndex := LIndex + 'Desc';

  opt := [];

  if desc then
    opt := opt + [ixDescending];

  // clear any previous indexes and add the new index to sort
  Akt_DS.Indexes.Clear;
  Akt_DS.Indexes.Add(LIndex, WebDBGrid1.Columns[ACol].DataField, opt);
  Akt_DS.ActiveIndex := LIndex;

  // set the sort indicator in the grid column header
  if ixDescending in Akt_DS.Indexes.Find(Lindex).Options then
    WebDBGrid1.Columns[ACol].SortIndicator := siDescending
  else
    WebDBGrid1.Columns[ACol].SortIndicator := siAscending;

  // force the grid to update
  WebDataSource1.DataSet :=  Akt_DS;

end;

procedure TSTD_Edit.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  WebLabel1.Caption := AktFirmaName;
  if not Akt_DS.Active then begin
    if Lade_Daten_2(Akt_DS,Akt_Entity,Akt_FString,'') then begin
    end;
  end;
end;

procedure TSTD_Edit.WebImageControl10Click(Sender: TObject);
begin
  Standard_Suchen_Form(Akt_DS,'EINRICHTUNGEN','$filter=(NL_Key eq '+IntToStr(AktFirmaNo) + ')',
    'Nummer;Nummer;100;Bezeichnung;Bezeichnung;200;Email;Email;250;',
    'Nummer;Nummer;100;Bezeichnung;Bezeichnung;200;Email;Email;250;');


end;

procedure TSTD_Edit.WebImageControl11Click(Sender: TObject);
begin
  Akt_DS.First;
end;

procedure TSTD_Edit.WebImageControl12Click(Sender: TObject);
begin
  Akt_DS.Next;
end;

procedure TSTD_Edit.WebImageControl13Click(Sender: TObject);
var i : integer;
begin
  for i := 1 to 10 do begin
    Akt_DS.Next;
  end;
end;

procedure TSTD_Edit.WebImageControl14Click(Sender: TObject);
begin
  Akt_DS.Last;
end;

procedure TSTD_Edit.WebImageControl15Click(Sender: TObject);
var i : integer;
begin
  for i := 1 to 10 do begin
    Akt_DS.Prior;
  end;
end;

procedure TSTD_Edit.WebImageControl16Click(Sender: TObject);
begin
  Akt_DS.Prior;
end;

procedure TSTD_Edit.WebImageControl1Click(Sender: TObject);

 procedure DialogProc(AValue: TModalResult);
 begin
   if AValue = mrYes then begin
     try
       Akt_DS.Delete;
       Akt_DS.ApplyUpdates;
     except
       MessageDlg('Fehler beim Löschen', mtError, [], nil);
       exit;
     end;
   end;
 end;

begin
  MessageDlg('Aktuellen Datensatz löschen?', mtConfirmation, [mbYes, mbNo, mbCancel], @DialogProc);
end;

procedure TSTD_Edit.WebImageControl2Click(Sender: TObject);
begin
  Standard_Edit_Form2(Akt_DS,Akt_Entity,Akt_FString,Akt_FieldString,Akt_EFString);


end;

procedure TSTD_Edit.WebImageControl8Click(Sender: TObject);
begin
  Close;
end;

procedure TSTD_Edit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebImageControl3 := TImageControl.Create(Self);
  WebImageControl4 := TImageControl.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  WebImageControl6 := TImageControl.Create(Self);
  WebImageControl7 := TImageControl.Create(Self);
  WebImageControl8 := TImageControl.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebGroupBox1 := TGroupBox.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebImageControl9 := TImageControl.Create(Self);
  WebImageControl10 := TImageControl.Create(Self);
  WebImageControl11 := TImageControl.Create(Self);
  WebImageControl12 := TImageControl.Create(Self);
  WebImageControl13 := TImageControl.Create(Self);
  WebImageControl14 := TImageControl.Create(Self);
  WebImageControl15 := TImageControl.Create(Self);
  WebImageControl16 := TImageControl.Create(Self);
  WebDataSource1 := TDataSource.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebImageControl3.BeforeLoadDFMValues;
  WebImageControl4.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  WebImageControl6.BeforeLoadDFMValues;
  WebImageControl7.BeforeLoadDFMValues;
  WebImageControl8.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebGroupBox1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebImageControl9.BeforeLoadDFMValues;
  WebImageControl10.BeforeLoadDFMValues;
  WebImageControl11.BeforeLoadDFMValues;
  WebImageControl12.BeforeLoadDFMValues;
  WebImageControl13.BeforeLoadDFMValues;
  WebImageControl14.BeforeLoadDFMValues;
  WebImageControl15.BeforeLoadDFMValues;
  WebImageControl16.BeforeLoadDFMValues;
  WebDataSource1.BeforeLoadDFMValues;
  try
    Name := 'STD_Edit';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 10;
    WebLabel1.Top := 8;
    WebLabel1.Width := 85;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 47;
    Weblabel301.Height := 14;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 155;
    WebPanel2.Width := 126;
    WebPanel2.Height := 265;
    WebPanel2.Align := alLeft;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 420;
    WebPanel3.Width := 640;
    WebPanel3.Height := 60;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebButton7.SetParentComponent(WebPanel3);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 105;
    WebButton7.Top := 6;
    WebButton7.Width := 113;
    WebButton7.Height := 39;
    WebButton7.Caption := 'Wochenplan';
    WebButton7.ChildOrder := 4;
    WebButton6.SetParentComponent(WebPanel3);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 3;
    WebButton6.Top := 6;
    WebButton6.Width := 96;
    WebButton6.Height := 39;
    WebButton6.Caption := 'Allergien';
    WebButton6.ChildOrder := 4;
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 280;
    WebButton1.Top := 6;
    WebButton1.Width := 153;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 89;
    WebPanel4.Width := 640;
    WebPanel4.Height := 33;
    WebPanel4.Align := alTop;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsSingle;
    WebPanel4.ChildOrder := 4;
    WebImageControl1.SetParentComponent(WebPanel4);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 33;
    WebImageControl1.Height := 33;
    WebImageControl1.Align := alLeft;
    SetEvent(WebImageControl1, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl1.Picture.LoadFromFile('UStd_Edit.WebPanel4.WebImageControl1.Picture.png');
    WebImageControl2.SetParentComponent(WebPanel4);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 33;
    WebImageControl2.Top := 0;
    WebImageControl2.Width := 33;
    WebImageControl2.Height := 33;
    WebImageControl2.Align := alLeft;
    WebImageControl2.ChildOrder := 1;
    SetEvent(WebImageControl2, Self, 'OnClick', 'WebImageControl2Click');
    WebImageControl2.Picture.LoadFromFile('UStd_Edit.WebPanel4.WebImageControl2.Picture.png');
    WebImageControl3.SetParentComponent(WebPanel4);
    WebImageControl3.Name := 'WebImageControl3';
    WebImageControl3.Left := 66;
    WebImageControl3.Top := 0;
    WebImageControl3.Width := 33;
    WebImageControl3.Height := 33;
    WebImageControl3.Align := alLeft;
    WebImageControl3.ChildOrder := 2;
    SetEvent(WebImageControl3, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl3.Picture.LoadFromFile('UStd_Edit.WebPanel4.WebImageControl3.Picture.png');
    WebImageControl4.SetParentComponent(WebPanel4);
    WebImageControl4.Name := 'WebImageControl4';
    WebImageControl4.Left := 99;
    WebImageControl4.Top := 0;
    WebImageControl4.Width := 33;
    WebImageControl4.Height := 33;
    WebImageControl4.Align := alLeft;
    WebImageControl4.ChildOrder := 3;
    SetEvent(WebImageControl4, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl4.Picture.LoadFromFile('UStd_Edit.WebPanel4.WebImageControl4.Picture.png');
    WebImageControl5.SetParentComponent(WebPanel4);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 132;
    WebImageControl5.Top := 0;
    WebImageControl5.Width := 33;
    WebImageControl5.Height := 33;
    WebImageControl5.Align := alLeft;
    WebImageControl5.ChildOrder := 4;
    WebImageControl6.SetParentComponent(WebPanel4);
    WebImageControl6.Name := 'WebImageControl6';
    WebImageControl6.Left := 165;
    WebImageControl6.Top := 0;
    WebImageControl6.Width := 33;
    WebImageControl6.Height := 33;
    WebImageControl6.Align := alLeft;
    WebImageControl6.ChildOrder := 5;
    WebImageControl7.SetParentComponent(WebPanel4);
    WebImageControl7.Name := 'WebImageControl7';
    WebImageControl7.Left := 198;
    WebImageControl7.Top := 0;
    WebImageControl7.Width := 33;
    WebImageControl7.Height := 33;
    WebImageControl7.Align := alLeft;
    WebImageControl7.ChildOrder := 6;
    WebImageControl8.SetParentComponent(WebPanel4);
    WebImageControl8.Name := 'WebImageControl8';
    WebImageControl8.Left := 231;
    WebImageControl8.Top := 0;
    WebImageControl8.Width := 33;
    WebImageControl8.Height := 33;
    WebImageControl8.Align := alLeft;
    WebImageControl8.ChildOrder := 7;
    SetEvent(WebImageControl8, Self, 'OnClick', 'WebImageControl8Click');
    WebImageControl8.Picture.LoadFromFile('UStd_Edit.WebPanel4.WebImageControl8.Picture.png');
    WebEdit2.SetParentComponent(WebPanel4);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 328;
    WebEdit2.Top := 5;
    WebEdit2.Width := 121;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 8;
    WebGroupBox1.SetParentComponent(Self);
    WebGroupBox1.Name := 'WebGroupBox1';
    WebGroupBox1.Left := 126;
    WebGroupBox1.Top := 155;
    WebGroupBox1.Width := 514;
    WebGroupBox1.Height := 265;
    WebGroupBox1.Align := alClient;
    WebGroupBox1.Caption := 'WebGroupBox1';
    WebGroupBox1.ChildOrder := 4;
    WebDBGrid1.SetParentComponent(WebGroupBox1);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 0;
    WebDBGrid1.Width := 514;
    WebDBGrid1.Height := 265;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'NUMMER';
      Title := 'Nummer';
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BEZEICHNUNG';
      Title := 'Bezeichnung';
      Width := 362;
    end;
    WebDBGrid1.DataSource := WebDataSource1;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.ShowIndicator := False;
    SetEvent(WebDBGrid1, Self, 'OnFixedCellClick', 'WebDBGrid1FixedCellClick');
    WebDBGrid1.ColWidths[0] := 64;
    WebDBGrid1.ColWidths[1] := 362;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
    WebPanel5.SetParentComponent(Self);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 122;
    WebPanel5.Width := 640;
    WebPanel5.Height := 33;
    WebPanel5.Align := alTop;
    WebPanel5.BorderColor := clSilver;
    WebPanel5.BorderStyle := bsSingle;
    WebPanel5.ChildOrder := 4;
    WebImageControl9.SetParentComponent(WebPanel5);
    WebImageControl9.Name := 'WebImageControl9';
    WebImageControl9.Left := 0;
    WebImageControl9.Top := 0;
    WebImageControl9.Width := 33;
    WebImageControl9.Height := 33;
    WebImageControl9.Align := alLeft;
    SetEvent(WebImageControl9, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl9.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl9.Picture.png');
    WebImageControl10.SetParentComponent(WebPanel5);
    WebImageControl10.Name := 'WebImageControl10';
    WebImageControl10.Left := 33;
    WebImageControl10.Top := 0;
    WebImageControl10.Width := 33;
    WebImageControl10.Height := 33;
    WebImageControl10.Align := alLeft;
    WebImageControl10.ChildOrder := 1;
    SetEvent(WebImageControl10, Self, 'OnClick', 'WebImageControl10Click');
    WebImageControl10.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl10.Picture.png');
    WebImageControl11.SetParentComponent(WebPanel5);
    WebImageControl11.Name := 'WebImageControl11';
    WebImageControl11.Left := 66;
    WebImageControl11.Top := 0;
    WebImageControl11.Width := 33;
    WebImageControl11.Height := 33;
    WebImageControl11.Align := alLeft;
    WebImageControl11.ChildOrder := 2;
    SetEvent(WebImageControl11, Self, 'OnClick', 'WebImageControl11Click');
    WebImageControl11.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl11.Picture.png');
    WebImageControl12.SetParentComponent(WebPanel5);
    WebImageControl12.Name := 'WebImageControl12';
    WebImageControl12.Left := 165;
    WebImageControl12.Top := 0;
    WebImageControl12.Width := 33;
    WebImageControl12.Height := 33;
    WebImageControl12.Align := alLeft;
    WebImageControl12.ChildOrder := 3;
    SetEvent(WebImageControl12, Self, 'OnClick', 'WebImageControl12Click');
    WebImageControl12.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl12.Picture.png');
    WebImageControl13.SetParentComponent(WebPanel5);
    WebImageControl13.Name := 'WebImageControl13';
    WebImageControl13.Left := 198;
    WebImageControl13.Top := 0;
    WebImageControl13.Width := 33;
    WebImageControl13.Height := 33;
    WebImageControl13.Align := alLeft;
    WebImageControl13.ChildOrder := 4;
    SetEvent(WebImageControl13, Self, 'OnClick', 'WebImageControl13Click');
    WebImageControl13.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl13.Picture.png');
    WebImageControl14.SetParentComponent(WebPanel5);
    WebImageControl14.Name := 'WebImageControl14';
    WebImageControl14.Left := 231;
    WebImageControl14.Top := 0;
    WebImageControl14.Width := 33;
    WebImageControl14.Height := 33;
    WebImageControl14.Align := alLeft;
    WebImageControl14.ChildOrder := 5;
    SetEvent(WebImageControl14, Self, 'OnClick', 'WebImageControl14Click');
    WebImageControl14.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl14.Picture.png');
    WebImageControl15.SetParentComponent(WebPanel5);
    WebImageControl15.Name := 'WebImageControl15';
    WebImageControl15.Left := 99;
    WebImageControl15.Top := 0;
    WebImageControl15.Width := 33;
    WebImageControl15.Height := 33;
    WebImageControl15.Align := alLeft;
    WebImageControl15.ChildOrder := 6;
    SetEvent(WebImageControl15, Self, 'OnClick', 'WebImageControl15Click');
    WebImageControl15.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl15.Picture.png');
    WebImageControl16.SetParentComponent(WebPanel5);
    WebImageControl16.Name := 'WebImageControl16';
    WebImageControl16.Left := 132;
    WebImageControl16.Top := 0;
    WebImageControl16.Width := 33;
    WebImageControl16.Height := 33;
    WebImageControl16.Align := alLeft;
    WebImageControl16.ChildOrder := 7;
    SetEvent(WebImageControl16, Self, 'OnClick', 'WebImageControl16Click');
    WebImageControl16.Picture.LoadFromFile('UStd_Edit.WebPanel5.WebImageControl16.Picture.png');
    WebDataSource1.SetParentComponent(Self);
    WebDataSource1.Name := 'WebDataSource1';
    WebDataSource1.Left := 360;
    WebDataSource1.Top := 352;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebImageControl3.AfterLoadDFMValues;
    WebImageControl4.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    WebImageControl6.AfterLoadDFMValues;
    WebImageControl7.AfterLoadDFMValues;
    WebImageControl8.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebGroupBox1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebImageControl9.AfterLoadDFMValues;
    WebImageControl10.AfterLoadDFMValues;
    WebImageControl11.AfterLoadDFMValues;
    WebImageControl12.AfterLoadDFMValues;
    WebImageControl13.AfterLoadDFMValues;
    WebImageControl14.AfterLoadDFMValues;
    WebImageControl15.AfterLoadDFMValues;
    WebImageControl16.AfterLoadDFMValues;
    WebDataSource1.AfterLoadDFMValues;
  end;
end;

end.